import {Injectable} from "@angular/core";
import {AbstractControl, AsyncValidator, FormGroup, ValidationErrors} from "@angular/forms";
import {GridxDeviceService} from "../../services/gridx-device.service";
import {catchError, map, Observable, of} from "rxjs";

@Injectable({ providedIn: 'root' })
export class GridxDeviceStartcodeValidator implements AsyncValidator {
  constructor(private gridXservice: GridxDeviceService) {}

  validate(fg: FormGroup): Observable<ValidationErrors | null> {

    const serial = fg.get('serialInput')?.value;
    let startcode = fg.get('startcodeInput')?.value;
    startcode = startcode.replace(/-/gi, '')

    return this.gridXservice.addGateway(serial).pipe(
      map(device => device.spec.startcode == startcode ? null : { gridxDeviceStartcode: true }),
      catchError(() => of({ gridxDeviceStartcode: true }))
    );
  }
}
