import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import { UnitConversion } from '../domain/unit-conversion';
import { Device } from '../domain/device.interface';
import { ImageOSVersion } from '../domain/image-osversion.interface';
import { GatewayOSVersion } from '../domain/gateway-osversion.interface';

@Injectable({
  providedIn: 'root'
})
export class OSVersionService {

  private readonly SERVICE_URL = `${environment.backendUrl}/os-version`;

  constructor(private http: HttpClient) {
  }

  public getLatestOSVersion(): Observable<ImageOSVersion> {
    return this.http.get<ImageOSVersion>(this.SERVICE_URL + '/latest-version');
  }

  public getAllOSVersions(): Observable<UnitConversion[]> {
    return this.http.get<UnitConversion[]>(this.SERVICE_URL);
  }

  createVersion(osVersion: GatewayOSVersion) {
    return this.http.post<GatewayOSVersion>(this.SERVICE_URL, osVersion) as Observable<GatewayOSVersion>;
  }

  updateVersion(osVersion: GatewayOSVersion) {
    return this.http.put<GatewayOSVersion>(this.SERVICE_URL, osVersion) as Observable<GatewayOSVersion>;
  }

  deleteVersion(osVersion: GatewayOSVersion) {
    return this.http.delete(this.SERVICE_URL + '/' + osVersion.id);
  }
}
