<div mat-dialog-title>
  <eon-ui-icon fxLayout fxLayoutAlign="end" mat-dialog-close class=closeX name="close"></eon-ui-icon>
  <div class="headline">{{'reset-ip-configuration.headline' | translate}}</div>
</div>

<ng-container>
  <mat-dialog-content>
    {{'reset-ip-configuration.content' | translate}}
  </mat-dialog-content>

  <mat-dialog-actions style="justify-content: space-between;">
    <eon-ui-button scheme="redInverted" icon="close" text="{{'general.cancel' | translate}}" mat-dialog-close></eon-ui-button>
    <eon-ui-button (click)="resetIpConfiguration()"
                   text="{{'reset-ip-configuration.reset-ip-configuration' | translate}}"></eon-ui-button>
  </mat-dialog-actions>
</ng-container>
