import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import 'dayjs/locale/de'
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { DeploymentService } from "../../../services/deployment.service";
import { Router } from "@angular/router";
import { DataPoint } from "../../../domain/datapoint.interface";
import { NotificationService } from '../../../services/notification.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'eis-gateway-datapoint-table',
  templateUrl: './datapoint-table.component.html',
  styleUrls: ['./datapoint-table.component.scss']
})
export class DatapointTableComponent implements OnInit, AfterViewInit {

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;

  @Input()
  public datapoints: MatTableDataSource<DataPoint>;

  @Input()
  public tenant: string | undefined;

  @Output()
  public updateDatasource = new EventEmitter<string>();

  public displayedColumns: string[] = ['online', 'deviceName', 'key', 'lastValue', 'unit', 'scaleFactor', 'pollingInterval',
    'labels', 'address', 'functionCode', 'registers', 'format', 'byteOrder', 'lastUpdated', 'contextActions'
  ]

  constructor(private deploymentService: DeploymentService,
              private router: Router,
              private clipboard: Clipboard,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.datapoints.sortingDataAccessor = (data: any, sortHeaderId: string): any => {
      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }

      return data[sortHeaderId];
    };
  }

  ngAfterViewInit(): void {
    this.datapoints.sort = this.sort;
  }

  refresh(): void {
    this.table.renderRows();
  }

  async copyLabelsToClipboard(labels: string) {
    if(!labels) {
      return;
    }

    this.clipboard.copy(labels);
    await this.notificationService.success("Copied to clipboard");
  }
}
