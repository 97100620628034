import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'eis-gateway-tenant-card',
  templateUrl: './tenant-card.component.html',
  styleUrls: ['./tenant-card.component.scss']
})
export class TenantCardComponent implements OnInit {

  @Input()
  title: string = "";

  @Output()
  click: EventEmitter<TenantCardComponent> = new EventEmitter<TenantCardComponent>()

  selected: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
