import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbItem } from '../../../components/breadcrumb/breadcrumbItem';

enum availableViews {
  UNIT_CONVERSIONS = "unit-conversions",
  OS_VERSIONS = "os-versions",
}

@Component({
  selector: 'eis-gateway-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent implements OnInit {
  activeView: string = availableViews.UNIT_CONVERSIONS;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    const section = this.route.snapshot.params['section'];
    this.changeView(section);
  }

  changeView(views: string) {
    switch(views) {
      case 'unit-conversions':
        this.activeView = availableViews.UNIT_CONVERSIONS;
        break;
      case 'os-versions':
        this.activeView = availableViews.OS_VERSIONS;
        break;
    }
  }

  gotoSection(section: string) {
    this.router.navigate(['admin/' + section]);
    this.changeView(section);
  }

  getActiveViewClass(views: string) {
    return views === this.activeView ? "list-item-active" : "list-item-inactive";
  }
}
