<ng-container *ngIf="currentImageName && !loading">
  <p *ngIf="hasImageUpdate">
    <eon-ui-link hide-icon="true"
                 size="small"
                 (click)="openGatewayConfig($event)"
                 text="{{'gateway-os-version.update' | translate:{'version': latestImageName} }}">
      <eon-ui-tooltip slot="tooltip" placement="top">
        <eon-ui-text [innerHTML]="'gateway-software-update-config.gateway-update-required' | translate: {differenceInDays: differenceInDays}">
        </eon-ui-text>
      </eon-ui-tooltip>
    </eon-ui-link>
  </p>
  <p *ngIf="!hasImageUpdate">
    {{currentImageName}}
  </p>
</ng-container>

<ng-container *ngIf="loading">
  <eon-ui-preloader-circle size="small"></eon-ui-preloader-circle>
</ng-container>
