import { Component, Inject, OnInit } from '@angular/core';
import { MBusDevice } from '../../../../domain/device.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GridxDeviceService } from '../../../../services/gridx-device.service';

@Component({
  selector: 'eis-gateway-delete-mbus-device-dialog',
  templateUrl: './delete-mbus-device-dialog.component.html',
  styleUrls: ['./delete-mbus-device-dialog.component.scss']
})
export class DeleteMbusDeviceDialogComponent implements OnInit {

  device: MBusDevice;

  deleteDatapoints: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { serial: string, serialPort: string, device: MBusDevice },
              public dialogRef: MatDialogRef<DeleteMbusDeviceDialogComponent>,
              private gridxService: GridxDeviceService) {

  }

  ngOnInit(): void {
    this.device = this.data.device;
  }

  deleteDevice() {
    this.gridxService.deleteMBusDevice(this.data.serial, this.data.serialPort, this.device, this.deleteDatapoints)
      .subscribe(value => this.dialogRef.close({
        success: true,
      }), error => this.dialogRef.close({success: false, error: error}))
  }

}
