import {Component, Input, OnInit} from '@angular/core';
import {BreadcrumbItem} from "./breadcrumbItem";

@Component({
  selector: 'eis-gateway-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input()
  public breadcrumbItems: BreadcrumbItem[]

  constructor() { }

  ngOnInit(): void {
  }

}
