<ng-container *ngIf="latestOsVersion && !loading">
  <p *ngIf="hasOsUpdate && !osUpdateTriggered">
    <eon-ui-link hide-icon="true"
                 size="small"
                 (click)="updateOsVersion($event)"
                 text="{{'gateway-os-version.update' | translate:{'version': latestOsVersion} }}">
          <eon-ui-tooltip slot="tooltip" placement="top">
            <eon-ui-text [innerHTML]="'gateway-os-update-config.os-update-required' | translate: {differenceInDays: differenceInDays}">
            </eon-ui-text>
          </eon-ui-tooltip>
    </eon-ui-link>
  </p>
  <p *ngIf="osUpdateTriggered">
    {{'gateway-os-version.update-triggered' | translate:{'version': latestOsVersion} }}
  </p>
  <p *ngIf="!hasOsUpdate">
    {{latestOsVersion}}
  </p>
</ng-container>

<ng-container *ngIf="loading">
  <eon-ui-preloader-circle size="small"></eon-ui-preloader-circle>
</ng-container>
