import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MBusConfig, MBusDataPoint, MBusDevice } from '../../../../domain/device.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeviceService } from '../../../../services/device.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../../../services/notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { GridxDeviceService } from '../../../../services/gridx-device.service';
import { MatSort } from '@angular/material/sort';
import * as dayjs from 'dayjs';
import { NgxDataToCsvService } from '../../../../services/ngx-data-to-csv.service';

interface ErrorDetail {
  category: String,
  elementHint: String,
  message: String,
  position: String
}

@Component({
  selector: 'eis-gateway-mbus-edit-csv-labels-dialog',
  templateUrl: './mbus-edit-csv-labels-dialog.component.html',
  styleUrls: ['./mbus-edit-csv-labels-dialog.component.scss']
})
export class MbusEditCsvLabelsDialog implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  file: File | null;
  step: number = 1;
  checkDeviceErrors: ErrorDetail[] = [];
  validationDeviceErrors: ErrorDetail[] = [];
  csvDeviceErrors: ErrorDetail[] = [];
  device: MBusDevice;
  mBusConfig: MBusConfig[];
  deleteDatapoints: boolean = false;

  public displayedColumns: string[] = ['select', 'key', 'data_type', 'value', 'unit', 'function', 'storageNumber', 'tariff'];
  public dataPointsDataSource = new MatTableDataSource<MBusDataPoint>([]);
  public deviceAddedDataPoints: MBusDataPoint[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: {mBusConfig: MBusConfig[], device: MBusDevice, activeSerial: string, location: string},
              public dialogRef: MatDialogRef<MbusEditCsvLabelsDialog>,
              private gridxService: GridxDeviceService,
              public deviceService: DeviceService,
              private clipboard: Clipboard,
              private translateService: TranslateService,
              private notificationService: NotificationService,
              private csvService: NgxDataToCsvService) { }

  ngOnInit(): void {
    this.device = this.data.device;
    this.mBusConfig = this.data.mBusConfig;

    this.loadDatapoints();
  }

  loadDatapoints() {
    this.gridxService.getMBusDeviceDataPoints(this.data.activeSerial!!, this.device.id).subscribe(scanResult => {
      scanResult = scanResult?.filter(sr => this.mBusConfig.find(mc => mc.key == sr.key && mc.isDeployed)) ?? [];
      scanResult = this.sortDataPointsByKey(scanResult);
      this.dataPointsDataSource = new MatTableDataSource(scanResult!!);
      this.dataPointsDataSource.sort = this.sort;
      this.dataPointsDataSource.sortingDataAccessor = this.sortingDataAccessor;
    });
  }

  exportSelectedDataPoints() {
    const sortedDataPoints = this.deviceAddedDataPoints.sort((a, b) => parseInt(a.key) > parseInt(b.key) ? 1 : -1)
    const exportDataPoints = sortedDataPoints.map(dp => {
      return {
        "Selection state": 1,
        "Data Type": dp.data_type,
        "Key" : dp.key,
        "Function": dp.function,
        "Storage number": dp.storage_number,
        "Tariff": dp.tariff,
        "Value": dp.value,
        "Unit": dp.unit,
        "Manufacturer": this.device?.manufacturer ?? "",
        "Version": this.device?.version ?? "",
        "Existing labels": dp.labels ?? "",
      }
    });

    const gatewayLocation = this.data.location ?? "X";
    const manufacturer = this.device?.manufacturer ?? "X";
    const type = this.device?.type ?? "X";
    const version = this.device?.version ?? "X";
    const serialNumber = this.device?.id;
    const date = dayjs().format("YYYYMMDD_HHmmss");
    let exportFileName = `MBus-export_${gatewayLocation}_${this.device?.manufacturer}_${type}-meter_v${version}_${serialNumber}_${date}`;

    this.csvService.toCsv(exportDataPoints, exportFileName, {fieldSeparator: ';'});
  }

  showUploadDialog() {
    this.step = 2;
  }

  checkCSV() {
    this.deviceService.validateMbusDataPointLabels(this.data.activeSerial, this.data.device.id, this.file!).subscribe(errorResults => {
      this.checkDeviceErrors = errorResults;
      this.validationDeviceErrors = this.checkDeviceErrors.filter((e) => e.category == "Conflict");
      this.csvDeviceErrors = this.checkDeviceErrors.filter((e) => e.category != "Conflict");
      this.step = 3;
    });
  }

  fileChange($event: Event) {
    this.file = ($event.target as HTMLInputElement).files![0];
  }

  get checkEnabled(): boolean {
    return (this.device != null && this.file != null);
  }

  confirmDevice() {
    this.deviceService.updateMbusDataPointLabels(this.data.activeSerial, this.data.device.id, this.file!).subscribe({
      next: (_) => this.dialogRef.close({
        status: "200",
      }),
      error: (err) => {
        this.dialogRef.close({
          status: err.status.toString()
        });
      }
    });
  }

  async copyErrorToClipboard() {
    if (this.csvDeviceErrors.length == 0) {
      return;
    }

    const errors = [];
    errors.push(this.translateService.instant("add-device-dialog.csv.error"));
    for(let csvError of this.csvDeviceErrors) {
      let errorAsString = "Line: " + csvError.position + "\n";
      errorAsString += "Datapoint Key: " + csvError.elementHint + "\n";
      errorAsString += "Message: " + csvError.message + "\n";
      errors.push(errorAsString);
    }
    this.clipboard.copy(errors.join("\n"));
    await this.notificationService.success("Copied to clipboard");
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.deviceAddedDataPoints = [];
      return;
    }

    this.deviceAddedDataPoints = [...this.dataPointsDataSource.data];
  }

  isAllSelected() {
    const numSelected = this.deviceAddedDataPoints.length;
    const numRows = this.dataPointsDataSource.data.length;
    return numSelected === numRows;
  }

  isSelected(dataPoint: MBusDataPoint): boolean {
    return this.findDataPointIndex(this.deviceAddedDataPoints, dataPoint) != -1;
  }

  toggle(dataPoint: MBusDataPoint) {
    let index = this.findDataPointIndex(this.deviceAddedDataPoints, dataPoint);
    if(index > -1) {
      this.deviceAddedDataPoints.splice(index, 1);
    } else {
      this.deviceAddedDataPoints.push(dataPoint);
    }
  }

  findDataPointIndex(dataPoints: MBusDataPoint[], point: MBusDataPoint): number {
    return dataPoints.findIndex(dt => dt.key == point.key);
  }

  gotoStep(step: number) {
    this.step  = step;
  }

  private sortingDataAccessor(data: any, sortHeaderId: string): any {
    if(sortHeaderId == "key") {
      return parseInt(data[sortHeaderId]);
    }
    else if (typeof data[sortHeaderId] === 'string') {
      return data[sortHeaderId].toLocaleLowerCase();
    }

    return data[sortHeaderId];
  }

  private sortDataPointsByKey(dataPoints: MBusDataPoint[]): MBusDataPoint[] {
    return dataPoints.sort((a, b) =>
      parseInt(a.key) > parseInt(b.key)
        ? 1 : -1
    );
  }
}
