<div mat-dialog-title>
  <eon-ui-icon fxLayout fxLayoutAlign="end" [mat-dialog-close]="{status: 'closed'}" class=closeX name="close"></eon-ui-icon>
  <div class="headline">{{'confirmation-dialog.headline' | translate}}</div>
</div>

<ng-container>
  <mat-dialog-content fxFlex class="diaglo-content">
    <div>
      <div fxLayout="column">
        <p fxLayoutAlign="center center"
           class="confirmation-paragraph">{{'confirmation-dialog.confirmation-paragraph' | translate}}</p>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="justify-content: space-between;">
    <eon-ui-button scheme="redInverted" icon="close" text="{{'general.cancel' | translate}}"
                   mat-dialog-close></eon-ui-button>
    <eon-ui-button (click)="saveSettings()"
                   text="{{'confirmation-dialog.button.confirm-configuration' | translate}}"></eon-ui-button>
  </mat-dialog-actions>

</ng-container>
