import { Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {interval, Observable, Subscription} from "rxjs";
import {
  GridxDeviceNetworkAdapters,
  GridxDeviceNetworkInterface,
  GridxNetworkDeviceConfig
} from "../../../domain/gridx-device.interface";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {GridxDeviceService} from "../../../services/gridx-device.service";
import {NotificationService} from "../../../services/notification.service";
import {ConfirmationDialogComponent} from "./confirmation-dialog/confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {
  ResetIpConfigurationDialogComponent
} from './reset-ip-configuration-dialog/reset-ip-configuration-dialog.component';

@Component({
  selector: 'eis-gateway-gateway-network-config',
  templateUrl: './gateway-network-config.component.html',
  styleUrls: ['./gateway-network-config.component.scss']
})
export class GatewayNetworkConfigComponent implements OnInit, OnDestroy {

  public outputNetworkConfig: GridxNetworkDeviceConfig = {
    spec: {
      networkConfig: {
        eth0: {
          address: null,
          subnet: null,
          gateway: null,
          metric: null,
          modemConfig: null,
        },
        eth1: {
          address: null,
          subnet: null,
          gateway: null,
          metric: null,
          modemConfig: null
        },
        gsm0: {
          address: null,
          subnet: null,
          gateway: null,
          metric: null,
          modemConfig: {
            pin: null,
            apn: null,
            username: null,
            password: null,
          }
        }
      }
    }
  }

  public eth0readonly = true;
  public eth1readonly = true;
  public gsm0readonly = true;

  public eth0default: boolean;
  public eth1default: boolean;
  public gsm0default: boolean;

  @Input()
  public serial: string;

  public networkConfig$: Observable<GridxDeviceNetworkAdapters>;
  public networkStatus$: Observable<GridxDeviceNetworkAdapters>;
  public networkConfigForm: FormGroup;
  public mobileSettingsForm: FormGroup;

  public eth0Config: GridxDeviceNetworkInterface;
  public eth1Config: GridxDeviceNetworkInterface;
  public gsm0Config: GridxDeviceNetworkInterface;

  public eth0dhcpSelection: FormControl<boolean>;
  public eth0addressInput: FormControl<string | null>;
  public eth0subnetInput: FormControl<string | null>;
  public eth0macAddressInput: FormControl<string | null>;
  public eth0defaultCheckbox: FormControl<boolean>;
  public eth0DhcpAddress: FormControl<string | null>;

  public eth1dhcpSelection: FormControl<boolean>;
  public eth1addressInput: FormControl<string | null>;
  public eth1subnetInput: FormControl<string | null>;
  public eth1macAddressInput: FormControl<string | null>;
  public eth1defaultCheckbox: FormControl<boolean>;
  public eth1DhcpAddress: FormControl<string | null>;

  public gsm0dhcpSelection: FormControl<boolean>;
  public gsm0addressInput: FormControl<string | null>;
  public gsm0subnetInput: FormControl<string | null>;
  public gsm0macAddressInput: FormControl<string | null>;
  public gsm0apnInput: FormControl<string | null>;
  public gsm0pinInput: FormControl<string | null>;
  public gsm0defaultCheckbox: FormControl<boolean>;
  public gsm0DhcpAddress: FormControl<string | null>;

  private networkStatusSubscription: Subscription | null;

  constructor(public gridxService: GridxDeviceService,
              public notificationService: NotificationService,
              public fb: FormBuilder,
              public dialog: MatDialog,
              private el: ElementRef) {
    this.networkConfigForm = this.fb.group({
      eth0dhcpSelection: {value: this.eth0dhcpSelection, disabled: true},
      eth0addressInput: {
        value: this.eth0addressInput,
        disabled: true,
        validators: [Validators.pattern("(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)")]
      },
      eth0subnetInput: {value: this.eth0subnetInput, disabled: true},
      eth0macAddressInput: {value: this.eth0macAddressInput, disabled: true},
      eth0defaultCheckbox: {value: this.eth0defaultCheckbox, disabled: true},
      eth0DhcpAddress: {value: this.eth0DhcpAddress, disabled: true},

      eth1dhcpSelection: {value: this.eth1dhcpSelection, disabled: true},
      eth1addressInput: {value: this.eth1addressInput, disabled: true},
      eth1subnetInput: {value: this.eth1subnetInput, disabled: true},
      eth1macAddressInput: {value: this.eth1macAddressInput, disabled: true},
      eth1defaultCheckbox: {value: this.eth1defaultCheckbox, disabled: true},
      eth1DhcpAddress: {value: this.eth1DhcpAddress, disabled: true},

    });

    this.mobileSettingsForm = this.fb.group({
      gsm0dhcpSelection: {value: this.gsm0dhcpSelection, disabled: true},
      gsm0addressInput: {value: this.gsm0addressInput, disabled: true},
      gsm0subnetInput: {value: this.gsm0subnetInput, disabled: true},
      gsm0macAddressInput: {value: this.gsm0macAddressInput, disabled: true},
      gsm0apnInput: {value: this.gsm0apnInput, disabled: true},
      gsm0pinInput: {value: this.gsm0pinInput, disabled: true},
      gsm0usernameInput: {value: this.gsm0pinInput, disabled: true},
      gsm0passwordInput: {value: this.gsm0pinInput, disabled: true},
      gsm0defaultCheckbox: {value: this.gsm0defaultCheckbox, disabled: true},
      gsm0DhcpAddress: {value: this.gsm0DhcpAddress, disabled: true},


    })
  }

  ngOnInit(): void {
    this.networkConfig$ = this.gridxService.getDeviceNetworkConfig(this.serial);
    this.networkStatus$ = this.networkConfig$;

    this.networkConfig$.subscribe(
      networkAdapters => {
        console.log(networkAdapters)
        this.eth0Config = networkAdapters.networkAdapters[0];
        this.eth1Config = networkAdapters.networkAdapters[1];
        this.gsm0Config = networkAdapters.networkAdapters[2];

        this.eth0Config.address = this.eth0Config.address == "dhcp" ? "" : this.eth0Config.address;
        this.eth0Config.subnet = this.eth0Config.subnet || "255.255.255.0";

        this.eth1Config.dhcp = false;
        this.eth1Config.address = this.eth1Config.address == "dhcp" ? "" : this.eth1Config.address;
        this.eth1Config.subnet = this.eth1Config.subnet || "255.255.255.0";

        this.gsm0Config.dhcp = true;

        this.networkConfigForm.controls['eth0dhcpSelection'].patchValue(this.eth0Config.dhcp)
        this.networkConfigForm.controls['eth0addressInput'].patchValue(this.eth0Config.address)
        this.networkConfigForm.controls['eth0subnetInput'].patchValue(this.eth0Config.subnet)
        this.networkConfigForm.controls['eth0macAddressInput'].patchValue(this.eth0Config.macAddress)
        if (this.eth0Config.ipV4 == null) {
          this.networkConfigForm.controls['eth0DhcpAddress'].patchValue("Unavailable")
        } else {
          this.networkConfigForm.controls['eth0DhcpAddress'].patchValue(this.eth0Config.ipV4)
        }
        if (this.eth0Config.metric === 1) {
          this.eth0default = true;
        } else {
          this.eth0default = false;
        }

        this.networkConfigForm.controls['eth1dhcpSelection'].patchValue(this.eth1Config.dhcp)
        this.networkConfigForm.controls['eth1addressInput'].patchValue(this.eth1Config.address)
        this.networkConfigForm.controls['eth1subnetInput'].patchValue(this.eth1Config.subnet)
        this.networkConfigForm.controls['eth1macAddressInput'].patchValue(this.eth1Config.macAddress)
        if (this.eth1Config.ipV4 == null) {
          this.networkConfigForm.controls['eth1DhcpAddress'].patchValue("Unavailable")
        } else {
          this.networkConfigForm.controls['eth1DhcpAddress'].patchValue(this.eth1Config.ipV4)
        }
        if (this.eth1Config.metric === 1) {
          this.eth1default = true;
        } else {
          this.eth1default = false;
        }

        this.mobileSettingsForm.controls['gsm0dhcpSelection'].patchValue(this.gsm0Config.dhcp)
        this.mobileSettingsForm.controls['gsm0addressInput'].patchValue(this.gsm0Config.address)
        this.mobileSettingsForm.controls['gsm0subnetInput'].patchValue(this.gsm0Config.subnet)
        this.mobileSettingsForm.controls['gsm0macAddressInput'].patchValue(this.gsm0Config.macAddress)
        this.mobileSettingsForm.controls['gsm0apnInput'].patchValue(this.gsm0Config.apn)
        this.mobileSettingsForm.controls['gsm0pinInput'].patchValue(this.gsm0Config.pin)
        this.mobileSettingsForm.controls['gsm0usernameInput'].patchValue(this.gsm0Config.username)
        this.mobileSettingsForm.controls['gsm0passwordInput'].patchValue(this.gsm0Config.password)
        if (this.gsm0Config.ipV4 == null) {
          this.mobileSettingsForm.controls['gsm0DhcpAddress'].patchValue("Unavailable")
        } else {
          this.mobileSettingsForm.controls['gsm0DhcpAddress'].patchValue(this.gsm0Config.ipV4)
        }
        if (this.gsm0Config.metric === 1) {
          this.gsm0default = true;
        } else {
          this.gsm0default = false;
        }

        this.networkConfigForm.markAsPristine();
        this.mobileSettingsForm.markAsPristine();

        console.log(this.eth0Config);
        console.log(this.eth1Config);
        console.log(this.gsm0Config);

      }
    );

    this.networkConfigForm.controls['eth0dhcpSelection'].valueChanges.subscribe(
      data => {
        if (!this.eth0readonly) {
          if (data) {
            this.networkConfigForm.controls['eth0addressInput'].disable();
            this.networkConfigForm.controls['eth0addressInput'].setValidators([]);
            this.networkConfigForm.controls['eth0subnetInput'].setValidators([]);
            this.networkConfigForm.controls['eth0subnetInput'].disable();
            this.networkConfigForm.controls['eth0defaultCheckbox'].disable();
          } else {
            this.networkConfigForm.controls['eth0addressInput'].enable();
            this.networkConfigForm.controls['eth0addressInput'].setValidators([Validators.required]);
            this.networkConfigForm.controls['eth0subnetInput'].setValidators([Validators.required]);
            this.networkConfigForm.controls['eth0subnetInput'].enable();
            this.networkConfigForm.controls['eth0defaultCheckbox'].enable();

          }
        }
      })

    this.networkConfigForm.controls['eth1dhcpSelection'].valueChanges.subscribe(
      data => {
        if (!this.eth1readonly) {
          if (data) {
            this.networkConfigForm.controls['eth1addressInput'].disable();
            this.networkConfigForm.controls['eth1addressInput'].setValidators([]);
            this.networkConfigForm.controls['eth1subnetInput'].disable();
            this.networkConfigForm.controls['eth1defaultCheckbox'].disable();
          } else {
            this.networkConfigForm.controls['eth1addressInput'].enable();
            this.networkConfigForm.controls['eth1addressInput'].setValidators([
              Validators.required
            ]);
            this.networkConfigForm.controls['eth1subnetInput'].enable();
          }
        }
      })

    this.mobileSettingsForm.controls['gsm0dhcpSelection'].valueChanges.subscribe(
      data => {
        if (!this.gsm0readonly) {
          if (data) {
            this.mobileSettingsForm.controls['gsm0addressInput'].disable();
            this.mobileSettingsForm.controls['gsm0subnetInput'].disable();
            this.mobileSettingsForm.controls['gsm0defaultCheckbox'].disable();
          } else {
            this.mobileSettingsForm.controls['gsm0addressInput'].enable();
            this.mobileSettingsForm.controls['gsm0subnetInput'].enable();
          }
        }
      })

    this.networkStatusSubscription = interval(10000).subscribe(() => this.refreshStatus())
  }

  refreshStatus() {
    this.networkStatus$ = this.gridxService.getDeviceNetworkConfig(this.serial);
  }

  enableEditing(formName: string) {
    switch (formName) {
      case "eth0":
        this.networkConfigForm.controls['eth0dhcpSelection'].enable();
        this.networkConfigForm.controls['eth0defaultCheckbox'].enable();
        if (!this.networkConfigForm.controls['eth0dhcpSelection'].value) {
          this.networkConfigForm.controls['eth0addressInput'].enable();
          this.networkConfigForm.controls['eth0subnetInput'].enable();
          this.networkConfigForm.controls['eth0addressInput'].setValidators([Validators.required]);
          this.networkConfigForm.controls['eth0subnetInput'].setValidators([Validators.required]);
          this.markAsTouched(this.networkConfigForm);
        }
        this.eth0readonly = false;
        break;
      case "eth1":
        this.networkConfigForm.controls['eth1dhcpSelection'].enable();
        this.networkConfigForm.controls['eth1defaultCheckbox'].enable();
        if (!this.networkConfigForm.controls['eth1dhcpSelection'].value) {
          this.networkConfigForm.controls['eth1addressInput'].enable();
          this.networkConfigForm.controls['eth1subnetInput'].enable();
          this.networkConfigForm.controls['eth1addressInput'].setValidators([Validators.required]);
          this.networkConfigForm.controls['eth1subnetInput'].setValidators([Validators.required]);
          this.markAsTouched(this.networkConfigForm);
        }
        this.eth1readonly = false;
        break;
      case "gsm0":
        this.mobileSettingsForm.controls['gsm0dhcpSelection'].enable();
        this.mobileSettingsForm.controls['gsm0defaultCheckbox'].enable();
        if (!this.mobileSettingsForm.controls['gsm0dhcpSelection'].value) {
          this.mobileSettingsForm.controls['gsm0addressInput'].enable();
          this.mobileSettingsForm.controls['gsm0subnetInput'].enable();
        }
        this.mobileSettingsForm.controls['gsm0apnInput'].enable();
        this.mobileSettingsForm.controls['gsm0pinInput'].enable();
        this.mobileSettingsForm.controls['gsm0usernameInput'].enable();
        this.mobileSettingsForm.controls['gsm0passwordInput'].enable();
        this.gsm0readonly = false;
        break;
    }
  }

  disableEditing(formName: string) {
    switch (formName) {
      case "eth0":
        this.networkConfigForm.controls['eth0dhcpSelection'].disable();
        this.networkConfigForm.controls['eth0addressInput'].disable();
        this.networkConfigForm.controls['eth0subnetInput'].disable();
        this.networkConfigForm.controls['eth0defaultCheckbox'].disable();
        this.eth0readonly = true;
        break;
      case "eth1":
        this.networkConfigForm.controls['eth1dhcpSelection'].disable();
        this.networkConfigForm.controls['eth1addressInput'].disable();
        this.networkConfigForm.controls['eth1subnetInput'].disable();
        this.networkConfigForm.controls['eth1defaultCheckbox'].disable();
        this.eth1readonly = true;
        break;
      case "gsm0":
        this.mobileSettingsForm.controls['gsm0dhcpSelection'].disable();
        this.mobileSettingsForm.controls['gsm0addressInput'].disable();
        this.mobileSettingsForm.controls['gsm0subnetInput'].disable();
        this.mobileSettingsForm.controls['gsm0apnInput'].disable();
        this.mobileSettingsForm.controls['gsm0pinInput'].disable();
        this.mobileSettingsForm.controls['gsm0usernameInput'].disable();
        this.mobileSettingsForm.controls['gsm0passwordInput'].disable();
        this.mobileSettingsForm.controls['gsm0defaultCheckbox'].disable();
        this.gsm0readonly = true;
        break;
    }
  }

  isFormValid(form: FormGroup) {
    return form.disabled ? true : form.valid
  }

  disableAllForms() {
    this.eth0readonly = true;
    this.eth1readonly = true;
    this.gsm0readonly = true;

    this.mobileSettingsForm.disable()
    this.networkConfigForm.disable()
  }

  getChipClass(status: string) {
    if (status === "Activated") {
      return "active-chip"
    } else {
      return "unavailable-chip"
    }
  }

  getDhcpStatus(formName: string): boolean {
    switch (formName) {
      case "eth0":
        return this.networkConfigForm.controls['eth0dhcpSelection'].value
      case "eth1":
        return this.networkConfigForm.controls['eth1dhcpSelection'].value
      case "gsm0":
        return this.mobileSettingsForm.controls['gsm0dhcpSelection'].value
      default:
        return false
    }
  }


  openConfirmationDialog(): void {
    if (!this.networkConfigForm.controls['eth0defaultCheckbox'].value && !this.mobileSettingsForm.controls['gsm0defaultCheckbox'].value) {
      this.notificationService.failure("gateway-network-config.validation.default");
      return;
    }

    this.markAsTouched(this.networkConfigForm);
    this.markAsTouched(this.mobileSettingsForm);
    if (this.isFormValid(this.networkConfigForm) && this.isFormValid(this.mobileSettingsForm)) {
      this.outputNetworkConfig.spec.networkConfig.eth0!.address =
        this.getDhcpStatus('eth0') ? 'dhcp' : this.networkConfigForm.controls['eth0addressInput'].value
      this.outputNetworkConfig.spec.networkConfig.eth0!.subnet =
        this.getDhcpStatus('eth0') ? null : this.networkConfigForm.controls['eth0subnetInput'].value
      this.outputNetworkConfig.spec.networkConfig.eth0!.gateway =
        this.getDhcpStatus('eth0') ? 'auto' : null
      this.outputNetworkConfig.spec.networkConfig.eth0!.metric =
        this.networkConfigForm.controls['eth0defaultCheckbox'].value ? 1 : null

      this.outputNetworkConfig.spec.networkConfig.eth1!.address =
        this.getDhcpStatus('eth1') ? 'dhcp' : this.networkConfigForm.controls['eth1addressInput'].value
      this.outputNetworkConfig.spec.networkConfig.eth1!.subnet =
        this.getDhcpStatus('eth1') ? null : this.networkConfigForm.controls['eth1subnetInput'].value
      this.outputNetworkConfig.spec.networkConfig.eth1!.gateway =
        this.getDhcpStatus('eth1') ? 'auto' : null
      this.outputNetworkConfig.spec.networkConfig.eth1!.metric =
        this.networkConfigForm.controls['eth1defaultCheckbox'].value ? 1 : null


      this.outputNetworkConfig.spec.networkConfig.gsm0.address =
        this.getDhcpStatus('gsm0') ? 'dhcp' : this.mobileSettingsForm.controls['gsm0addressInput'].value
      this.outputNetworkConfig.spec.networkConfig.gsm0.subnet =
        this.getDhcpStatus('gsm0') ? null : this.mobileSettingsForm.controls['gsm0subnetInput'].value
      this.outputNetworkConfig.spec.networkConfig.gsm0.gateway =
        this.getDhcpStatus('gsm0') ? 'auto' : null
      this.outputNetworkConfig.spec.networkConfig.gsm0.metric =
        this.mobileSettingsForm.controls['gsm0defaultCheckbox'].value ? 1 : null


      this.outputNetworkConfig.spec.networkConfig.gsm0.modemConfig!!.pin = this.mobileSettingsForm.controls['gsm0pinInput'].value
      this.outputNetworkConfig.spec.networkConfig.gsm0.modemConfig!!.apn = this.mobileSettingsForm.controls['gsm0apnInput'].value
      this.outputNetworkConfig.spec.networkConfig.gsm0.modemConfig!!.username = this.mobileSettingsForm.controls['gsm0usernameInput'].value
      this.outputNetworkConfig.spec.networkConfig.gsm0.modemConfig!!.password = this.mobileSettingsForm.controls['gsm0passwordInput'].value

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        panelClass: 'dialog-container-custom',
        disableClose: true,
        data: {
          activeSerial: this.serial,
          newNetworkConfig: this.outputNetworkConfig,
        },
        width: '792px',
        height: '330px',
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((response) => {
        console.log(response);
        switch (response.status) {
          case 200:
            this.notificationService.success("Network Settings updated. It takes up to 15 seconds for changes to show!")
            this.disableAllForms();
            break;
          case 409:
            this.notificationService.failure("Network Settings could not be updated");
            break;
          case 400:
            this.notificationService.failure(response?.error?.Error?.message || "Network Settings could not be updated");
            break;
          case "closed":
            break;
          default:
            this.notificationService.failure('gateway-details.snackbar.error');
        }
      });
    } else {
      const invalidElements = this.el.nativeElement.querySelectorAll('.input-field.ng-invalid input');
      if (invalidElements.length > 0) {
        invalidElements[0].focus();
      }
    }
  }

  markAsTouched(formGroup: FormGroup) {
    formGroup.markAsTouched({ onlySelf: true });
    Object.keys(formGroup.controls).map((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl || control instanceof FormArray || control) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  checkboxChanged(checkboxName: string) {
    if (checkboxName === 'eth0') {
      this.eth1default = false;
      this.gsm0default = false;
    } else if (checkboxName === 'eth1') {
      this.eth0default = false;
      this.gsm0default = false;
    } else if (checkboxName === 'gsm0') {
      this.eth0default = false;
      this.eth1default = false;
    }
  }

  ngOnDestroy(): void {
    if(this.networkStatusSubscription != null)
      this.networkStatusSubscription.unsubscribe()
  }

  openRecoveryDialog() {
    const dialogRef = this.dialog.open(ResetIpConfigurationDialogComponent, {
      width: '492px',
      height: '380px',
      data: {
        activeSerial: this.serial,
        newNetworkConfig: this.outputNetworkConfig,
      },
    });
    dialogRef.afterClosed().subscribe((response) => {
      console.log(response);
      switch (response.status) {
        case "200":
          this.notificationService.success("Network Settings reset. It takes up to 15 seconds for changes to show!")
          this.disableAllForms();
          break;
        case "409":
          this.notificationService.failure("Network Settings could not be reset");
          break;
        case "closed":
          break;
      }
    });
  }
}
