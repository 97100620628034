import {Component, Inject, OnInit} from '@angular/core';
import {GridxDeviceService} from "../../../../services/gridx-device.service";
import {NotificationService} from "../../../../services/notification.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface DialogData {
  newNetworkConfig: any;
  activeSerial: string;
}

@Component({
  selector: 'eis-gateway-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  public outputNetworkConfig: any;
  public serial: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
              public gridxService: GridxDeviceService,
              public notificationService: NotificationService,
              public dialogRef: MatDialogRef<ConfirmationDialogComponent>,) {}

  ngOnInit(): void {
    this.outputNetworkConfig = this.data.newNetworkConfig;
    this.serial = this.data.activeSerial;
  }


  saveSettings() {
    this.gridxService.postNetworkConfig(this.outputNetworkConfig, this.serial).subscribe({
      next: (_) => {
        this.dialogRef.close({status: 200});
      },
      error: (err) => this.dialogRef.close(err)
    });
  }
}
