<div mat-dialog-title class="dialog-title">
  <eon-ui-icon fxLayout fxLayoutAlign="end" [mat-dialog-close]="{status: 'closed'}" class=closeX
               name="close"></eon-ui-icon>
  <div class="headline">{{ 'edit-csv-dialog.headline.edit' | translate}} '{{device.deviceName}}'</div>
</div>

<ng-container *ngIf="step == 1">
  <mat-dialog-content fxFlex class="dialog-content">
    <div>
      <div fxLayout="column" fxLayoutGap="25px">
        <p class="stepheadline">{{'edit-csv-dialog.stepper.csv.headline' | translate}}</p>
        <span>{{'edit-csv-dialog.stepper.csv.text' | translate}}</span>
        <div fxFlex="48">
          <eis-gateway-upload fileType=".csv" (change)="fileChange($event)"></eis-gateway-upload>
        </div>
        <eon-ui-checkbox [(ngModel)]="deleteDatapoints" label="{{'edit-csv-dialog.delete-device-checkbox-label' | translate}}"></eon-ui-checkbox>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="justify-content: space-between;">
    <eon-ui-button scheme="red" rank="secondary" icon="close" text="{{'general.cancel' | translate}}"
                   [mat-dialog-close]="{status: 'closed'}"></eon-ui-button>
    <eon-ui-button (click)="checkCSV()" [disabled]="!checkEnabled"
                   text="{{'general.button.check-process' | translate}}"></eon-ui-button>
  </mat-dialog-actions>

</ng-container>


<ng-container *ngIf="step == 2">
  <mat-dialog-content fxFlex>
    <div *ngIf="csvDeviceErrors.length > 0">
      <p class="stepheadline">{{'add-device-dialog.datapoints.error' | translate}}</p>
      <p>{{csvDeviceErrors.length}} Errors</p>
      <div fxLayout="column">
        <p class="stepsubheadline" *ngIf="csvDeviceErrors.length > 0">{{'add-device-dialog.csv.error' | translate}}</p>
        <ul>
          <li *ngFor="let error of csvDeviceErrors">
            <div><span>Line: </span>{{error.position}}</div>
            <div><span>Element hint: </span>{{error.elementHint}}</div>
            <div><span>Message: </span><span class="error-message" [innerHTML]="error.message"></span></div>
          </li>
        </ul>
      </div>
    </div>


    <div *ngIf="csvDeviceErrors.length === 0">
      <p class="stepheadline">{{'add-device-dialog.datapoints.success' | translate}}</p>

      <div fxLayout="column" fxLayoutGap="35px">

      </div>
      <div fxLayout="column" fxLayoutGap="35px">
        <eis-gateway-checkmark></eis-gateway-checkmark>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="justify-content: space-between;">
    <div fxLayout="row" fxLayoutGap="35px" style="align-items:center">
      <eon-ui-link icon="chevron_small_left" text="{{'general.back' | translate}}" (click)="gotoStep(1)"></eon-ui-link>

      <eon-ui-button scheme="red" rank="secondary" icon="close" text="{{'general.cancel' | translate}}"
                     [mat-dialog-close]="{status: 'closed'}"></eon-ui-button>
    </div>


    <div fxLayout="row" fxLayoutGap="35px">
      <eon-ui-button scheme="red" rank="secondary" (click)="copyErrorToClipboard()" text="{{'general.copy-to-clipboard' | translate}}"
                     *ngIf="checkDeviceErrors.length > 0"></eon-ui-button>

      <eon-ui-button (click)="confirmDevice()" text="{{'general.button.complete-process' | translate}}"
                     *ngIf="csvDeviceErrors.length == 0"></eon-ui-button>
    </div>
  </mat-dialog-actions>

</ng-container>
