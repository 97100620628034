<div mat-dialog-title>
  <eon-ui-icon fxLayout fxLayoutAlign="end" [mat-dialog-close]="false" class=closeX name="close"></eon-ui-icon>
  <div class="headline">{{'delete-conversion.headline' | translate}}</div>
</div>

<ng-container>
  <mat-dialog-content fxFlex>
    <div>
      <div fxLayout="column">
        <div fxLayoutAlign="center center">
          <mat-icon svgIcon="eon-trash-bin" class="delete-icon"></mat-icon>
        </div>
        <div class="stepheadline" fxLayoutAlign="center center">{{'delete-conversion.subheadline' | translate}}</div>
        <p fxLayoutAlign="center center" class="confirmation-paragraph">{{'delete-conversion.confirmation-paragraph' | translate}}</p>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="justify-content: space-between;">
    <eon-ui-button scheme="red" rank="secondary" icon="close" text="{{'general.cancel' | translate}}" mat-dialog-close></eon-ui-button>
    <eon-ui-button (click)="deleteConversion()"
                   icon="trash_bin"
                   text="{{'delete-conversion.button.delete' | translate}}"></eon-ui-button>
  </mat-dialog-actions>

</ng-container>
