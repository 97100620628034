import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {catchError, Observable, of} from 'rxjs';
import {AuthService} from "../../services/auth.service";
import {map, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class FullyAuthenticatedGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.getConnectionStatus().pipe(
      map(status => {
        if (!status.fullyConnected){
          this.router.navigate(["/welcome"])
        }
        return status.fullyConnected
      }),
      catchError(err => {
        this.router.navigate(["/welcome"])
        return of(false);
      })
    )

  }

}
