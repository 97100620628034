import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import 'dayjs/locale/de'
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { DeploymentService } from "../../../services/deployment.service";
import { Device } from "../../../domain/device.interface";
import { DeviceService } from "../../../services/device.service";
import { MatDialog } from "@angular/material/dialog";
import { DeleteDeviceDialogComponent } from "../delete-device-dialog/delete-device-dialog.component";
import { NotificationService } from "../../../services/notification.service";
import { AddDeviceDialogComponent } from '../add-device-dialog/add-device-dialog.component';
import { EditCSVDialogComponent } from "../edit-csv-dialog/edit-csv-dialog.component";
import { Clipboard } from '@angular/cdk/clipboard';
import {EditCSVLabelsDialog} from "../edit-csv-labels-dialog/edit-csv-labels-dialog.component";

@Component({
  selector: 'eis-gateway-device-table',
  templateUrl: './device-table.component.html',
  styleUrls: ['./device-table.component.scss']
})
export class DeviceTableComponent implements AfterViewInit {

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;

  @Input()
  public devices: MatTableDataSource<Device>;

  @Input()
  public tenant: string | undefined;

  @Input()
  public gatewaySerial: string | undefined;

  @Output()
  public updateDatasource = new EventEmitter<string>();

  public displayedColumns: string[] = ['online', 'deviceName', 'ipAddress', 'portNumber', 'portName', 'unitId', 'batchSize', 'labels', 'deleteAction'];

  public labelsAsString: {[key:string]: string} = {};

  constructor(private deploymentService: DeploymentService,
              private deviceService: DeviceService,
              private dialog: MatDialog,
              private clipboard: Clipboard,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.devices.connect().subscribe(data => this.labelsAsString = this.getDeviceLabelsAsString(data))
    this.devices.sortingDataAccessor = (data: any, sortHeaderId: string): any => {
      if (sortHeaderId == 'unitId') {
        return parseInt(data[sortHeaderId]);
      }

      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }

      return data[sortHeaderId];
    };
    this.devices.sort = this.sort;
  }

  ngAfterViewInit() {
    if (this.devices == null) return;
    this.devices.sort = this.sort;
  }

  refresh(): void {
    this.table.renderRows();
  }

  openDeletionDialog(device: Device): void {
    const dialogRef = this.dialog.open(DeleteDeviceDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        device: device,
      },
      width: '792px',
      height: '764px'
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.updateDatasource.emit();
      if (result.success) {
        this.notificationService.success('delete-device.snackbar.success', {noOfDataPoints: result.deletedDataPoints});
      }
    })
  }

  openEditDialog(device: Device): void {
    const dialogRef = this.dialog.open(AddDeviceDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        activeSerial: this.gatewaySerial,
        device: device
      },
      width: '792px',
      height: '840px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((response) => {
      this.refresh();
      console.log(response);
      switch (response.status) {
        case "200":
          console.log(response);
          this.updateDatasource.emit();
          this.notificationService.success((device == null ? 'gateway-details.snackbar.device-add.success' : 'gateway-details.snackbar.device-update.success'));
          break;
        case "409":
          this.notificationService.failure('gateway-details.snackbar.conflict');
          break;
        case "closed":
          break;
        default:
          this.notificationService.failure((device == null ? 'gateway-details.snackbar.device-add.error' : 'gateway-details.snackbar.device-update.error'));
      }
    });
  }

  openCSVDialog(device: Device): void {
    const dialogRef = this.dialog.open(EditCSVDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        activeSerial: this.gatewaySerial,
        device: device
      },
      width: '792px',
      height: '787px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((response) => {
      this.refresh();
      console.log(response);
      switch (response.status) {
        case "200":
          console.log(response);
          this.updateDatasource.emit();
          this.notificationService.success( 'gateway-details.snackbar.device-csv-update.success',
            {createdDatapoints: response.createdDatapoints, deletedDatapoints: response.deletedDatapoints});
          break;
        case "closed":
          break;
        default:
          this.notificationService.failure('gateway-details.snackbar.device-update.error');
      }
    });
  }

  openCSVLabelsDialog(device: Device): void {
    const dialogRef = this.dialog.open(EditCSVLabelsDialog, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        activeSerial: this.gatewaySerial,
        device: device
      },
      width: '792px',
      height: '787px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((response) => {
      this.refresh();
      console.log(response);
      switch (response.status) {
        case "200":
          console.log(response);
          this.updateDatasource.emit();
          this.notificationService.success( 'gateway-details.snackbar.device-csv-labels-update.success',
            {updatedDatapoints: response.updatedDatapoints});
          break;
        case "409":
          console.log(response);
          this.updateDatasource.emit();
          this.notificationService.failure('gateway-details.snackbar.device-csv-label-update.error',
            {updatedDatapoints: 0});
          break;
        case "closed":
          break;
        default:
          this.notificationService.failure('gateway-details.snackbar.device-update.error');
      }
    });
  }

  async copyLabelsToClipboard(labels: string) {
    if(!labels) {
      return;
    }

    this.clipboard.copy(labels);
    await this.notificationService.success("Copied to clipboard");
  }

  private getDeviceLabelsAsString(data: Device[]): { [p: string]: string } {
    const result:{[key:string]: string} = { };
    for(let device of data) {
      if(device.id && device.labels) {
        const labels: string = device.labels!.map(l => l.key + ":" + l.value).join(",");
        result[device.id] = labels;
      }
    }
    return result;
  }
}
