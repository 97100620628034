<div *ngIf="comportConfigs$ | async as comportConfigs">
  <form>
    <div class="config-container" fxLayout="column" *ngFor="let comConfig of comportConfigs; let index = index;" [formGroup]="comportConfigFormArray[index]">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="80" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
          <h3 class="config-container-headline">{{comConfig!!.portName}} ({{comConfig!!.port}})</h3>
          <mat-radio-group formControlName="protocol" (change)="enableEditing(index)">
            <mat-radio-button *ngFor="let protocol of portProtocols[comConfig.portName]" value="{{protocol}}" class="protocol-option">{{protocol}}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div fxFlex="20" fxLayoutAlign="end center" *ngIf="comConfig.inUse">
          <div class="chip" >{{'comportsettings-update.in-use' | translate}}</div>
        </div>
        <div fxFlex="20" fxLayoutAlign="end center" *ngIf="portsNotAvailable[comConfig.portName]">
          <div class="chip danger" >{{'comportsettings-update.not-available' | translate}}</div>
        </div>
        <div fxFlex="20" fxLayoutAlign="end center" *ngIf="!comConfig.inUse && !portsNotAvailable[comConfig.portName]">
          <div class="chip available">{{'comportsettings-update.available' | translate}}</div>
          <eon-ui-button *ngIf="!fromGroupEditable[index]" icon="edit" scheme="turquoise" size="small" rank="secondary" (click)="enableEditing(index)"></eon-ui-button>
          <eon-ui-button *ngIf="fromGroupEditable[index]" icon="radio_checkbox_tick" scheme="turquoise" size="small" rank="secondary" (click)="disableEditing(index)"></eon-ui-button>
        </div>
      </div>
      <mat-divider class="divider"></mat-divider>
      <div fxLayout="row" fxLayoutGap="10" class="bottom-controls">
        <div>
          <mat-form-field class="input-field">
            <mat-label>{{'gateway-port-config.baudrate' | translate}}</mat-label>
            <mat-select *ngIf="comConfig.protocol != 'M-Bus'" formControlName="baudRate" [compareWith]="compareFn">
              <mat-option *ngFor="let baudrate of availableBaudrates" [value]="baudrate">
                {{baudrate}}
              </mat-option>
            </mat-select>
            <mat-select *ngIf="comConfig.protocol === 'M-Bus'" formControlName="baudRate" [compareWith]="compareFn">
              <mat-option *ngFor="let baudrate of availableMbusBaudrates" [value]="baudrate">
                {{baudrate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="input-field">
            <mat-label>{{'gateway-port-config.parity' | translate}}</mat-label>
            <mat-select formControlName="parity" [compareWith]="compareFn">
              <mat-option *ngFor="let parity of availableParity" [value]="parity">
                {{parity}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="input-field">
            <mat-label>{{'gateway-port-config.stop-bits' | translate}}</mat-label>
            <mat-select formControlName="stopBits" [compareWith]="compareFn">
              <mat-option *ngFor="let stopbits of availableStopBits" [value]="stopbits">
                {{stopbits}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <label class="warning" *ngIf="portsInUse[comConfig.portName]" innerHTML="{{'comportsettings-update.notpossible' | translate: {portName: comConfig.portName} }}"></label>
    </div>
  </form>

  <div class="form-controls" fxLayoutAlign="end center">
    <eon-ui-button text="{{'general.apply' | translate}}" (click)="saveAllSettings()"></eon-ui-button>
  </div>
</div>
