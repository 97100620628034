<div class="table-container">
  <table mat-table [dataSource]="devices" matSort>
    <ng-container matColumnDef="online">
      <th mat-header-cell *matHeaderCellDef>{{'gateway-table.header.online-status' | translate}}</th>
      <td mat-cell *matCellDef="let device">
        <mat-icon *ngIf="device.online" class="wifi-on">wifi</mat-icon>
        <mat-icon *ngIf="!device.online" class="wifi-off">wifi_off</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="deviceName">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header="deviceName">{{'device-table.header.device-name' | translate}}</th>

      <td mat-cell *matCellDef="let device">
        {{device.deviceName}}
      </td>

    </ng-container>


    <ng-container matColumnDef="ipAddress">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header="ipAddress">{{'device-table.header.ip-address' | translate}}</th>
      <td mat-cell *matCellDef="let device"> {{device.ipAddress}} </td>
    </ng-container>

    <ng-container matColumnDef="portNumber">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header="portNumber">{{'device-table.header.port-number' | translate}}</th>
      <td mat-cell *matCellDef="let device"> {{device.portNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="portName">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header="portName">{{'device-table.header.port-name' | translate}}</th>
      <td mat-cell *matCellDef="let device"> {{device.portName}} </td>
    </ng-container>


    <ng-container matColumnDef="unitId">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header="unitId">{{'device-table.header.unit-id' | translate}}</th>
      <td mat-cell *matCellDef="let device"> {{device.unitId}} </td>
    </ng-container>

    <ng-container matColumnDef="batchSize">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header="batchSize">{{'device-table.header.batch-size' | translate}}</th>
      <td mat-cell *matCellDef="let device"> {{device.aggregationBatchSize}} </td>
    </ng-container>

    <ng-container matColumnDef="labels">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'datapoint-table.header.labels' | translate}}</th>
      <td mat-cell *matCellDef="let device;" (click)="copyLabelsToClipboard(labelsAsString[device.id])">
        <div *ngIf="labelsAsString[device.id].length > 80" matTooltip="{{labelsAsString[device.id]}}" matTooltipClass="label-tooltip">
          {{labelsAsString[device.id].substring(0, 80)}}
          <span *ngIf="labelsAsString[device.id].length > 80">...</span>
          <mat-icon>file_copy</mat-icon>
        </div>
        <div *ngIf="labelsAsString[device.id] && labelsAsString[device.id].length <= 80">
          {{labelsAsString[device.id]}}
          <mat-icon>file_copy</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="deleteAction">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
      <td mat-cell *matCellDef="let device" (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu"
          [matMenuTriggerData]="{device: device}">
        <mat-icon class="clickable">more_vert</mat-icon>
      </td>

    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-device=device>
    <a mat-menu-item target="_blank"
       href="https://app.iot.eon.com/tenants/{{tenant}}/devices/{{device.gatewaySerial}}.{{device.deviceName}}"
       [disabled]="!device.online">
      <mat-icon svgIcon="link-external"></mat-icon>
      {{'device-table.popupmenu.eiot' | translate}}
    </a>
    <a mat-menu-item target="_blank"
       href="https://monitoring.eon.com/de-DE/app/s187_sonic_digital_twin/search?q=search%20source%3D%22http%3Ahttp_sonic_digital_twin_s187_eis_gateway%22%7C%20search%20serialnumber%3D%22{{device.gatewaySerial}}%22%20{{device.deviceName}}">
      <mat-icon svgIcon="link-external"></mat-icon>
      {{'device-table.popupmenu.log' | translate}}
    </a>

    <button mat-menu-item (click)="openDeletionDialog(device)">
      <mat-icon svgIcon="eon-trash-bin"></mat-icon>
      {{'device-table.popupmenu.delete' | translate}}
    </button>

    <button mat-menu-item (click)="openEditDialog(device)">
      <mat-icon>edit</mat-icon>
      {{'device-table.popupmenu.edit' | translate}}
    </button>

    <button mat-menu-item (click)="openCSVDialog(device)">
      <mat-icon>edit</mat-icon>
      {{'device-table.popupmenu.csv-edit' | translate}}
    </button>

    <button mat-menu-item (click)="openCSVLabelsDialog(device)">
      <mat-icon>edit</mat-icon>
      {{'device-table.popupmenu.csv-label-edit' | translate}}
    </button>

  </ng-template>
</mat-menu>
