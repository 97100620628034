import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { AddDeviceDialogComponent } from "./add-device-dialog/add-device-dialog.component";
import { ActivatedRoute, Router } from "@angular/router";
import { DeploymentService } from "../../services/deployment.service";
import { GatewayDeployment } from "../../domain/gateway-deployment.interface";
import { MatTableDataSource } from "@angular/material/table";
import { DeviceService } from "../../services/device.service";
import { Device, MBusConfig, MBusDataPoint } from "../../domain/device.interface";
import { DeviceTableComponent } from "./device-table/device-table.component";
import { NotificationService } from "../../services/notification.service";
import { DataPoint } from "../../domain/datapoint.interface";
import { MatTabGroup } from "@angular/material/tabs";
import {combineLatest, interval, map, Observable, Subject, Subscription, takeUntil} from "rxjs";
import { BreadcrumbItem } from "../../components/breadcrumb/breadcrumbItem";
import { GridxDeviceService } from "../../services/gridx-device.service";
import * as dayjs from 'dayjs';
import { ComportService } from '../../services/comport.service';
import { Comport } from '../../domain/comport';
import { TranslateService } from '@ngx-translate/core';
import { ImageOSVersion } from '../../domain/image-osversion.interface';
import { Clipboard } from '@angular/cdk/clipboard';
import { environment } from '../../../environments/environment';
import { DiagnosticsDialogComponent } from './diagnostics-dialog/diagnostics-dialog.component';
import { OSVersionService } from '../../services/os-version.service';
import {ImageVersionService} from "../../services/image-version.service";

@Component({
  selector: 'eis-gateway-gateway-details',
  templateUrl: './gateway-details.component.html',
  styleUrls: ['./gateway-details.component.scss']
})
export class GatewayDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  // @ts-ignore
  @ViewChild(DeviceTableComponent) deviceTable!: DeviceTableComponent;

  @ViewChild('modbusTabGroup') public modbusTabGroup!: MatTabGroup;
  @ViewChild('mbusTabGroup') public mbusTabGroup!: MatTabGroup;

  public serial = "";
  public devices = new MatTableDataSource<Device>();
  public gatewayDeployment: GatewayDeployment | null;
  public mergedGatewayDeployment: GatewayDeployment | null;
  public datapoints: MatTableDataSource<DataPoint>;
  public mbusDatapoints: MatTableDataSource<MBusDataPoint>;
  public mbusConfigs: MatTableDataSource<MBusConfig>;
  public breadcrumbData: BreadcrumbItem[]
  public deviceAmount: number;
  public online: boolean;
  public imageOsVersion: ImageOSVersion;
  public hasImageUpdate: boolean = false;
  public hasOSUpdate: boolean = false;
  public osUpdateTriggered: boolean = false;
  public activeProtocol = localStorage.getItem('activeProtocol') || "modbus";
  public modbusSelectedTabIndex = 0;
  public mbusSelectedTabIndex = 0;
  public supportMBus = false;
  public supportsDiagnostics: boolean | null;

  private dataPointUpdateSubscription: Subscription;
  private _onDestroy$ = new Subject<void>();
  private ports: Comport[] = [];


  constructor(private dialog: MatDialog,
              private route: ActivatedRoute,
              private deploymentService: DeploymentService,
              private gridxService: GridxDeviceService,
              private osVersionService: OSVersionService,
              private deviceService: DeviceService,
              private imageVersionService: ImageVersionService,
              private notificationService: NotificationService,
              private comportService: ComportService,
              private translateService: TranslateService,
              private clipboard: Clipboard,
              private router: Router
  ) {
  }

  ngOnInit(): void {
    this.serial = this.route.snapshot.params['serial'];
    this.modbusSelectedTabIndex = this.getTabSelectedIndex('modbusSelectedIndex');
    this.mbusSelectedTabIndex = this.getTabSelectedIndex('mbusSelectedIndex');

    this.loadGatewayDetails();
    this.datapoints = new MatTableDataSource();
    this.mbusDatapoints = new MatTableDataSource();
    this.loadComPorts();
    this.loadDevices();
    this.loadMBusDataPoints();
    this.loadDataPoints();
  }

  ngAfterViewInit(): void {
    this.modbusTabGroup.focusChange.subscribe(event => {
      this.setTabSelectedIndex('modbusSelectedIndex', event.index);
      if (event.tab.textLabel == "Data Points") {
        this.loadDataPoints();
        this.dataPointUpdateSubscription = interval(5000).subscribe(() => this.loadDataPoints());
      } else {
        this.dataPointUpdateSubscription?.unsubscribe();
      }
    });

    this.mbusTabGroup.focusChange.subscribe(event => {
      this.setTabSelectedIndex('mbusSelectedIndex', event.index);
      if (event.tab.textLabel == this.translateService.instant("gateway-details.mbus.tab.configuration")) {
        this.loadMBusConfigs();
      } else if (event.tab.textLabel == this.translateService.instant("gateway-details.mbus.tab.datapoints")) {
        this.loadMBusDataPoints();
      }
    })

    interval(5000)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => this.loadGatewayDetails());
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
  }

  openAddDeviceDialog(): void {
    const dialogRef = this.dialog.open(AddDeviceDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        activeSerial: this.serial,
      },
      width: '850px',
      height: '910px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((response) => {
      this.refresh();
      switch (response.status) {
        case "200":
          console.log(response);
          this.notificationService.success('gateway-details.snackbar.device-add.success');
          break;
        case "409":
          this.notificationService.failure('gateway-details.snackbar.conflict');
          break;
        case "closed":
          break;
        default:
          this.notificationService.failure('gateway-details.snackbar.device-add.error');
      }
    })
  }

  private loadGatewayDetails() {
    combineLatest([
      this.deploymentService.getGatewayDeployment(this.serial),
      this.deploymentService.getMergedGatewayDeployment(this.serial),
      this.osVersionService.getLatestOSVersion(),
    ]).subscribe(data => {
      this.gatewayDeployment = data[0];
      this.mergedGatewayDeployment = data[1];
      this.imageOsVersion = data[2];
      this.hasImageUpdate = this.hasNewImage();
      this.supportMBus = this.doesSupportMBus();
      this.diagnosticsEnabled()
      if(!this.supportMBus) {
        this.activeProtocol = "modbus";
      }

      this.breadcrumbData = [
        {name: "Gateway Admin Home", link: ['/tenants']},
        {name: "Gateways", link: ['/gateway-overview', this.gatewayDeployment?.tenant || ""]}
      ]
    });
  }

  loadDevices() {
    this.deviceService.getDevices(this.serial).subscribe(data => {
      this.deviceAmount = data.length;
      this.devices.data = data;
    });
  }

  loadDataPoints() {
    this.deploymentService.getGatewayDeploymentDataPoints(this.serial).subscribe(data => {
      this.datapoints.data = data;
    });
  }

  loadMBusDataPoints() {
    this.gridxService.getMBusConfigDataPoints(this.serial).subscribe(data => {
      if (data) {
        this.mbusDatapoints.data = this.sortAfterSerialNumberAndKey(data);
      }
    });
  }

  loadMBusConfigs() {
    this.gridxService.getMBusConfig(this.serial).subscribe(mbusConfig => {
      if (mbusConfig) {
        this.setPortName(mbusConfig);
        this.mbusConfigs = new MatTableDataSource<MBusConfig>(mbusConfig)
      }
    });
  }

  private loadComPorts() {
    this.comportService.getComportConfigs(this.serial).subscribe(ports => {
      this.ports = ports;
    });
  }

  refresh(): void {
    this.loadDevices();
    this.deviceTable.refresh();
  }

  refreshMBusDataPoints(dataPoints: MBusDataPoint[]): void {
    this.mbusDatapoints.data = dataPoints;
  }

  openGatewayConfig(section: string): void {
    this.router.navigate(['gateway/' + this.serial + '/config/' + section])
  }

  openDiagnosticsDialog() {
    this.dialog.open(DiagnosticsDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        serial: this.serial,
      },
      width: '850px',
      height: '810px',
      autoFocus: false,
    });
  }

  private hasNewImage() {
    const currentImageDate = this.getImageDate(this.gatewayDeployment!.imageVersion);
    const latestImageDate = dayjs(this.imageOsVersion.imageDate);

    if (currentImageDate != null && latestImageDate != null) {
      return currentImageDate.isBefore(latestImageDate);
    }

    return false;
  }

  private getImageDate(currentImage: string): dayjs.Dayjs | null {
    const imageParts = currentImage.split("-");
    if (imageParts.length >= 2) {
      const datePart = imageParts[1];
      const dateParts = datePart.split("_");
      if (dateParts.length == 3) {
        return dayjs(datePart.replace(new RegExp("_", 'g'), "-") + 'T00:00:00.000Z');
      }
    }

    return null;
  }

  private compareOSVersion(currentVersion: string | undefined, latestVersion: string | undefined): number {
    if (currentVersion == undefined && latestVersion == undefined) {
      return 0
    }

    if (latestVersion == undefined) {
      return 1;
    }

    if (currentVersion == undefined) {
      return -1;
    }

    return currentVersion.localeCompare(latestVersion, undefined, {numeric: true, sensitivity: 'base'});
  }

  private sortAfterSerialNumberAndKey(dataPoints: MBusDataPoint[]): MBusDataPoint[] {
    return dataPoints.sort((a, b) => a.secondaryAddress.localeCompare(b.secondaryAddress) == 0
      ? parseInt(a.key) > parseInt(b.key) ? 1 : -1
      : a.secondaryAddress.localeCompare(b.secondaryAddress)
    );
  }

  private setPortName(mbusConfigs: MBusConfig[]) {
    for (let config of mbusConfigs) {
      const port = this.ports.find(p => p.port == config.serialPort);
      if (port) {
        config.serialPort = port.portName;
      }
    }
  }

  saveActiveProtocol() {
    localStorage.setItem('activeProtocol', this.activeProtocol)
  }

  private getTabSelectedIndex(key: string) : number {
    const index = localStorage.getItem(key);
    if(index != null) {
      return parseInt(index);
    }
    return 0;
  }

  private setTabSelectedIndex(key: string, index: number) {
    localStorage.setItem(key, index.toString());
  }

  copyToClipboard(text: string | null | undefined) {
    if(!text) {
      return;
    }

    this.clipboard.copy(text!);
    this.notificationService.success("Copied to clipboard");
  }

  private doesSupportMBus() {

    // Support gateway development images
    if (!this.gatewayDeployment?.imageVersion?.includes("master") && !this.gatewayDeployment?.imageVersion?.includes("main")) {
      return true;
    }

    // Support for inbetween images during development of m-bus stable image
    switch (this.gatewayDeployment!.imageVersion) {
      case "feat_mbus_refactor_decoder-inte-1963-2024_04_04-0-83babbc-arm32v7": return true;
      case "feat_mbus_refactor_variable_data_struct-inte-1958-2024_03_19-0-6b8b892-arm32v7": return true;
    }

    const currentImageDate = this.getImageDate(this.gatewayDeployment!.imageVersion);

    const minImageDate = this.getImageDate("main-2024_05_16");

    if (currentImageDate != null && minImageDate != null) {
      return minImageDate.isSame(currentImageDate) || minImageDate.isBefore(currentImageDate);
    }

    return false;
  }

  public diagnosticsEnabled() {
     this.imageVersionService.getImageVersion(this.gatewayDeployment?.imageVersion!!).subscribe(deploymentImage => {
        if (deploymentImage != null) {
          this.supportsDiagnostics = deploymentImage.supportsDiagnostics;
        } else {
          this.supportsDiagnostics = true;
        }
      })
  }
}
