<eis-gateway-app-shell>
  <eis-gateway-content-shell
    [headline]="'gateway-selection.page.headline' | translate"
    [subtext]="'gateway-selection.page.connection-prompt' | translate"
    [stepindicator]="'gateway-selection.page.stepindicator' | translate"
    [stepheadline]="'gateway-selection.page.stepheadline' | translate">

    <div class="content-container" *ngIf="$userTenants | async as userTenants">

      <eis-gateway-tenant-radio-control fxLayout="row wrap" fxLayoutGap="32px"
                                        (cardSelectionChanged)="tenantSelected($event)">
        <eis-gateway-tenant-card *ngFor="let tenant of userTenants" [title]="tenant.tenantId" [routerLink]="['/gateway-overview', selectedTenant]"></eis-gateway-tenant-card>
      </eis-gateway-tenant-radio-control>

      <div>
      </div>


    </div>
  </eis-gateway-content-shell>
</eis-gateway-app-shell>




