import { Component, Input, OnInit } from '@angular/core';
import { GatewayDeployment } from '../../../domain/gateway-deployment.interface';
import { GridxDeviceService } from '../../../services/gridx-device.service';
import { delay, Observable } from 'rxjs';
import { GridxOSVersion } from '../../../domain/gridx-osversion.interface';
import * as dayjs from 'dayjs';
import { ImageOSVersion } from '../../../domain/image-osversion.interface';

@Component({
  selector: 'eis-gateway-gateway-os-version',
  templateUrl: './gateway-os-version.component.html',
  styleUrls: ['./gateway-os-version.component.scss']
})
export class GatewayOsVersionComponent implements OnInit {

  @Input()
  gateway: GatewayDeployment

  @Input()
  latestOsVersion: string

  public hasOsUpdate: boolean;
  public osUpdateTriggered: boolean = false;
  public differenceInDays: number | undefined;
  public loading: boolean = false;

  constructor(private gridxService: GridxDeviceService){
  }

  ngOnInit(): void {
    const currentOsVersionDate = dayjs(this.gateway.osVersion, "yyyy.MM.dd");
    const latestOSVersionDate = dayjs(this.latestOsVersion, "yyyy.MM.dd");
    this.differenceInDays = latestOSVersionDate?.diff(currentOsVersionDate, 'day');

    this.osUpdateTriggered = this.gateway?.targetVersion != undefined && this.compareOSVersion(this.latestOsVersion, this.gateway?.targetVersion) == 0 && this.compareOSVersion(this.gateway.osVersion!, this.gateway?.targetVersion) == -1;
    this.hasOsUpdate = this.compareOSVersion(this.gateway.osVersion!, this.latestOsVersion) == -1;
  }

  private compareOSVersion(currentVersion: string | undefined, latestVersion: string | undefined) : number {
    if(currentVersion == undefined && latestVersion == undefined) {
      return 0
    }

    if(latestVersion == undefined) {
      return 1;
    }

    if(currentVersion == undefined) {
      return -1;
    }

    return currentVersion.localeCompare(latestVersion, undefined, { numeric: true, sensitivity: 'base' });
  }

  updateOsVersion($event: MouseEvent) {
    $event.stopPropagation();
    this.loading = true;
    this.gridxService.updateGateWayOS(this.gateway.gatewaySerial, this.latestOsVersion!).subscribe(
      (gridxImage) => {
        this.gateway.osVersion = this.latestOsVersion;
        this.hasOsUpdate = true;
        this.osUpdateTriggered = true;
        this.loading = false;
      },
      (err) => {
        this.hasOsUpdate = true;
        this.osUpdateTriggered = false;
        this.loading = false;
      }
    );
  }
}
