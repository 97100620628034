<div mat-dialog-title>
  <eon-ui-icon fxLayout fxLayoutAlign="end" [mat-dialog-close]="false" class=closeX name="close"></eon-ui-icon>
  <div class="headline">{{'delete-device.headline' | translate}}</div>
</div>

<ng-container>
  <mat-dialog-content fxFlex>
    <div>
      <div fxLayout="column">
        <div fxLayoutAlign="center center">
          <mat-icon svgIcon="eon-trash-bin" class="delete-icon"></mat-icon>
        </div>
        <div class="stepheadline" fxLayoutAlign="center center">{{'delete-device.subheadline' | translate}}</div>
        <p fxLayoutAlign="center center" class="confirmation-paragraph">{{'delete-device.confirmation-paragraph' | translate}}</p>
        <div class="bottom-container" fxLayoutAlign="center center" fxLayout="column">
          <div>{{'device.name' | translate}}</div>
          <p class="stepheadline">{{this.device.deviceName}}</p>
        </div>
      </div>
    </div>
    <eon-ui-checkbox [(ngModel)]="deleteDatapoints" label="{{'delete-device.checkbox-label' | translate}}"></eon-ui-checkbox>
  </mat-dialog-content>

  <mat-dialog-actions style="justify-content: space-between;">
    <eon-ui-button scheme="red" rank="secondary" icon="close" text="{{'general.cancel' | translate}}" mat-dialog-close></eon-ui-button>
    <eon-ui-button (click)="deleteDevice()"
                   text="{{'general.button.complete-process' | translate}}"></eon-ui-button>
  </mat-dialog-actions>

</ng-container>
