import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UnitConversion } from '../../../../domain/unit-conversion';
import { UnitConversionService } from '../../../../services/unit-conversion.service';

@Component({
  selector: 'eis-gateway-delete-conversion-dialog',
  templateUrl: './delete-conversion-dialog.component.html',
  styleUrls: ['./delete-conversion-dialog.component.scss']
})
export class DeleteConversionDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { conversion: UnitConversion },
              public dialogRef: MatDialogRef<DeleteConversionDialogComponent>,
              private uniConversionService: UnitConversionService) { }

  ngOnInit(): void {
  }

  deleteConversion() {
    this.uniConversionService.deleteConversion(this.data.conversion)
      .subscribe({
        next: (_) => this.dialogRef.close({success: true}),
        error: (error) => this.dialogRef.close({success: false, error: error})
      });
  }
}
