<div fxLayout class="line-neu">
  <div fxFlex class="rounded-left" [class.current-step]="step == 1" [class.former-step]="step > 1"></div>
  <div fxFlex [class.current-step]="step == 2" [class.former-step]="step > 2"></div>
  <div fxFlex [class.current-step]="step >= 3"></div>
</div>

<div fxLayout fxFlexAlign="center" class="subtext">
  <p fxFlex fxLayout fxLayoutAlign="end" class="steptext">{{'general.step' | translate}} {{step}} {{'general.of' | translate}} 3</p>
  <p fxFlex class="steptext stepbold">{{details}}</p>
</div>
