<div mat-dialog-title>
  <eon-ui-icon fxLayout fxLayoutAlign="end" [mat-dialog-close]="null" class=closeX name="close"></eon-ui-icon>
  <div class="headline">{{'mbus-stop-scan-dialog.headline' | translate}}</div>
</div>

<mat-dialog-content>
  <div class="body">
    {{'mbus-stop-scan-dialog.body' | translate}}
  </div>
</mat-dialog-content>

<mat-dialog-actions style="justify-content: space-between;">
  <eon-ui-button scheme="redInverted" text="{{'general.cancel' | translate}}" mat-dialog-close></eon-ui-button>
  <eon-ui-button (click)="stopScan()"
                 icon="close"
                 text="{{'mbus-stop-scan-dialog.stop' | translate}}"></eon-ui-button>
</mat-dialog-actions>
