import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {debounceTime, interval, Observable, ReplaySubject, Subscription, take} from "rxjs";
import {EiotGateway} from "../../domain/eiot-gateway.interface";
import {EonIotService} from "../../services/eon-iot.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AddGatewayDialogComponent} from "./add-gateway-dialog/add-gateway-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {GatewayTableComponent} from "./gateway-table/gateway-table.component";
import {MatTableDataSource} from "@angular/material/table";
import {DeploymentService} from "../../services/deployment.service";
import {GatewayDeployment} from "../../domain/gateway-deployment.interface";
import {FormBuilder, FormControl, FormGroup, UntypedFormControl} from "@angular/forms";
import {NotificationService} from "../../services/notification.service";
import {EiotTenant} from "../../domain/eiot-tenant.interface";
import {MatSelect} from "@angular/material/select";
import {BreadcrumbItem} from "../../components/breadcrumb/breadcrumbItem";
import { ImageOSVersion } from '../../domain/image-osversion.interface';
import { GridxDeviceService } from '../../services/gridx-device.service';
import { OSVersionService } from '../../services/os-version.service';

@Component({
  selector: 'eis-gateway-gateway-overview',
  templateUrl: './gateway-overview.component.html',
  styleUrls: ['./gateway-overview.component.scss']
})
export class GatewayOverviewComponent implements OnInit, OnDestroy {

  // @ts-ignore
  @ViewChild(GatewayTableComponent) gatewayTable!: GatewayTableComponent;

  public $gateways!: Observable<EiotGateway[]>;
  public gateways = new MatTableDataSource<GatewayDeployment>();
  public tenant: string = "";
  public amountOnlineGateways: number = 0;
  public amountOfflineGateways: number = 0;
  public totalAmountOfGateways: number = 0;
  public locationSearch: FormControl<string | null>;
  public tenantFormGroup: FormGroup;
  public filteredTenants: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  public userTenants: string[] = [];
  public imageOsVersion: ImageOSVersion;

  @ViewChild('singleSelect', {static: true}) singleSelect: MatSelect;
  public breadcrumbData: BreadcrumbItem[] = [
    {name: "Gateway Admin Home", link: ['/tenants']},
  ]
  private gatewaySubscription: Subscription | null;
  private menuOpened: boolean = false;

  constructor(private eonIotService: EonIotService,
              private deploymentService: DeploymentService,
              private osVersionService: OSVersionService,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private notificationService: NotificationService,
              private router: Router,
              private fb: FormBuilder) {
    this.locationSearch = new FormControl('');
  }

  ngOnInit(): void {
    this.tenant = this.route.snapshot.params['tenant'];
    this.eonIotService.getTenants().subscribe(tenants => {
      for (let tenant of tenants) {
        this.userTenants.push(tenant.tenantId);
        this.filteredTenants.next(this.userTenants.slice());
      }
    });
    this.tenantFormGroup = this.fb.group({
      tenantSelection: [this.tenant],
      tenantFilter: ['']
    })

    this.loadImageOsVersions();
    this.loadGateways();

    this.locationSearch.valueChanges.pipe(
      debounceTime(500),
    ).subscribe(() => this.loadGateways());

    this.tenantFormGroup.controls['tenantSelection'].valueChanges.subscribe((tenant) => {
      this.router.navigate(['/gateway-overview/' + tenant]);
      this.tenant = tenant!;
      this.loadGateways();
    });

    this.tenantFormGroup.controls['tenantFilter'].valueChanges
      .subscribe(() => {
        this.filterTenants();
      });

    this.gatewaySubscription = interval(5000).subscribe(() => this.loadGateways())

  }

  loadGateways() {
    this.deploymentService.getMergedGatewayDeployments(this.tenant, this.locationSearch.value!!).subscribe(data => {
      this.totalAmountOfGateways = data.length;
      this.amountOnlineGateways = data.filter(gateway => gateway.online === true).length;
      this.amountOfflineGateways = this.totalAmountOfGateways - this.amountOnlineGateways;
      if(!this.menuOpened) {
        this.gateways.data = data;
      }
    });
  }

  loadImageOsVersions() {
    this.osVersionService.getLatestOSVersion().subscribe(version => {
      const imageParts = version.imageName.split(":");
      version.imageName = imageParts[imageParts.length - 1];
      this.imageOsVersion = version;
    });
  }

  openAddGatewayDialog(): void {
    const dialogRef = this.dialog.open(AddGatewayDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        activeTenant: this.tenant,
      },
      width: '792px',
      height: '764px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.refresh();
      if (result == true) {
        this.notificationService.success('gateway-overview.snackbar.success');
        return
      }
      this.notificationService.failure(result);
    })
  }

  refresh(): void {
    this.loadGateways();
    this.gatewayTable.refresh();
  }

  protected filterTenants() {
    if (!this.userTenants) {
      return;
    }
    // get the search keyword
    let search = this.tenantFormGroup.controls['tenantFilter'].value;
    if (!search) {
      this.filteredTenants.next(this.userTenants.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the tenants
    this.filteredTenants.next(
      this.userTenants.filter(tenant => tenant.toLowerCase().indexOf(search) > -1)
    );
  }

  protected setInitialValue() {
    this.filteredTenants
      .pipe(take(1))
      .subscribe(() => {
        this.singleSelect.compareWith = (a: EiotTenant, b: EiotTenant) => a && b && a === b;
      });
  }

  public compareFn(object1: any, object2: any) {
    return object1 && object2 && object1 == object2;
  }

  ngOnDestroy(): void {
    if(this.gatewaySubscription != null)
      this.gatewaySubscription.unsubscribe()
  }

  menuVisibilityChanged(opened: boolean) {
    this.menuOpened = opened;
  }
}
