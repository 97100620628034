export interface Comport {
  portName: string,
  port: string,
  baudRate: number,
  parity: string,
  stopBits: number,
  dataBits: number,
  protocol: string,
  inUse: boolean,
}

export enum ComportProtocols {
  MBus='M-Bus',
  ModbusRtu='Modbus/RTU'
}
