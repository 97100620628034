import { Component, EventEmitter, Input, Output, ViewChild
} from '@angular/core';
import 'dayjs/locale/de'
import { MatTable } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MBusConfig, MBusConfigPayload, MBusDataPoint, MBusDevice } from "../../../../domain/device.interface";
import { MatDialog } from '@angular/material/dialog';
import { EonIotService } from '../../../../services/eon-iot.service';
import { GridxDeviceService } from '../../../../services/gridx-device.service';
import { MatDrawer } from '@angular/material/sidenav';
import { Comport } from '../../../../domain/comport';
import { NotificationService } from '../../../../services/notification.service';

@Component({
  selector: 'eis-gateway-mbus-device-configurator',
  templateUrl: './m-bus-device-configurator.component.html',
  styleUrls: ['./m-bus-device-configurator.component.scss']
})
export class MBusDeviceConfiguratorComponent {

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;

  @Input()
  public tenant: string | undefined;

  @Input()
  public gatewaySerial: string | undefined;

  @Input()
  public location: string | undefined;

  @Output()
  public updateDatasource = new EventEmitter<MBusDataPoint[]>();

  public addedDataPoints: {[key: string]: MBusDataPoint[]}  = {};
  public updatedDataPoints: {[key: string]: MBusDataPoint[]}  = {};
  public removedDataPoints: {[key: string]: MBusDataPoint[]}  = {};
  public mBusConfig: MBusConfig[] = [];
  public selectedDevice: MBusDevice | undefined;
  public selectedPort: Comport | undefined;

  constructor(
    private gridxService: GridxDeviceService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    this.loadMBusConfig();
  }

  public loadMBusConfig() {
    this.gridxService.getMBusConfig(this.gatewaySerial!).subscribe(mBusConfig => {
      this.mBusConfig = mBusConfig;
    });
  }

  changesApplied() {
    this.removedDataPoints = {};
    this.addedDataPoints = {};
    this.updatedDataPoints = {};
    this.loadMBusConfig();
    this.updateDatasource.emit([]);
  }

  async deviceSelected($event: any) {
    this.selectedDevice = $event.device;
    this.selectedPort = $event.port;
    await this.drawer.open();
  }

  async dataPointsUpdated($event: any) {
    await this.drawer.close();
    this.addedDataPoints[this.selectedDevice!.id] = $event.added;
    this.updatedDataPoints[this.selectedDevice!.id] = $event.updated;
    this.removedDataPoints[this.selectedDevice!.id] = $event.removed;
  }

  async applyChanges($event: any) {
    await this.drawer.close();

    this.addedDataPoints[this.selectedDevice!.id] = $event.added;
    this.updatedDataPoints[this.selectedDevice!.id] = $event.updated;
    this.removedDataPoints[this.selectedDevice!.id] = $event.removed;

    const addedPoints: MBusConfig[] = [];
    for (let point of $event.added) {
      addedPoints.push({
        serialPort: point.serialPort,
        baudrate: point.baudrate,
        secondaryAddress: point.secondaryAddress,
        key: point.key,
        pollingFrequency: point.pollingFrequency,
        isDeployed: false,
      } as MBusConfig)
    }

    const updatedPoints: MBusConfig[] = [];
    for (let point of $event.updated) {
      updatedPoints.push({
        serialPort: point.serialPort,
        baudrate: point.baudrate,
        secondaryAddress: point.secondaryAddress,
        key: point.key,
        pollingFrequency: point.pollingFrequency,
        isDeployed: false,
      } as MBusConfig)
    }

    const removedPoints: MBusConfig[] = [];
    for (let point of $event.removed) {
      removedPoints.push({
        serialPort: point.serialPort,
        baudrate: point.baudrate,
        secondaryAddress: point.secondaryAddress,
        key: point.key,
        pollingFrequency: point.pollingFrequency,
        isDeployed: false,
      } as MBusConfig)
    }

    const payload = {
      added: addedPoints,
      updated: updatedPoints,
      removed: removedPoints,
    } as MBusConfigPayload;

    this.gridxService.saveMBusConfig(this.gatewaySerial!, payload).subscribe(
      {
        next: (response) => {
          this.loadMBusConfig();
          this.notificationService.success("mbus-preview-changes.apply.success");
        },
        error: (err) => {
          this.notificationService.failure("mbus-preview-changes.apply.error");
        }
      }
    );
  }
}
