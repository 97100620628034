import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import { UnitConversion } from '../domain/unit-conversion';
import { Device } from '../domain/device.interface';

@Injectable({
  providedIn: 'root'
})
export class UnitConversionService {

  private readonly SERVICE_URL = `${environment.backendUrl}/unit-conversion`;

  constructor(private http: HttpClient) {
  }

  public getAllUnitConversions(): Observable<UnitConversion[]> {
    return this.http.get<UnitConversion[]>(this.SERVICE_URL);
  }

  createConversion(conversion: UnitConversion) {
    return this.http.post<UnitConversion>(this.SERVICE_URL, conversion) as Observable<UnitConversion>;
  }

  updateConversion(conversion: UnitConversion) {
    return this.http.put<UnitConversion>(this.SERVICE_URL, conversion) as Observable<UnitConversion>;
  }

  deleteConversion(conversion: UnitConversion) {
    return this.http.delete(this.SERVICE_URL + '/' + conversion.id);
  }
}
