<div class="table-container">
  <table mat-table matSort [dataSource]="datapoints">
    <ng-container matColumnDef="secondaryAddress">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="secondaryAddress">{{'mbus-device-table.header.serial-number' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.secondaryAddress}}</td>
    </ng-container>

    <ng-container matColumnDef="key">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="key">{{'mbus-datapoint-configurator.header.key' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.key}}</td>
    </ng-container>

    <ng-container matColumnDef="dataType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="data_type">{{'mbus-datapoint-configurator.header.data-type' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.data_type}}</td>
    </ng-container>

    <ng-container matColumnDef="unit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="unit">{{'mbus-datapoint-configurator.header.unit' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.unit}}</td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="value">{{'mbus-datapoint-configurator.header.value' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.value}}</td>
    </ng-container>

    <ng-container matColumnDef="pollingFrequency">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="pollingFrequency">{{'mbus-config-table.header.polling-frequency' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.pollingFrequency}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
