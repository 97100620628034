<div class="container" fxLayout fxLayoutAlign="start stretch">
  <div fxFlex="none" class="one"></div>
  <div fxFlex="none" class="two"></div>
  <div fxFlex="5 5 auto" class="three">
    <h1>Welcome to the<br>
      EIS Gateway<br>
      Administration Panel</h1>
    <mat-icon svgIcon="eis-assets-working-icon"></mat-icon>
  </div>
  <div fxFlex="1 1 auto" class="four" fxLayout="column">
    <h2>How to start the panel</h2>
    <p style="max-width: 400px">Two simple steps will take you to your gateway overview for your respective tenant.</p>
    <eon-ui-button scheme="turquoiseInverted" text="Start now" (click)="start()"></eon-ui-button>
  </div>
  <div fxFlex="none" class="five"></div>
</div>
