import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UnitConversion } from '../../../../domain/unit-conversion';
import { UnitConversionService } from '../../../../services/unit-conversion.service';

@Component({
  selector: 'eis-gateway-add-conversion-dialog',
  templateUrl: './add-conversion-dialog.component.html',
  styleUrls: ['./add-conversion-dialog.component.scss']
})
export class AddConversionDialogComponent implements OnInit {
  public conversion: UnitConversion;
  public startUnit: FormControl<string | null>;
  public endUnit: FormControl<string | null>;
  public factor: FormControl<string | null>;
  public formGroup: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {conversion: UnitConversion},
              public dialogRef: MatDialogRef<AddConversionDialogComponent>,
              private uniConversionService: UnitConversionService) {
    this.conversion = data.conversion;
  }

  ngOnInit(): void {
    this.startUnit = new FormControl(this.conversion?.startUnit ?? '', [Validators.required]);
    this.endUnit = new FormControl(this.conversion?.endUnit ?? '', [Validators.required]);
    this.factor = new FormControl(this.conversion?.factor ?? '', [Validators.required]);

    this.formGroup = new FormGroup<any>({
      startUnit: this.startUnit,
      endUnit: this.endUnit,
      factor: this.factor,
    });
  }

  update() {
    if(this.formGroup.valid) {
      if(this.conversion) {
        this.conversion.startUnit = this.startUnit.value!;
        this.conversion.endUnit = this.endUnit.value!;
        this.conversion.factor = this.factor.value!;
        this.uniConversionService.updateConversion(this.conversion).subscribe({
          next: (_) => {
            this.dialogRef.close({status: 200});
          },
          error: (err) => this.dialogRef.close(err)
        });
      } else{
        const conversion = {
          startUnit: this.startUnit.value!,
          endUnit: this.endUnit.value!,
          factor: this.factor.value!,
        } as UnitConversion;

        this.uniConversionService.createConversion(conversion).subscribe({
          next: (_) => {
            this.dialogRef.close({status: 200});
          },
          error: (err) => this.dialogRef.close(err)
        });
      }
    } else {
      this.markAsTouched(this.formGroup);
    }
  }

  markAsTouched(formGroup: FormGroup) {
    formGroup.markAsTouched({ onlySelf: true });
    Object.keys(formGroup.controls).map((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl || control instanceof FormArray || control) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

}
