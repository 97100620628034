<eis-gateway-app-shell>
  <eis-gateway-content-shell
    [headline]="'welcome.page.headline' | translate"
    [subtext]="'welcome.page.connection-prompt' | translate"
    [stepindicator]="'welcome.page.stepindicator' | translate" [stepheadline]="'welcome.page.stepheadline' | translate">
    <div class="connection-container" fxLayout fxLayoutGap="46px">

      <eis-gateway-connection-card (cardClick)="this.authService.startAzureOauth()" connection="e.IoT"
                                   [connected]=connectionStatus?.eiot?.connectionEstablished></eis-gateway-connection-card>
      <eis-gateway-connection-card (cardClick)="this.authService.startGridxOauth()" connection="gridX"
                                   [connected]=connectionStatus?.gridx?.connectionEstablished></eis-gateway-connection-card>

    </div>

    <eon-ui-button class="next-button" [disabled]=!connectionStatus?.fullyConnected [text]="'welcome.page.buttontext' | translate"
                   [routerLink]="'/tenants'"></eon-ui-button>

  </eis-gateway-content-shell>
</eis-gateway-app-shell>
