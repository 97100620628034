import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { map, Observable, of } from "rxjs";
import { Device, DeviceLabel, MBusDeviceScan, MBusDevice, RtuDevice } from "../domain/device.interface";
import {checkFullyConnected} from "../components/decorators/checkConnectedDecorator";
import {Comport} from "../domain/comport";
import {triggerLoader} from "../components/decorators/loaderDecorator";

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(private http: HttpClient) { }

  @triggerLoader
  @checkFullyConnected
  public createDevice(deviceName: string, ipAddress: string, portNumber: string, unitId: string, gatewaySerial: string, aggregateRate: number, file: File, labels: DeviceLabel[], dataPointLabels: DeviceLabel[]): Observable<Device> {
    const data = this.prepareCreateDevicePostPayload(deviceName, ipAddress, portNumber, unitId, gatewaySerial, aggregateRate, file, labels, dataPointLabels);
    return this.http.post<Device>('/api/devices', data) as Observable<Device>;
  }

  @triggerLoader
  @checkFullyConnected
  public updateDevice(id: string, deviceName: string, ipAddress: string, portNumber: string, unitId: string, gatewaySerial: string, aggregateRate: number, labels: DeviceLabel[], dataPointLabels: DeviceLabel[]): Observable<Device> {
    const data = this.prepareUpdateDevicePostPayload(id, deviceName, ipAddress, portNumber, unitId, gatewaySerial, aggregateRate, null, labels, dataPointLabels);
    return this.http.put<Device>('/api/devices', data) as Observable<Device>;
  }

  @triggerLoader
  @checkFullyConnected
  public createRtuDevice(deviceName: string, unitId: string, portName: string, gatewaySerial: string, aggregateRate: number, file: File, labels: DeviceLabel[], dataPointLabels: DeviceLabel[]): Observable<Device> {
    const data = this.prepareCreateRtuDevicePostPayload(deviceName, portName, unitId, gatewaySerial, aggregateRate, file, labels, dataPointLabels);
    return this.http.post<Device>('/api/devices/rtu', data) as Observable<Device>;
  }

  @triggerLoader
  @checkFullyConnected
  public updateRtuDevice(id: string, deviceName: string, unitId: string, portName: string, gatewaySerial: string, aggregateRate: number, labels: DeviceLabel[], dataPointLabels: DeviceLabel[]): Observable<Device> {
    const data = this.prepareUpdateRtuDevicePostPayload(id, deviceName, portName, unitId, gatewaySerial, aggregateRate, null, labels, dataPointLabels);
    return this.http.put<Device>('/api/devices/rtu', data) as Observable<Device>;
  }

  @triggerLoader
  @checkFullyConnected
  public updateDeviceCSV(id: string, deviceName: string, ipAddress: string, portNumber: string, unitId: string, gatewaySerial: string, aggregateRate: number, file: File, labels: DeviceLabel[], deleteDatapoints: boolean): Observable<any> {
    const data = this.prepareUpdateDevicePostPayload(id, deviceName, ipAddress, portNumber, unitId, gatewaySerial, aggregateRate, file, labels, []);
    return this.http.put<Device>('/api/devices/csv?deleteInEiot=' + deleteDatapoints, data) as Observable<Device>;
  }

  @triggerLoader
  @checkFullyConnected
  public updateDeviceCSVLabels(id: string, deviceName: string, ipAddress: string, portNumber: string, unitId: string, gatewaySerial: string, aggregateRate: number, file: File, labels: DeviceLabel[]): Observable<any> {
    const data = this.prepareUpdateDevicePostPayload(id, deviceName, ipAddress, portNumber, unitId, gatewaySerial, aggregateRate, file, labels, []);
    return this.http.put<Device>('/api/devices/csvLabels', data) as Observable<Device>;
  }

  private prepareCreateDevicePostPayload(deviceName: string, ipAddress: string, portNumber: string, unitId: string, gatewaySerial: string, aggregationBatchSize: number, file: File | null, labels: DeviceLabel[], dataPointLabels: DeviceLabel[]) {
    const data = new FormData();

    data.append("deviceName", deviceName);
    data.append("ipAddress", ipAddress);
    data.append("portNumber", portNumber);
    data.append("unitId", unitId);
    data.append("gatewaySerial", gatewaySerial);
    data.append("aggregationBatchSize", aggregationBatchSize.toString());
    if(file != null) {
      data.append("datapoints", file, file.name);
    }
    data.append("labels", JSON.stringify(labels));
    data.append("dataPointLabels", JSON.stringify(dataPointLabels));
    return data;
  }

  private prepareCreateRtuDevicePostPayload(deviceName: string, portName: string, unitId: string, gatewaySerial: string, aggregationBatchSize: number, file: File | null, labels: DeviceLabel[], dataPointLabels: DeviceLabel[]) {
    const data = new FormData();

    data.append("deviceName", deviceName);
    data.append("portName", portName)
    data.append("unitId", unitId);
    data.append("gatewaySerial", gatewaySerial);
    data.append("aggregationBatchSize", aggregationBatchSize.toString());
    if(file != null) {
      data.append("datapoints", file, file.name);
    }
    data.append("labels", JSON.stringify(labels));
    data.append("dataPointLabels", JSON.stringify(dataPointLabels));

    return data;
  }

  private prepareUpdateDevicePostPayload(id: string, deviceName: string, ipAddress: string, portNumber: string, unitId: string, gatewaySerial: string, aggregationBatchSize: number, file: File | null, labels: DeviceLabel[], dataPointLabels: DeviceLabel[]) {
    const data = this.prepareCreateDevicePostPayload(deviceName, ipAddress, portNumber, unitId, gatewaySerial, aggregationBatchSize, file, labels, dataPointLabels);
    data.append("id", id);

    return data;
  }

  private prepareUpdateRtuDevicePostPayload(id: string, deviceName: string, portName: string, unitId: string, gatewaySerial: string, aggregationBatchSize: number, file: File | null, labels: DeviceLabel[], dataPointLabels: DeviceLabel[]) {
    const data = this.prepareCreateRtuDevicePostPayload(deviceName, portName, unitId, gatewaySerial, aggregationBatchSize, file, labels, dataPointLabels);
    data.append("id", id);

    return data;
  }

  @triggerLoader
  @checkFullyConnected
  public checkCreateDevice(deviceName: string, ipAddress: string, portNumber: string, unitId: string, gatewaySerial: string, aggregationBatchSize: number, file: File, labels: DeviceLabel[], dataPointLabels: DeviceLabel[]): Observable<any> {
    const data = this.prepareCreateDevicePostPayload(deviceName, ipAddress, portNumber, unitId, gatewaySerial, aggregationBatchSize, file, labels, dataPointLabels);
    return this.http.post<any>('/api/devices/datacheck', data) as Observable<any>;
  }

  @triggerLoader
  @checkFullyConnected
  public checkCreateRtuDevice(deviceName: string, portName: string, unitId: string, gatewaySerial: string, aggregationBatchSize: number, file: File, labels: DeviceLabel[], dataPointLabels: DeviceLabel[]): Observable<any> {
    const data = this.prepareCreateRtuDevicePostPayload(deviceName, portName, unitId, gatewaySerial, aggregationBatchSize, file, labels, dataPointLabels);
    return this.http.post<any>('/api/devices/datacheck-rtu', data) as Observable<any>;
  }

  @triggerLoader
  @checkFullyConnected
  public validateDevice(id: string, deviceName: string, ipAddress: string, portNumber: string, portName: string, unitId: string, gatewaySerial: string, aggregationBatchSize: number, file: File, labels: DeviceLabel[], dataPointLabels: DeviceLabel[]): Observable<any> {
    const data = this.prepareUpdateDevicePostPayload(id, deviceName, ipAddress, portNumber, unitId, gatewaySerial, aggregationBatchSize, file, labels, dataPointLabels);
    data.append("portName", portName);
    return this.http.post<any>('/api/devices/validate', data) as Observable<any>;
  }

  public getDevices(serial: string): Observable<Device[]> {
    return this.http.get<Device[]>('/api/devices/' + serial).pipe(
      map((devices: Device[]) => {
        for(let device of devices) {
          if(device.labels != null) {
            device.labels = JSON.parse(device.labels?.toString());
          }
        }
        return devices;
      })
    );
  }

  @triggerLoader
  @checkFullyConnected
  public getDeviceByName(serial: string, name: string): Observable<Device> {
    return this.http.get<Device>('/api/devices/' + serial + '/device/' + name);
  }

  @triggerLoader
  @checkFullyConnected
  public deleteDevice(device: Device, deleteInEoit: boolean = false): Observable<any> {
    return this.http.delete<Device>('/api/devices/' + device.id + "?deleteInEiot=" + deleteInEoit) as Observable<any>;
  }

  @triggerLoader
  @checkFullyConnected
  validateMbusDataPointLabels(gatewaySerial: string, deviceId: string, file: File) {
    const data = new FormData();
    data.append("gatewaySerial", gatewaySerial);
    data.append("deviceId", deviceId);
    data.append("dataPoints", file, file.name);
    return this.http.post<any>('/api/devices/mbus/datapoint/labels/validate', data) as Observable<any>;
  }

  @triggerLoader
  @checkFullyConnected
  updateMbusDataPointLabels(gatewaySerial: string, deviceId: string, file: File) {
    const data = new FormData();
    data.append("gatewaySerial", gatewaySerial);
    data.append("deviceId", deviceId);
    data.append("dataPoints", file, file.name);
    return this.http.put<any>('/api/devices/mbus/datapoint/labels', data) as Observable<any>;
  }

  @triggerLoader
  @checkFullyConnected
  updateMbusDeviceLabels(gatewaySerial: string, deviceId: string, deviceLabels: string, dataPointLabels: string) {
    const data = new FormData();
    data.append("gatewaySerial", gatewaySerial);
    data.append("deviceId", deviceId);
    data.append("deviceLabels", deviceLabels);
    data.append("dataPointLabels", dataPointLabels);
    return this.http.put<any>('/api/devices/mbus/device/labels', data) as Observable<any>;
  }
}
