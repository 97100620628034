import {Injectable} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private snackBar: MatSnackBar,
                private translateService: TranslateService) {
    }

    async success(message: string, interpolateParams?: Object, duration: number = 5000) {
        this.showMessage('notification-success', this.translateService.instant(message, interpolateParams), duration);
    }

    async failure(message: string, interpolateParams?: Object, duration?: number) {
        this.showMessage('notification-failure', this.translateService.instant(message, interpolateParams), duration);
    }

    private showMessage(panelClass: string, message: string, duration?: number) {
        this.snackBar.open(message, 'X', {
            panelClass: panelClass,
            verticalPosition: 'bottom',
            horizontalPosition: 'right',
          duration: duration
        });
    }
}
