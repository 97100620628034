import {AuthService} from "../../services/auth.service";
import {concatMap} from "rxjs";
import {tap} from "rxjs/operators";

let authService: AuthService

export function setAuthService(a: AuthService) {
  authService = a
}

export function checkFullyConnected(target: any, propertyKey: string, descriptor: PropertyDescriptor) {

  let originalMethod = descriptor.value
  descriptor.value = function (...args: any[]) {

    //console.log("Called")
    //console.log(this)
    //console.log("authService ", authService)

    return authService.getConnectionStatus().pipe(
      tap(value => console.log("Before: ", value)),
      concatMap(source => {
          if (source.fullyConnected) {
            return originalMethod.apply(this, args);
          } else {
            throw new Error("Not fully connected!");
          }
        }
      ),
      tap(value => console.log("After: ", value))
    )
  }
  return descriptor
}



