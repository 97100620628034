import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges, TemplateRef,
  ViewChild
} from '@angular/core';
import { MBusConfig, MBusDataPoint, MBusDevice } from '../../../../domain/device.interface';
import { Comport } from '../../../../domain/comport';
import { MBusScanDataPointsPayload } from '../../../../domain/mbus-scan-datapoints-payload';
import { MatTableDataSource } from '@angular/material/table';
import { GridxDeviceService } from '../../../../services/gridx-device.service';
import { NotificationService } from '../../../../services/notification.service';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';
import {interval, Subscription, takeUntil, timer} from "rxjs";
import { NgxDataToCsvService } from '../../../../services/ngx-data-to-csv.service';

@Component({
  selector: 'eis-gateway-mbus-datapoint-configurator-table',
  templateUrl: './mbus-datapoint-configurator-table.component.html',
  styleUrls: ['./mbus-datapoint-configurator-table.component.scss']
})
export class MbusDatapointConfiguratorTableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('confirmTemplate') confirmTemplate!: TemplateRef<any>;

  @Input()
  public gatewaySerial: string | undefined;

  @Input()
  public location: string | undefined;

  @Input()
  public device: MBusDevice |undefined;

  @Input()
  public port: Comport | undefined;

  @Input()
  public mBusConfig: MBusConfig[] = [];

  @Input()
  public addedDataPoints: {[key: string]: MBusDataPoint[]}  = {};

  @Input()
  public removedDataPoints: {[key: string]: MBusDataPoint[]}  = {};

  @Input()
  public drawer: MatSidenav | undefined;

  @Output()
  public dataPointsUpdated = new EventEmitter<any>();

  public pollingFrequencyControl = new FormControl(15);

  public scanningDeviceDataPoints = false;
  public dataPointScanDate: Date | null;

  public displayedColumns: string[] = ['select', 'key', 'data_type', 'value', 'unit', 'function', 'storageNumber', 'tariff'];
  public deviceAddedDataPoints: MBusDataPoint[] = [];
  public deviceRemovedDataPoints: MBusDataPoint[] = [];
  public dataPointsDataSource = new MatTableDataSource<MBusDataPoint>([]);

  private deviceDataPoints: MBusDataPoint[] = [];
  private initialDataPoints: MBusDataPoint[] = [];
  private originalPollingFrequency = 15;
  private scanResultSubscription: Subscription | null;

  constructor(
    private gridxService: GridxDeviceService,
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.device && this.port) {
      this.getDeviceDataPoints();
    }

    if(this.addedDataPoints && this.device) {
      this.deviceAddedDataPoints = this.addedDataPoints[this.device.id] ?? [];
    }

    if(this.removedDataPoints && this.device) {
      this.deviceRemovedDataPoints = this.removedDataPoints[this.device.id] ?? [];
    }
  }

  ngOnDestroy(): void {
    console.log("Dialog closed");
    this.scanResultSubscription?.unsubscribe();
  }

  applyChanges() {
    this.dialog.closeAll();
    this.initialDataPoints = [...this.deviceAddedDataPoints];
    const originalDataPoints = this.deviceDataPoints.filter(dp => this.isInMbusConfig(dp));
    const removedDataPoints = originalDataPoints.filter(dp => this.findDataPointIndex(this.deviceAddedDataPoints, dp) == -1);
    removedDataPoints.forEach((dp: MBusDataPoint) => {
      dp.baudrate = this.port?.baudRate!;
      dp.pollingFrequency = this.pollingFrequencyControl.value!;
      dp.secondaryAddress = this.device!.id;
      dp.serialPort = this.port!.port;
    });

    const addedDataPoints = this.deviceAddedDataPoints.filter(dp => this.findDataPointIndex(originalDataPoints, dp) == -1);
    addedDataPoints.forEach((dp: MBusDataPoint) => {
      dp.baudrate = this.port?.baudRate!;
      dp.pollingFrequency = this.pollingFrequencyControl.value!;
      dp.secondaryAddress = this.device!.id;
      dp.serialPort = this.port!.port;
    });

    let updatedDataPoints: MBusDataPoint[] = [];
    if(this.pollingFrequencyControl.value != this.originalPollingFrequency) {
      updatedDataPoints = originalDataPoints.filter(dp => this.findDataPointIndex(removedDataPoints, dp) == -1);
      updatedDataPoints.forEach((dp: MBusDataPoint) => {
        dp.baudrate = this.port?.baudRate!;
        dp.pollingFrequency = this.pollingFrequencyControl.value!;
        dp.secondaryAddress = this.device!.id;
        dp.serialPort = this.port!.port;
      });
    }

    this.dataPointsUpdated.emit({ added: addedDataPoints, updated: updatedDataPoints, removed: removedDataPoints });
  }

  rollbackChanges() {
    this.dialog.closeAll();
    this.deviceAddedDataPoints = [...this.dataPointsDataSource.data.filter(dp => this.getMbusConfig(dp))];
    this.addedDataPoints[this.device!.id] = [];
    this.removedDataPoints[this.device!.id] = [];
    this.deviceRemovedDataPoints = [];
    this.dataPointsUpdated.emit({ added: [], updated: [], removed: [] });
  }

  scanDataPoints() {
    if(this.port && this.device) {
      this.dataPointsDataSource = new MatTableDataSource();
      this.scanningDeviceDataPoints = true;

      const scanPayload = {
        port: this.port.port,
        baudrate: this.port.baudRate,
        secondary_address: this.device.id!,
      } as MBusScanDataPointsPayload;

      this.gridxService.scanDataPoints(this.gatewaySerial!, scanPayload).subscribe(result => {
        if (result) {
          const timer$ = timer(120 * 1000)
          this.checkDatapoints();
          this.scanResultSubscription = interval(10 * 1000).pipe(takeUntil(timer$)).subscribe(() => this.checkDatapoints());
        }
      }, (error) => {
        this.notificationService.failure(error.error);
        this.scanningDeviceDataPoints = false
      });
    }
  }

  checkDatapoints() {
    if (this.port && this.device) {

      const scanPayload = {
        port: this.port.port,
        baudrate: this.port.baudRate,
        secondary_address: this.device.id!,
      } as MBusScanDataPointsPayload;

      this.gridxService.getMbusDatapoints(this.gatewaySerial!!, scanPayload).subscribe(scanResult => {

        if(scanResult.done && scanResult.error == null) {
          this.loadDatapoints(scanPayload.secondary_address);
          this.scanResultSubscription?.unsubscribe();
        }

        if(scanResult.done && scanResult.error != null) {
          this.notificationService.failure(scanResult.error);
          this.scanningDeviceDataPoints = false;
          this.scanResultSubscription?.unsubscribe();
        }
      });
    }
  }

  loadDatapoints(secondaryAddress: string) {

    this.gridxService.getMBusDeviceDataPoints(this.gatewaySerial!!, secondaryAddress).subscribe(scanResult => {
        if (scanResult!!.length > 0) {
          this.dataPointScanDate = scanResult!![0].lastScanDate;
        }

        this.device!.hasDataPointScan = true;
        this.scanningDeviceDataPoints = false;
        this.dataPointsDataSource = new MatTableDataSource(this.sortDataPointsBySelection(scanResult!!));
        this.dataPointsDataSource.sort = this.sort;
        this.dataPointsDataSource.sortingDataAccessor = this.sortingDataAccessor;
      });
    }


  getDeviceDataPoints() {
    this.gridxService.getMBusDeviceDataPoints(this.gatewaySerial!, this.device!.id!).subscribe(dataPoints => {
      if(dataPoints != null && dataPoints.length > 0) {
        this.deviceDataPoints = dataPoints;
        this.dataPointScanDate = dataPoints[0].lastScanDate;

        this.deviceRemovedDataPoints = dataPoints.filter(d => this.getMbusConfig(d)?.actionType == "delete");
        this.deviceAddedDataPoints = dataPoints.filter(d => this.isAdded(d))

        for(let dataPoint of this.deviceAddedDataPoints) {
          const config = this.findConfigOfDataPoint(dataPoint);
          if(config) {
            dataPoint.pollingFrequency = config.pollingFrequency;
          }
        }

        this.initialDataPoints = [...this.deviceAddedDataPoints];

        this.dataPointsDataSource = new MatTableDataSource(this.sortDataPointsBySelection(dataPoints));
        this.dataPointsDataSource.sort = this.sort;
        this.dataPointsDataSource.sortingDataAccessor = this.sortingDataAccessor;
      } else {
        this.deviceDataPoints = [];
        this.dataPointScanDate = null;
        this.dataPointsDataSource = new MatTableDataSource<MBusDataPoint>();
        this.dataPointsDataSource.sort = this.sort;
        this.dataPointsDataSource.sortingDataAccessor = this.sortingDataAccessor;
      }

      const firstDataPointInConfig = this.mBusConfig.find(it => it.secondaryAddress == this.device?.id);
      if(firstDataPointInConfig) {
        this.pollingFrequencyControl.patchValue(firstDataPointInConfig.pollingFrequency);
        this.originalPollingFrequency = firstDataPointInConfig.pollingFrequency;
      }
    });
  }


  toggleAllRows() {
    if (this.isAllSelected() || this.isNoneSelected()) {
      for(let i = 0; i < this.dataPointsDataSource.data.length; i++) {
        this.toggle(this.dataPointsDataSource.data[i]);
      }
    }
    else {
      for(let i = 0; i < this.dataPointsDataSource.data.length; i++) {
        if(this.findDataPointIndex(this.deviceAddedDataPoints, this.dataPointsDataSource.data[i]) == -1) {
          this.toggle(this.dataPointsDataSource.data[i]);
        }
      }
    }
  }

  isAllSelected() {
    const numSelected = this.deviceAddedDataPoints.length;
    const numRows = this.dataPointsDataSource.data.length;
    return numSelected === numRows;
  }

  isNoneSelected() {
    return this.deviceAddedDataPoints.length == 0;
  }

  findDataPointIndex(dataPoints: MBusDataPoint[], point: MBusDataPoint): number {
    return dataPoints.findIndex(dt => dt.key == point.key);
  }

  isSelected(dataPoint: MBusDataPoint): boolean {
    return this.findDataPointIndex(this.deviceAddedDataPoints, dataPoint) != -1;
  }

  toggle(dataPoint: MBusDataPoint) {
    let index = this.findDataPointIndex(this.deviceAddedDataPoints, dataPoint);
    if(index > -1) {
      this.deviceAddedDataPoints.splice(index, 1);
      if(this.getMbusConfig(dataPoint)) {
        this.deviceRemovedDataPoints.push(dataPoint);
      }
    } else {
      this.deviceAddedDataPoints.push(dataPoint);
      index = this.findDataPointIndex(this.deviceRemovedDataPoints, dataPoint);
      if(index > -1) {
        this.deviceRemovedDataPoints.splice(index, 1);
      }
    }
  }

  isNew(dataPoint: MBusDataPoint): boolean {
    const config = this.getMbusConfig(dataPoint);
    return this.findDataPointIndex(this.deviceAddedDataPoints, dataPoint) != -1 && (!config || config.actionType == "add");
  }

  isAdded(dataPoint: MBusDataPoint): boolean {
    const config = this.getMbusConfig(dataPoint);
    return !!config && config.actionType != "delete";
  }

  isDeleted(dataPoint: MBusDataPoint): boolean {
    return this.findDataPointIndex(this.deviceRemovedDataPoints, dataPoint) != -1;
  }

  closeDrawer() {
    this.drawer?.close();
  }

  isDirty() {
    return this.deviceRemovedDataPoints.length > 0 ||
      this.deviceAddedDataPoints.length != this.initialDataPoints.length ||
      this.deviceAddedDataPoints.find(dp => this.findDataPointIndex(this.initialDataPoints, dp) == -1);
  }

  private getMbusConfig(dataPoint: MBusDataPoint): MBusConfig | undefined {
    return this.findConfigOfDataPoint(dataPoint);
  }

  private isInMbusConfig(dataPoint: MBusDataPoint): boolean {
    const config = this.findConfigOfDataPoint(dataPoint);

    return config != undefined && config.actionType != "delete";
  }

  private findConfigOfDataPoint(dataPoint: MBusDataPoint): MBusConfig | undefined {
    return this.mBusConfig.find(conf => conf.key == dataPoint.key &&
      conf.serialPort == this.port!.port &&
      conf.secondaryAddress == this.device?.id);
  }

  private sortDataPointsBySelection(dataPoints: MBusDataPoint[]): MBusDataPoint[] {
    const selectedDataPoints = this.deviceAddedDataPoints;
    return dataPoints.sort((a, b) =>
      this.findDataPointIndex(selectedDataPoints, a) > -1 && this.findDataPointIndex(selectedDataPoints, b) == -1
        ? -1
        : this.findDataPointIndex(selectedDataPoints, b) > -1 && this.findDataPointIndex(selectedDataPoints, a) == -1
          ? 1
          : parseInt(a.key) > parseInt(b.key)
            ? 1 : -1
    );
  }

  private sortingDataAccessor(data: any, sortHeaderId: string): any {
    if(sortHeaderId == "key") {
      return parseInt(data[sortHeaderId]);
    }
    else if (typeof data[sortHeaderId] === 'string') {
      return data[sortHeaderId].toLocaleLowerCase();
    }

    return data[sortHeaderId];
  }
}
