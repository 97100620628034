import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError, map, Observable} from "rxjs";
import {ConnectionStatus} from "../domain/login.interface";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  gridxOauthUrl = '/api/gridx/authorize?redirect='
  azureOauthUrl = '/api/eon-iot/authorize?redirect='
  loginUrl = '/api/login/start?redirect='
  rolesUrl = '/api/login/roles'
  connectionStatusUrl = '/api/login/connectionstatus'
  logoutUrl = '/api/logout/start?redirect='

  constructor(private http: HttpClient) {
  }

  startGridxOauth() {
    location.href = this.gridxOauthUrl + location.href
  }

  startAzureOauth() {
    location.href = this.azureOauthUrl + location.href
  }

  startLogout() {
    location.href = this.logoutUrl + location.href
  }

  getRoles(): Observable<string> {
    return this.http.get<string>(this.rolesUrl)
  }

  getConnectionStatus(): Observable<ConnectionStatus> {
    return this.http.get<any>(this.connectionStatusUrl)
      .pipe(
        map(data => new ConnectionStatus(
          {connectionEstablished: data.gridx.connectionEstablished},
          {connectionEstablished: data.eiot.connectionEstablished}
        ))
      )
  }
}
