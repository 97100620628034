import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, tap } from 'rxjs/operators';

import { MissingTranslationHandlerParams, TranslateService } from '@ngx-translate/core';

export const isString = (value: any): value is string => {
  return typeof value === 'string';
};

export const trimText = (text?: string | null): string => {
  return (isString(text) ? text : '').trim();
};

export const sanitize = (value: any): string => {
  return trimText(value).toLowerCase();
};

@Injectable({providedIn: 'root'})
export class InitTranslateService {

  /**
   * Emits immediately current selected language as lower-case string and every single time language is changed afterwards.
   *
   * @publicApi
   */
  language$!: Observable<string>;

  constructor(private translateService: TranslateService) {
  }

  /**
   * Initializes this service by configure translation source, default language,
   * available languages and uses localStorage with key 'language'
   * for read / write user selected language.
   *
   * @see TranslateLoader
   * @see TranslateConfiguration
   *
   * Has to be called once during bootstrapping application.
   *
   * Returned observable emits after loading default and current selected language source.
   */
  init$(): Observable<any> {
    this.configureMissingTranslationHandler();
    this.configureDefaultAndAvailableLanguages();

    const currentLanguage = this.determineUserSelectedLanguage();

    // Preload default and current language
    return this.translateService.use(this.translateService.defaultLang)
      .pipe(
        mergeMap(() => this.translateService.use(currentLanguage)),
        tap(() => this.setupLanguageObservable(currentLanguage))
      );
  }

  /**
   * Changes to given language and writes to localStorage with key 'language'.
   *
   * Returned observable emits after loading selected language source or immediately if current language is given language.
   * On same event {@link language$} emits.
   *
   * @param lang selected language
   *
   * @publicApi
   */
  changeLanguage$(lang: string): Observable<any> {
    return this.translateService.use(sanitize(lang));
  }

  updateTranslation(translation: object): void {
    this.translateService.setTranslation(this.translateService.currentLang, translation);
  }

  private configureMissingTranslationHandler(): void {
    this.translateService.missingTranslationHandler = {
      handle: (params: MissingTranslationHandlerParams) => {
        return params.key ? (params.key.substring(params.key.lastIndexOf('.') + 1, params.key.length) || '').trim() : '';
      }
    };
  }

  private configureDefaultAndAvailableLanguages(): void {
    const availableLanguages = ['en'];
    this.translateService.addLangs(availableLanguages);
    this.translateService.setDefaultLang(availableLanguages[0]);
  }

  private determineUserSelectedLanguage(): string {
    const storedLanguage = sanitize(localStorage.getItem('language'));
    return this.translateService.getLangs()
      .some(supportedLanguage => supportedLanguage === storedLanguage) ?
      storedLanguage :
      this.translateService.defaultLang;
  }

  private setupLanguageObservable(currentLanguage: string): void {
    const languageSubject$ = new BehaviorSubject(currentLanguage);

    this.language$ = languageSubject$.pipe(distinctUntilChanged());

    this.translateService.onLangChange
      .pipe(
        map(langChangeEvent => sanitize(langChangeEvent.lang)),
        tap(lang => localStorage.setItem('language', lang))
      )
      .subscribe(languageSubject$);
  }
}
