<div mat-dialog-title>
  <eon-ui-icon fxLayout fxLayoutAlign="end" [mat-dialog-close]="false" class=closeX name="close"></eon-ui-icon>
  <div class="headline">{{'delete-gateway.headline' | translate}}</div>
</div>

<ng-container>
  <mat-dialog-content fxFlex>
    <div>
      <div fxLayout="column">
        <div fxLayoutAlign="center center">
          <mat-icon svgIcon="eon-trash-bin" class="delete-icon"></mat-icon>
        </div>
        <div class="stepheadline" fxLayoutAlign="center center">{{'delete-gateway.subheadline' | translate}}</div>
        <p fxLayoutAlign="center center"
           class="confirmation-paragraph">{{'delete-gateway.confirmation-paragraph' | translate}}</p>
        <div class="bottom-container" fxLayoutAlign="center center" fxLayout="column">
          <div>{{'general.serialnumber' | translate}}</div>
          <p class="stepheadline">{{this.gatewaySerial}}</p>
        </div>
        <div class="unable-to-delete" fxLayoutAlign="center center" *ngIf="!deletionAllowed">{{'delete-gateway.unable-to-delete' | translate}}</div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="justify-content: space-between;">
    <eon-ui-button scheme="redInverted" icon="close" text="{{'general.cancel' | translate}}"
                   mat-dialog-close></eon-ui-button>
    <eon-ui-button (click)="deleteGateway()" [disabled]="!deletionAllowed"
                   text="{{'general.button.complete-process' | translate}}"></eon-ui-button>
  </mat-dialog-actions>

</ng-container>
