import {Component, Input, OnInit} from '@angular/core';
import { Comport, ComportProtocols } from "../../../domain/comport";
import { FormBuilder, FormGroup } from "@angular/forms";
import {NotificationService} from "../../../services/notification.service";
import { combineLatest, Observable } from "rxjs";
import {ComportService} from "../../../services/comport.service";
import { DeviceService } from '../../../services/device.service';
import {Device, MBusConfig, MBusDevice} from '../../../domain/device.interface';
import {GridxDeviceService} from "../../../services/gridx-device.service";

@Component({
  selector: 'eis-gateway-gateway-com-port-config',
  templateUrl: './gateway-com-port-config.component.html',
  styleUrls: ['./gateway-com-port-config.component.scss']
})
export class GatewayComPortConfigComponent implements OnInit {

  @Input()
  public serial: string;

  public availableBaudrates = [1200, 2400, 4800, 9600, 19200, 115200];
  public availableMbusBaudrates = [300, 600, 1200, 2400, 4800, 9600];
  public availableParity = ["none", "even", "odd"];
  public availableStopBits = [1, 2];
  public comportConfigs$: Observable<Comport[]>;
  public devices$: Observable<Device[]>;
  public mbusConfigs$: Observable<MBusConfig[]>
  public comportConfigFormArray: FormGroup[] = [];
  public fromGroupEditable: boolean[] = [];
  public portsInUse: {[key: string]: boolean} = {};
  public portsNotAvailable: {[key: string]: boolean} = {};
  public portProtocols: {[key: string]: Array<string>} = {
    'RS232 COM1': [ComportProtocols.MBus],
    'RS232 COM2': [ComportProtocols.MBus],
    'RS485 COM3': [ComportProtocols.ModbusRtu, ComportProtocols.MBus],
    'RS485 COM4': [ComportProtocols.ModbusRtu, ComportProtocols.MBus],
  }

  constructor(public notificationService: NotificationService,
              public comportService: ComportService,
              public deviceService: DeviceService,
              public gridxService: GridxDeviceService,
              public fb: FormBuilder) {
  }

  ngOnInit(): void {

    this.comportConfigs$ = this.comportService.getComportConfigs(this.serial)
    combineLatest([
        this.comportConfigs$,
      ]).subscribe(data => {
      const portConfigs = data[0];
      this.addMissingSlash(portConfigs);

      for(const config of portConfigs) {
        this.portsInUse[config.portName] = config.inUse;
        this.comportConfigFormArray.push(this.getFormGroup(config));
      }
      for(const config of portConfigs) {
        this.portsNotAvailable[config.portName] = this.comPortNotAvailable(portConfigs, config);
      }
    });
  }

  enableEditing(index: number) {
    this.fromGroupEditable[index] = true;
    if(this.comportConfigFormArray[index].controls["protocol"].value == ComportProtocols.MBus) {
      this.comportConfigFormArray[index].controls["baudRate"].enable();
      this.comportConfigFormArray[index].controls["protocol"].enable();
      this.comportConfigFormArray[index].controls["parity"].patchValue("none");
      this.comportConfigFormArray[index].controls["parity"].disable();
      this.comportConfigFormArray[index].controls["stopBits"].patchValue("1");
      this.comportConfigFormArray[index].controls["stopBits"].disable();
    } else {
      for (const control in this.comportConfigFormArray[index].controls) {
        this.comportConfigFormArray[index].controls[control].enable();
      }
    }
  }

  disableEditing(index: number) {
    this.fromGroupEditable[index] = false;
    for(const control in this.comportConfigFormArray[index].controls) {
      this.comportConfigFormArray[index].controls[control].disable();
    }
  }

  saveAllSettings() {
    if (this.isFormValid(this.comportConfigFormArray)) {
      const configs = this.comportConfigFormArray.map(g => g.getRawValue());
      this.comportService.updateComportConfigs(configs, this.serial).subscribe({
        next: (_) => {
          this.notificationService.success("comportsettings-update.success")
          this.disableAllForms()
        },
        error: (err) => this.notificationService.failure("comportsettings-update.error")
      });
    }
  }

  isFormValid(formArray: FormGroup[]) {
    for(const group of formArray) {
      if(!group.disabled && !group.valid) {
        return false;
      }
    }
    return true;
  }

  disableAllForms() {
    this.comportConfigFormArray.forEach(g => g.disable());
  }

  public compareFn(object1: any, object2: any) {
    return object1 && object2 && object1 == object2;
  }

  public convertParity(parity: string) {
    switch(parity) {
      case "N":
        return "none";
      case "O":
        return "odd";
      case "E":
        return "even";
      default:
        return "none";
    }
  }

  comPortNotAvailable(comportConfigs: Comport[], comport: Comport) : boolean {
    const ports = comportConfigs.filter(c => c.port == comport.port && c.portName != comport.portName);
    for(const port of ports) {
      if(this.portsInUse[port.portName]) {
        return true;
      }
    }
    return false;
  }

  private getFormGroup(data: Comport) {
    const formGroup = this.fb.group({
      portName: {value: data.portName, disabled: true},
      port: {value: data.port, disabled: true},
      baudRate: {value: data.baudRate, disabled: true},
      parity: {value: this.convertParity(data.parity), disabled: true},
      stopBits: {value: data.stopBits, disabled: true},
      dataBits: {value: data.dataBits, disabled: true},
      protocol: {value: data.protocol, disabled: true},
    });
    formGroup.markAsPristine();

    return formGroup;
  }

  private addMissingSlash(portConfigs: Comport[]) {
    for(const config of portConfigs) {
      if(!config.port.startsWith("/")) {
        config.port = "/" + config.port;
      }
    }
  }
}
