import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'eis-gateway-content-shell',
  templateUrl: './content-shell.component.html',
  styleUrls: ['./content-shell.component.scss']
})
export class ContentShellComponent implements OnInit {
  @Input()
  headline: string = "";
  @Input()
  subtext: string = "";
  @Input()
  stepindicator: string = "";
  @Input()
  stepheadline: string = "";

  constructor() { }

  ngOnInit(): void {
  }

}
