<div class="connection" [class.notconnected]="!connected" fxLayout="column" (click)="onCardClick()">
  <div class="connection-top" fxFlex fxLayout fxLayoutAlign="center center">
    <p>{{connection}}</p>
  </div>
  <div class="connection-bottom" fxFlex="none" fxLayout="column" fxLayoutAlign="center center">
    <p>{{connected ? ('connection-card.connected' | translate) : ('connection-card.connect-now' | translate)}}</p>
    <div class="checkmark-container" fxLayout fxLayoutAlign="center center" fxFlex>
      <div fxLayout fxLayoutAlign="center center" class="checkmark">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 16">
          <path fill-rule="evenodd"
                d="M8.942 15.652c-.2 0-.403-.06-.579-.185l-7.35-5.227a1 1 0 011.16-1.63l6.535 4.647L17.584.78a1 1 0 111.63 1.16L9.757 15.232a1 1 0 01-.695.413l-.12.007z"></path>
        </svg>
      </div>
    </div>
  </div>

</div>
