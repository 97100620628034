import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {
  GridxDeviceLogLevels,
  GridxDeviceNetworkAdapters,
  GridxLogLevelDeviceConfig
} from "../../../domain/gridx-device.interface";
import {Observable} from "rxjs";
import {GridxDeviceService} from "../../../services/gridx-device.service";
import {NotificationService} from "../../../services/notification.service";

@Component({
  selector: 'eis-gateway-gateway-log-level-config',
  templateUrl: './gateway-log-level-config.component.html',
  styleUrls: ['./gateway-log-level-config.component.scss']
})
export class GatewayLogLevelConfigComponent implements OnInit {

  public outputLogLevelConfig: GridxLogLevelDeviceConfig = {
    metadata: {
      labels: {
        coreGridxAiloglevel: null,
      }
    }
  }

  @Input()
  public serial: string;

  public availableLogLevel = ['info', 'warn'];

  public logLevelConfig$: Observable<GridxDeviceLogLevels>;

  public logLevelConfigForm: FormGroup;

  public logLevelSelection: FormControl<string>;

  constructor(public fb: FormBuilder,
              public gridxService: GridxDeviceService,
              public notificationService: NotificationService) {
    this.logLevelConfigForm = this.fb.group(
      {
        logLevelSelection: {value: this.logLevelSelection, disabled: false},
      }
    )
  }

  ngOnInit(): void {
    this.logLevelConfig$ = this.gridxService.getLogLevelConfig(this.serial);

    this.logLevelConfig$.subscribe(logLevelConfig => {
      console.log(logLevelConfig)
      if (logLevelConfig.logLevel == null) {
        this.logLevelConfigForm.controls['logLevelSelection'].patchValue('warn')
      } else {
        this.logLevelConfigForm.controls['logLevelSelection'].patchValue(logLevelConfig.logLevel)
      }

      this.logLevelConfigForm.markAsPristine();
    });
  }

  public compareFn(object1: any, object2: any) {
    return object1 && object2 && object1 == object2;
  }

  saveSettings() {
    this.outputLogLevelConfig.metadata.labels.coreGridxAiloglevel =
      this.logLevelConfigForm.controls['logLevelSelection'].value

    this.gridxService.postMetaDataConfig(this.outputLogLevelConfig, this.serial).subscribe({
      next: (_) => {
        this.notificationService.success("Logging Config updated successfully!")
      },
      error: (err) => this.notificationService.failure("Logging Config could not be updated!")
    });
  }
}
