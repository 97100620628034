import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import { Observable } from "rxjs";
import {GatewayDeployment} from "../domain/gateway-deployment.interface";
import {checkFullyConnected} from "../components/decorators/checkConnectedDecorator";
import { DataPoint } from "../domain/datapoint.interface";

@Injectable({
  providedIn: 'root'
})
export class DeploymentService {

  constructor(private http: HttpClient) { }

  @checkFullyConnected
  public deleteDeployment(serial: string): Observable<any> {
    return this.http.delete('/api/deployments/', {params: {serial: serial}});
  }

  public getGatewayDeployment(serial: string): Observable<GatewayDeployment> {
    return this.http.get('/api/deployments/' + serial) as Observable<GatewayDeployment>;
  }

  public getGatewayDeploymentDataPoints(serial: string): Observable<DataPoint[]> {
    return this.http.get('/api/deployments/' + serial + "/datapoints") as Observable<DataPoint[]>;
  }

  public getMergedGatewayDeployments(tenant: string, location?: string): Observable<GatewayDeployment[]> {
    let params = this.toHttpParams(tenant, location);
    return this.http.get('/api/deployments/', {params}) as Observable<GatewayDeployment[]>;
  }

  public getMergedGatewayDeployment(serial: string): Observable<GatewayDeployment> {
    return this.http.get('/api/deployments/' + serial + '/merged') as Observable<GatewayDeployment>;
  }

  public toHttpParams(tenant: string, location?: string): HttpParams {
    let params = new HttpParams();
    params = params.set('tenant', tenant);

    if (location) {
      params = params.set('location', location);
    }

    return params;

  }
}
