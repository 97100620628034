import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UnitConversionService } from '../../../services/unit-conversion.service';
import { Observable } from 'rxjs';
import { UnitConversion } from '../../../domain/unit-conversion';
import { AddDeviceDialogComponent } from '../../gateway-details/add-device-dialog/add-device-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AddConversionDialogComponent } from './add-conversion-dialog/add-conversion-dialog.component';
import { NotificationService } from '../../../services/notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DeleteDeviceDialogComponent } from '../../gateway-details/delete-device-dialog/delete-device-dialog.component';
import { DeleteConversionDialogComponent } from './delete-conversion-dialog/delete-conversion-dialog.component';

@Component({
  selector: 'eis-gateway-unit-conversion',
  templateUrl: './unit-conversion.component.html',
  styleUrls: ['./unit-conversion.component.scss']
})
export class UnitConversionComponent implements OnInit, AfterViewInit {
  public unitConversions: UnitConversion[];
  public displayedColumns = ['startUnit', 'endUnit', 'factor', 'actions'];
  public dataSource = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private unitConversionService: UnitConversionService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.loadUnitConversions();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  loadUnitConversions() {
    this.unitConversionService.getAllUnitConversions().subscribe(res => {
      this.unitConversions = res;
      this.dataSource.data = res;
    });
  }

  addConversionUnit() {
    this.openAddDialog(null);
  }

  editConversionUnit(conversion: UnitConversion) {
    this.openAddDialog(conversion);
  }

  deleteConversionUnit(conversion: UnitConversion) {
    const dialogRef = this.dialog.open(DeleteConversionDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        conversion: conversion,
      },
      width: '592px',
      height: '564px'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result == "") {
        return;
      }

      this.loadUnitConversions();
      if (result.success) {
        this.notificationService.success('delete-conversion.snackbar.success');
      } else {
        this.notificationService.failure('delete-conversion.snackbar.error');
      }
    });
  }

  private openAddDialog(conversion: UnitConversion | null) {
    const dialogRef = this.dialog.open(AddConversionDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      width: '592px',
      height: '540px',
      autoFocus: false,
      data: {
        conversion: conversion,
      }
    });
    dialogRef.afterClosed().subscribe((response) => {
      this.loadUnitConversions();
      switch (response.status) {
        case 200:
          this.notificationService.success(conversion == null ? 'unit-conversion.snackbar.conversion-add.success' : 'unit-conversion.snackbar.conversion-update.success');
          break;
        case 409:
          this.notificationService.failure('unit-conversion.snackbar.conflict');
          break;
        case "closed":
          break;
        default:
          this.notificationService.failure(conversion == null ? 'unit-conversion.snackbar.conversion-add.error' : 'unit-conversion.snackbar.conversion-update.error');
      }
    });
  }
}
