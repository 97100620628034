<div class="main-container">
  <div class="header-container">
    <div class="row-space-container margin-bottom-24">
      <div class="device-name">Serial Number: {{device?.id}}</div>
      <eon-ui-button *ngIf="!device?.isDeleted" [text]="'mbus-datapoint-configurator.button.scan' | translate"
                     icon="search" scheme="red" rank="secondary"
                     [disabled]="scanningDeviceDataPoints"
                     (click)="scanDataPoints()"></eon-ui-button>
    </div>

    <div class="row-space-container">
      <div class="scan-info" *ngIf="dataPointScanDate">{{'mbus-datapoint-configurator.label.selected-datapoints' | translate: {selectedCount: deviceAddedDataPoints.length, totalCount: dataPointsDataSource.data.length} }}</div>
      <div class="scan-info" *ngIf="dataPointScanDate">{{'mbus-device-table.labels.devices.scan-start-date' | translate:{startDate: dataPointScanDate | date:'dd.MM.yyyy HH:mm'} }}</div>
    </div>
  </div>
  <div class="table-container">
    <table mat-table [dataSource]="dataPointsDataSource" matSort>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="toggleAllRows()"
                        [checked]="isAllSelected()"
                        [disabled]="device?.isDeleted"
                        [indeterminate]="deviceAddedDataPoints.length > 0 && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="toggle(row)"
                        [checked]="isSelected(row)"
                        [disabled]="device?.isDeleted">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="key">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="key">{{'mbus-datapoint-configurator.header.key' | translate}}</th>
        <td mat-cell *matCellDef="let dataPoint">{{dataPoint.key}}</td>
      </ng-container>

      <ng-container matColumnDef="data_type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="data_type">{{'mbus-datapoint-configurator.header.data-type' | translate}}</th>
        <td mat-cell *matCellDef="let dataPoint">{{dataPoint.data_type}}</td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="value">{{'mbus-datapoint-configurator.header.value' | translate}}</th>
        <td mat-cell *matCellDef="let dataPoint">{{dataPoint.value}}</td>
      </ng-container>

      <ng-container matColumnDef="unit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="unit">{{'mbus-datapoint-configurator.header.unit' | translate}}</th>
        <td mat-cell *matCellDef="let dataPoint">{{dataPoint.unit}}</td>
      </ng-container>

      <ng-container matColumnDef="function">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="function">{{'mbus-datapoint-configurator.header.function' | translate}}</th>
        <td mat-cell *matCellDef="let dataPoint">{{dataPoint.function}}</td>
      </ng-container>

      <ng-container matColumnDef="storageNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="storageNumber">{{'mbus-datapoint-configurator.header.storage-number' | translate}}</th>
        <td mat-cell *matCellDef="let dataPoint">{{dataPoint.storage_number}}</td>
      </ng-container>

      <ng-container matColumnDef="tariff">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="tariff">{{'mbus-datapoint-configurator.header.tariff' | translate}}</th>
        <td mat-cell *matCellDef="let dataPoint">{{dataPoint.tariff}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row
          [class.removed]="isDeleted(row)"
          [class.added]="isNew(row)"
          *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <div class="scanning-data-points-warning" *ngIf="device && dataPointsDataSource.data.length == 0 && !scanningDeviceDataPoints">
    {{'mbus-datapoint-configurator.warning.no-datapoint' | translate}}
  </div>

  <div class="scanning-data-points-warning" *ngIf="scanningDeviceDataPoints">
    <eon-ui-preloader-circle size="small"></eon-ui-preloader-circle>
    <span>{{'mbus-datapoint-configurator.warning.scan-take-long' | translate}}</span>
  </div>

</div>

<mat-toolbar>
  <div class="footer-container">
    <eon-ui-button [text]="'general.close' | translate"
                   icon="close" scheme="red" rank="secondary"
                    (click)="closeDrawer()"></eon-ui-button>

    <div *ngIf="!device?.isDeleted" class="interval-container" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
      <mat-form-field appearance="outline" class="eon-style-form-field no-padding" color="accent" *ngIf="device">
        <mat-label>{{'mbus-datapoint-configurator.label.selected-frequency-in-minutes' | translate}}</mat-label>
        <input type="number"
               matInput
               class="eon-style-input"
               [formControl]="pollingFrequencyControl">
      </mat-form-field>

      <eon-ui-button [text]="'mbus-datapoint-configurator.button.apply' | translate"
                     icon="radio_checkbox_tick" scheme="red"
                     (click)="applyChanges()"></eon-ui-button>
    </div>

  </div>
</mat-toolbar>

<ng-template #confirmTemplate>
  <div mat-dialog-title>
    <eon-ui-icon fxLayout fxLayoutAlign="end" [mat-dialog-close]="false" class=closeX name="close"></eon-ui-icon>
    <div class="headline">{{'mbus-datapoint-configurator.dialog.title' | translate}}</div>
  </div>

  <div mat-dialog-content>
    {{'mbus-datapoint-configurator.dialog.content' | translate}}
  </div>

  <div mat-dialog-actions class="dialog-actions">
    <eon-ui-button [text]="'mbus-datapoint-configurator.dialog.buttons.discard' | translate" *ngIf="isDirty()"
                   icon="close" scheme="red" rank="secondary"
                   (click)="rollbackChanges()"></eon-ui-button>

    <eon-ui-button [text]="'mbus-datapoint-configurator.button.apply' | translate"
                   icon="radio_checkbox_tick" scheme="red"
                   (click)="applyChanges()"></eon-ui-button>
  </div>

</ng-template>
