<div mat-dialog-title class="dialog-title">
  <h1 class="headline">{{'mbus-error-device-table.header' | translate }}</h1>

  <eon-ui-icon fxLayout fxLayoutAlign="end" (click)="closeDialog()" class=closeX name="close"></eon-ui-icon>
</div>

<mat-dialog-content fxFlex class="dialog-content">
  <div class="table-container">
    <table mat-table matSort [dataSource]="devices">
      <ng-container matColumnDef="deviceId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="secondaryAddress">{{'mbus-error-device-table.header.serial-number' | translate}}</th>
        <td mat-cell *matCellDef="let device">{{device.id}}</td>
      </ng-container>

      <ng-container matColumnDef="error">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="key">{{'mbus-error-device-table.header.error' | translate}}</th>
        <td mat-cell *matCellDef="let device">{{device.error}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

</mat-dialog-content>

<mat-dialog-actions style="justify-content: space-between;">
  <eon-ui-button scheme="redInverted" icon="close" text="{{'general.cancel' | translate}}" (click)="closeDialog()"></eon-ui-button>
  <eon-ui-button scheme="red" text="Clear Error Messages" (click)="clearErrors()"></eon-ui-button>
</mat-dialog-actions>
