import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'eis-gateway-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {

  loginUrl = '/api/login/start?redirect='
  welcomePage = "/welcome"

  constructor() { }

  ngOnInit(): void {
  }

  start() {
    location.href = this.loginUrl + location.href + this.welcomePage
  }
}
