<eis-gateway-app-shell>
  <div class="page-container">
    <div class="top-content-area">
      <div>
        <eon-ui-button text="Modbus Write Test" class="bottom-button"
                       (click)="sendModbusWrite()"></eon-ui-button>
      </div>
    </div>
  </div>
</eis-gateway-app-shell>
