<div mat-dialog-title class="dialog-title">
  <eon-ui-icon fxLayout fxLayoutAlign="end" [mat-dialog-close]="{status: 'closed'}" class=closeX
               name="close"></eon-ui-icon>
  <div
    class="headline">{{'add-mbus-device-dialog.button.add' | translate}}</div>
</div>

<div mat-dialog-content fxFlex class="dialog-content">
  <div fxLayout fxLayoutGap="12px">
    <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
      <mat-label>Serial Number</mat-label>
      <input [formControl]="nameInput"
             matInput
             class="eon-style-input"
             type="number"
      >
      <mat-error
        *ngIf="nameInput.invalid">{{'add-mbus-device-dialog.input.device-serial.error' | translate}}</mat-error>
      <mat-error *ngIf="nameInput.hasError('mbusDeviceNotAlreadyInUse')">{{'add-mbus-device-dialog.input.device-serial.conflict-error' | translate}}</mat-error>
      <mat-hint align="start">{{'add-mbus-device-dialog.input.device.hint' | translate}}</mat-hint>
    </mat-form-field>

    <eon-ui-button (click)="scanDataPoints()" class="scan-button" [disabled]="scanningDeviceDataPoints || nameInput.invalid || nameInput.hasError('mbusDeviceNotAlreadyInUse')"
                   text="{{'add-mbus-device-dialog.find-meter' | translate}}"></eon-ui-button>
  </div>

  <div class="scan-hint-warning" *ngIf="dataPoints.length == 0 && !scanningDeviceDataPoints && !scanDataPointError">
    {{'add-mbus-device-dialog.scan.hint' | translate}}
  </div>

  <div class="scanning-data-points-warning" *ngIf="scanningDeviceDataPoints">
    <eon-ui-preloader-circle size="small"></eon-ui-preloader-circle>
    <span>{{'add-mbus-device-dialog.finding-meter' | translate}}</span>
  </div>

  <div class="scan-error" *ngIf="scanDataPointError">
    {{'add-mbus-device-dialog.scan.error' | translate}}
  </div>

  <div class="scan-hint-warning" *ngIf="dataPoints.length > 0">
    {{'add-mbus-device-dialog.scan.success' | translate:{serialNumber: nameInput.value} }}
  </div>
</div>

<div mat-dialog-actions style="justify-content: space-between;">
  <eon-ui-button scheme="redInverted" icon="close" text="{{'general.cancel' | translate}}"
                 [mat-dialog-close]="{status: 'closed'}"></eon-ui-button>
  <eon-ui-button (click)="validateAndUpdate()" [disabled]="dataPoints.length == 0"
                 text="{{'add-mbus-device-dialog.button.add' | translate}}"></eon-ui-button>
</div>
