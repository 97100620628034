import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'eis-gateway-step-indicator',
  templateUrl: './step-indicator.component.html',
  styleUrls: ['./step-indicator.component.scss']
})
export class StepIndicatorComponent implements OnInit {

  @Input()
  step: number = 1

  @Input()
  details: string = ""


  constructor() { }

  ngOnInit(): void {
  }

}
