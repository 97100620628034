import {AfterContentInit, Component, ContentChildren, EventEmitter, OnInit, Output, QueryList} from '@angular/core';
import {TenantCardComponent} from "../tenant-card/tenant-card.component";

@Component({
  selector: 'eis-gateway-tenant-radio-control',
  templateUrl: './tenant-radio-control.component.html',
  styleUrls: ['./tenant-radio-control.component.scss']
})
export class TenantRadioControlComponent implements AfterContentInit {

  @Output()
  cardSelectionChanged: EventEmitter<TenantCardComponent> = new EventEmitter<TenantCardComponent>()

  constructor() { }

  @ContentChildren(TenantCardComponent)
  tenants!: QueryList<TenantCardComponent>

  ngAfterContentInit(): void {
    this.tenants.forEach(card => card.click.subscribe(card => this.handleCardClick(card)));
  }

  private handleCardClick(card: TenantCardComponent) {
    this.tenants.filter(tenant => tenant.title != card.title).forEach(tenant => tenant.selected = false)
    card.selected = true;
    this.cardSelectionChanged.emit(card)
  }
}
