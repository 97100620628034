import { Component, Inject, OnInit } from '@angular/core';
import { GridxDeviceService } from '../../../../services/gridx-device.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'eis-gateway-mbus-stop-scan-dialog',
  templateUrl: './mbus-stop-scan-dialog.component.html',
  styleUrls: ['./mbus-stop-scan-dialog.component.scss']
})
export class MbusStopScanDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { gatewaySerial: string, serialPort: string},
    public dialogRef: MatDialogRef<MbusStopScanDialogComponent>,
    private gridxService: GridxDeviceService
  ) { }

  ngOnInit(): void {
  }

  stopScan() {
    this.gridxService.stopMBusScan(this.data.gatewaySerial, this.data.serialPort).subscribe(result => {
      this.dialogRef.close(result);
    })
  }
}
