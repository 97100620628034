<div mat-dialog-title class="dialog-title">
  <eon-ui-icon fxLayout fxLayoutAlign="end" [mat-dialog-close]="{status: 'closed'}" class=closeX
               name="close"></eon-ui-icon>
  <div
    class="headline">{{(conversion ? 'add-conversion-dialog.button.update' : 'add-conversion-dialog.button.add') | translate}}</div>
</div>

<div mat-dialog-content fxFlex class="dialog-content">
  <div fxLayout="column" fxLayoutGap="12px" [formGroup]="formGroup">
    <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
      <mat-label>Start unit</mat-label>
      <input [formControl]="startUnit"
             matInput
             class="eon-style-input"
      >
      <mat-error
        *ngIf="startUnit.invalid">{{'add-conversion-dialog.input.start-unit.error' | translate}}</mat-error>
      <mat-hint align="start">{{'add-conversion-dialog.input.start-unit.hint' | translate}}</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
      <mat-label>End unit</mat-label>
      <input [formControl]="endUnit"
             matInput
             class="eon-style-input"
      >
      <mat-error
        *ngIf="endUnit.invalid">{{'add-conversion-dialog.input.end-unit.error' | translate}}</mat-error>
      <mat-hint align="start">{{'add-conversion-dialog.input.end-unit.hint' | translate}}</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
      <mat-label>Factor</mat-label>
      <input [formControl]="factor"
             matInput
             class="eon-style-input"
             type="number"
      >
      <mat-error
        *ngIf="factor.invalid">{{'add-conversion-dialog.input.factor.error' | translate}}</mat-error>
      <mat-hint align="start">{{'add-conversion-dialog.input.factor.hint' | translate}}</mat-hint>
    </mat-form-field>
  </div>
</div>

<div mat-dialog-actions style="justify-content: space-between;">
  <eon-ui-button scheme="redInverted" icon="close" text="{{'general.cancel' | translate}}"
                 [mat-dialog-close]="{status: 'closed'}"></eon-ui-button>
  <eon-ui-button (click)="update()" icon="{{conversion ? 'save' : 'add'}}" text="{{(conversion ? 'add-conversion-dialog.button.update' : 'add-conversion-dialog.button.add') | translate}}"></eon-ui-button>
</div>
