export interface AuthenticationStatus {
  connectionEstablished: boolean;
}

export class ConnectionStatus {
  gridx: AuthenticationStatus;
  eiot: AuthenticationStatus;

  constructor(gridx: AuthenticationStatus, eiot: AuthenticationStatus) {
    this.gridx = gridx;
    this.eiot= eiot;
  }

  get fullyConnected(): boolean {
    return this.gridx.connectionEstablished && this.eiot.connectionEstablished;
  }
}
