<div mat-dialog-title>
  <eon-ui-icon fxLayout fxLayoutAlign="end" [mat-dialog-close]="false" class=closeX name="close"></eon-ui-icon>
  <div class="headline">{{'change-location.headline' | translate}}</div>
</div>

<ng-container>
  <mat-dialog-content fxFlex>
    <div>
      <div fxLayout="column">
        <div class="stepheadline" fxLayoutAlign="center center">{{'change-location.subheadline' | translate}}</div>
        <div class="bottom-container" fxLayoutAlign="center center" fxLayout="column">
          <div>{{'general.location-name' | translate}}</div>
          <p class="stepheadline">{{this.location}}</p>
        </div>

        <div>
          <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
            <input [formControl]="locationInput"
                   matInput
                   placeholder="{{'general.location-name' | translate}}"
                   class="eon-style-input"
                   type="text"
            >
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="justify-content: space-between;">
    <eon-ui-button scheme="redInverted" icon="close" text="{{'general.cancel' | translate}}" mat-dialog-close></eon-ui-button>
    <eon-ui-button (click)="changeGateway()" [disabled]="!locationInput.valid"
                   text="{{'general.button.complete-process' | translate}}"></eon-ui-button>
  </mat-dialog-actions>

</ng-container>
