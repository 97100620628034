import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'eis-gateway-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  @Input()
  fileType: string;

  @Input()
  buttonText: string = "Upload DataPoints";

  @Output()
  change = new EventEmitter()

  filename: string | null;

  constructor() { }

  ngOnInit(): void {
  }

  private trimFileName(fileName: string) {
    let extension = fileName.substring(fileName.length -4, fileName.length)
    return fileName.substring(0, 30) + "[...]" +extension
  }

  fileChange($event: Event) {
    this.change.emit($event)
    if (($event.target as HTMLInputElement).files!.length != 0) {
      let fileName = ($event.target as HTMLInputElement).files![0].name;
      this.filename = fileName.length > 35 ? this.trimFileName(fileName): fileName
    } else {
      this.filename = null;
    }
  }
}
