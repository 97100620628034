<div *ngIf="logLevelConfig$ | async as config">
  <form [formGroup]="logLevelConfigForm">
    <div class="config-container" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="80" fxLayout="row" fxLayoutGap="10px">
          <h3 class="config-container-headline">{{'gateway-log-level-config.headline' | translate}}</h3>
        </div>
      </div>
      <mat-divider class="divider"></mat-divider>
      <div fxLayout="row" fxLayoutGap="10" class="bottom-controls">
        <div>
          <mat-form-field class="input-field">
            <mat-label>{{'gateway-log-level-config.input-headline' | translate}}</mat-label>
            <mat-select formControlName="logLevelSelection" [compareWith]="compareFn">
              <mat-option *ngFor="let logLevel of availableLogLevel" [value]="logLevel">
                {{logLevel}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
  <div class="form-controls" fxLayoutAlign="end center">
    <eon-ui-button text="{{'general.apply' | translate}}" (click)="saveSettings()"></eon-ui-button>
  </div>
</div>
