import {Injectable} from "@angular/core";
import {AbstractControl, AsyncValidator, ValidationErrors} from "@angular/forms";
import {GridxDeviceService} from "../../services/gridx-device.service";
import {catchError, map, Observable, of} from "rxjs";

@Injectable({ providedIn: 'root' })
export class GridxDeviceReadyValidator implements AsyncValidator {
  constructor(private gridXservice: GridxDeviceService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.gridXservice.addGateway(control.value).pipe(
      map(device => null),
      catchError(() => of({ gridxDeviceReady: true }))
    );
  }
}
