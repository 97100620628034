import {Directive, ElementRef, forwardRef, HostListener} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Directive({
  selector: 'eon-ui-checkbox',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EonUiCheckboxValueAccessorDirective),
      multi: true
    }
  ]
})
export class EonUiCheckboxValueAccessorDirective implements ControlValueAccessor {

  private _value: boolean = false;

  constructor(private elementRef: ElementRef) {
  }

  onChange: any = () => {
  };
  onTouched: any = () => {
  };

  @HostListener('valueChanged', ['$event.detail'])
  listenForValueChange(value: boolean) {
    this.writeValue(value);
  }

  writeValue(value: boolean) {
    if(this._value != value){
      this._value = value;
      this.onChange(this._value);
      this.onTouched();
      this.elementRef.nativeElement.checked = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.elementRef.nativeElement.disabled = isDisabled
  }

}
