<div class="table-container">
  <table mat-table matSort [dataSource]="configs">
    <ng-container matColumnDef="secondaryAddress">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="secondaryAddress">{{'mbus-device-table.header.serial-number' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.secondaryAddress}}</td>
    </ng-container>

    <ng-container matColumnDef="key">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="key">{{'mbus-config-table.header.key' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.key}}</td>
    </ng-container>

    <ng-container matColumnDef="serialPort">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="serialPort">{{'mbus-config-table.header.port' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.serialPort}}</td>
    </ng-container>

    <ng-container matColumnDef="baudrate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="baudrate">{{'mbus-config-table.header.baud-rate' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.baudrate}}</td>
    </ng-container>

    <ng-container matColumnDef="pollingFrequency">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="value">{{'mbus-config-table.header.polling-frequency' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.pollingFrequency}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
