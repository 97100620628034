<div class="config-container" fxLayout="column" fxLayoutAlign="center end" fxLayoutGap="12px">
  <eon-ui-button icon="add"
                 text="{{'unit-conversion-table.action.add-conversion' | translate}}"
                 (click)="addConversionUnit()"></eon-ui-button>

  <div class="table-container">
    <table mat-table matSort [dataSource]="dataSource">
      <ng-container matColumnDef="startUnit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'unit-conversion-table.header.start-unit' | translate}}</th>
        <td mat-cell *matCellDef="let conversion">{{conversion.startUnit}}</td>
      </ng-container>

      <ng-container matColumnDef="endUnit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'unit-conversion-table.header.end-unit' | translate}}</th>
        <td mat-cell *matCellDef="let conversion">{{conversion.endUnit}}</td>
      </ng-container>

      <ng-container matColumnDef="factor">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'unit-conversion-table.header.factor' | translate}}</th>
        <td mat-cell *matCellDef="let conversion">{{conversion.factor}}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{'unit-conversion-table.header.actions' | translate}}</th>
        <td mat-cell *matCellDef="let conversion" fxLayoutGap="24px">
          <eon-ui-button icon="edit" size="small" (click)="editConversionUnit(conversion)"></eon-ui-button>

          <eon-ui-button icon="trash_bin" size="small" (click)="deleteConversionUnit(conversion)"></eon-ui-button>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
