import { Component, Inject, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../../../../services/notification.service';
import { MBusDataPoint, MBusDevice } from '../../../../domain/device.interface';
import { GridxDeviceService } from '../../../../services/gridx-device.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'eis-gateway-mbus-preview-datapoints',
  templateUrl: './mbus-preview-datapoints.component.html',
  styleUrls: ['./mbus-preview-datapoints.component.scss']
})
export class MbusPreviewDatapointsComponent implements OnInit {
  public addedDataPoints = new MatTableDataSource<MBusDataPoint>([]);
  public updatedDataPoints = new MatTableDataSource<MBusDataPoint>([]);
  public removedDataPoints = new MatTableDataSource<MBusDataPoint>([]);
  public removedDevices = new MatTableDataSource<MBusDevice>([]);
  public displayedColumns: string[] = ['id', 'manufacturer', 'type', 'portName']
  public deleteDataPointsInIoT = false;
  public activeTabIndex = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { addedDataPoints: MBusDataPoint[], updatedDataPoints: MBusDataPoint[], removedDataPoints: MBusDataPoint[], removedDevices: MBusDevice[], serial: string},
    public dialogRef: MatDialogRef<MbusPreviewDatapointsComponent>,
    private notificationService: NotificationService,
    private gridXService: GridxDeviceService,
  ) { }

  ngOnInit(): void {
    this.data.addedDataPoints = this.sortAfterSerialNumberAndKey(this.data.addedDataPoints);
    this.data.updatedDataPoints = this.sortAfterSerialNumberAndKey(this.data.updatedDataPoints);
    this.data.removedDataPoints = this.sortAfterSerialNumberAndKey(this.data.removedDataPoints);

    this.addedDataPoints = new MatTableDataSource<MBusDataPoint>(this.data.addedDataPoints);
    this.updatedDataPoints = new MatTableDataSource<MBusDataPoint>(this.data.updatedDataPoints);
    this.removedDataPoints = new MatTableDataSource<MBusDataPoint>(this.data.removedDataPoints);
    this.removedDevices = new MatTableDataSource<MBusDevice>(this.data.removedDevices);
  }

  applyChanges() {
    const removedDeviceIds = this.removedDevices.data.map(d => d._id)
    this.gridXService.applyMBusConfig(this.data.serial, removedDeviceIds, this.deleteDataPointsInIoT).subscribe(
      {
        next: (response) => {
          this.dialogRef.close(true);
          this.notificationService.success("mbus-preview-changes.apply.success");
        },
        error: (err) => {
          let errorMessage = "mbus-preview-changes.apply.error";
          if(typeof  err.error == "string") {
            errorMessage = err.error;
          }
          this.notificationService.failure(errorMessage);
        }
      }
    );

  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  private sortAfterSerialNumberAndKey(dataPoints: MBusDataPoint[]): MBusDataPoint[] {
    return dataPoints.sort((a, b) => a.secondaryAddress.localeCompare(b.secondaryAddress) == 0
      ? parseInt(a.key) > parseInt(b.key) ? 1 : -1
      : a.secondaryAddress.localeCompare(b.secondaryAddress)
    );
  }

  selectedTabChange(changeEvent: MatTabChangeEvent) {
    this.activeTabIndex = changeEvent.index;
  }
}
