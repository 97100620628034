<!-- Hint Container -->
<div>
  <eon-ui-error-message icon-name="alert" scheme="turquoiseDark" is-visible="true" show-closing-x="true" class="hint-container" fxLayout="column">
    <eon-ui-headline slot="headline" size="h5" text="{{'gateway-network-config.hint-headline' | translate}}"></eon-ui-headline>
    <div class="hint-container-text">{{'gateway-network-config.hint-text' | translate}}</div>

    <eon-ui-link (click)="openRecoveryDialog()" icon="info" text="{{'gateway-network-config.recovery-instructions' | translate}}"></eon-ui-link>
  </eon-ui-error-message>
</div>
<div fxLayout="column" fxLayoutGap="10px" *ngIf="networkConfig$ | async as configs">
  <form [formGroup]="networkConfigForm">
    <!-- Eth0 Form -->
    <div class="config-container" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="80" fxLayout="row" fxLayoutGap="10px">
          <h3 class="config-container-headline">Ethernet 0/LAN1</h3>
          <ng-container *ngIf="networkStatus$ | async as status">
            <div [ngClass]="getChipClass(status.networkAdapters[0].status)"
                 fxLayoutAlign="center center">{{configs.networkAdapters[0].status}}
            </div>
            <div *ngIf="status.networkAdapters[0].dsUsed" fxLayoutAlign="center center" class="active-chip">
              {{'gateway-network-config.ds-main-connection' | translate}}
            </div>
            <div *ngIf="status.networkAdapters[0].macAddress" fxLayoutAlign="center center" class="active-chip">
              {{'gateway-network-config.mac-address' | translate}} {{status.networkAdapters[0].macAddress}}
            </div>
          </ng-container>
        </div>
        <div fxFlex="20" fxLayoutAlign="end center">
          <eon-ui-checkbox class="default-checkbox" (valueChanged)="checkboxChanged('eth0')" formControlName="eth0defaultCheckbox" [(ngModel)]="eth0default" label="{{'gateway-network-config.default' | translate}}"></eon-ui-checkbox>
          <eon-ui-button  *ngIf="eth0readonly" icon="edit" scheme="turquoise" size="small" rank="secondary"
                          (click)="enableEditing('eth0')"></eon-ui-button>
          <eon-ui-button  *ngIf="!eth0readonly" icon="radio_checkbox_tick" scheme="turquoise" size="small" rank="secondary"
                          (click)="disableEditing('eth0')"></eon-ui-button>
        </div>
      </div>
      <mat-divider class="divider"></mat-divider>
      <div class="radio-button-container">
        <div class="radio-button-headline">{{'gateway-network-config.connection-mode' | translate}}</div>
        <mat-radio-group aria-label="Select an option" formControlName="eth0dhcpSelection">
          <mat-radio-button [value]="true" class="radio-button">DHCP</mat-radio-button>
          <mat-radio-button [value]="false" class="radio-button">Static</mat-radio-button>
        </mat-radio-group>
      </div>
      <div fxLayout="row" fxLayoutGap="10" *ngIf="!this.networkConfigForm.controls['eth0dhcpSelection'].value">
        <div>
          <mat-form-field class="input-field">
            <mat-label>{{'gateway-network-config.ip-address' | translate}}</mat-label>
            <input matInput formControlName="eth0addressInput" placeholder="XXX.XXX.X.X">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="input-field" >
            <mat-label>{{'gateway-network-config.subnet' | translate}}</mat-label>
            <input matInput formControlName="eth0subnetInput">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10" *ngIf="this.networkConfigForm.controls['eth0dhcpSelection'].value">
        <div>
          <mat-form-field class="input-field">
            <mat-label>{{'gateway-network-config.ip-address' | translate}}</mat-label>
            <input matInput formControlName="eth0DhcpAddress">
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- Eth1 Form -->
    <div class="config-container" fxLayout="column">
      <div fxLayout="row">
        <div fxFlex="80" fxLayout="row" fxLayoutGap="10px">
          <h3 class="config-container-headline">Ethernet 1/LAN2</h3>
          <ng-container *ngIf="networkStatus$ | async as status">
            <div [ngClass]="getChipClass(status.networkAdapters[1].status)"
                 fxLayoutAlign="center center">{{configs.networkAdapters[1].status}}
            </div>
            <div *ngIf="status.networkAdapters[1].dsUsed" fxLayoutAlign="center center" class="active-chip">
              {{'gateway-network-config.ds-main-connection' | translate}}
            </div>
            <div *ngIf="status.networkAdapters[1].macAddress" fxLayoutAlign="center center" class="active-chip">
              {{'gateway-network-config.mac-address' | translate}} {{status.networkAdapters[1].macAddress}}
            </div>
          </ng-container>
        </div>
        <div fxFlex="20" fxLayoutAlign="end center">
          <eon-ui-button  *ngIf="eth1readonly" icon="edit" scheme="turquoise" size="small" rank="secondary"
                          (click)="enableEditing('eth1')"></eon-ui-button>
          <eon-ui-button  *ngIf="!eth1readonly" icon="radio_checkbox_tick" scheme="turquoise" size="small" rank="secondary"
                          (click)="disableEditing('eth1')"></eon-ui-button>
        </div>
      </div>
      <mat-divider class="divider"></mat-divider>
      <div class="radio-button-container">
        <div class="radio-button-headline">{{'gateway-network-config.connection-mode' | translate}}</div>
        <mat-radio-group aria-label="Select an option" formControlName="eth1dhcpSelection">
          <mat-radio-button [value]="true" disabled class="radio-button">DHCP</mat-radio-button>
          <mat-radio-button [value]="false" class="radio-button">Static</mat-radio-button>
        </mat-radio-group>
      </div>
      <div fxLayout="row" fxLayoutGap="10" *ngIf="!this.networkConfigForm.controls['eth1dhcpSelection'].value">
        <div>
          <mat-form-field class="input-field">
            <mat-label>{{'gateway-network-config.ip-address' | translate}}</mat-label>
            <input matInput formControlName="eth1addressInput" placeholder="XXX.XXX.X.X">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="input-field">
            <mat-label>{{'gateway-network-config.subnet' | translate}}</mat-label>
            <input matInput formControlName="eth1subnetInput">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10" *ngIf="this.networkConfigForm.controls['eth1dhcpSelection'].value">
        <div>
          <mat-form-field class="input-field">
            <mat-label>{{'gateway-network-config.ip-address' | translate}}</mat-label>
            <input matInput formControlName="eth1DhcpAddress">
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
  <form [formGroup]="mobileSettingsForm">
    <!-- gsm0 Form -->
    <div class="gsm-config-container" fxLayout="column">
      <div fxLayout="row">
        <div fxFlex="80" fxLayout="row" fxLayoutGap="10px">
          <h3 class="config-container-headline">Mobile 0/LTE1</h3>
          <ng-container *ngIf="networkStatus$ | async as status">
            <div [ngClass]="getChipClass(status.networkAdapters[2].status)"
                 fxLayoutAlign="center center">{{status.networkAdapters[2].status}}
            </div>
            <div *ngIf="status.networkAdapters[2].dsUsed" fxLayoutAlign="center center" class="active-chip">
              {{'gateway-network-config.ds-main-connection' | translate}}
            </div>
            <div *ngIf="status.networkAdapters[2].macAddress" fxLayoutAlign="center center" class="active-chip">
              {{'gateway-network-config.mac-address' | translate}} {{status.networkAdapters[2].macAddress}}
            </div>
          </ng-container>
        </div>
        <div fxFlex="20" fxLayoutAlign="end center">
          <eon-ui-checkbox class="default-checkbox" (valueChanged)="checkboxChanged('gsm0')" formControlName="gsm0defaultCheckbox" [(ngModel)]="gsm0default" label="{{'gateway-network-config.default' | translate}}"></eon-ui-checkbox>
          <eon-ui-button  *ngIf="gsm0readonly" icon="edit" scheme="turquoise" size="small" rank="secondary"
                          (click)="enableEditing('gsm0')"></eon-ui-button>
          <eon-ui-button  *ngIf="!gsm0readonly" icon="radio_checkbox_tick" scheme="turquoise" size="small" rank="secondary"
                          (click)="disableEditing('gsm0')"></eon-ui-button>
        </div>
      </div>
      <mat-divider class="divider"></mat-divider>
      <div class="radio-button-container">
        <div class="radio-button-headline">{{'gateway-network-config.connection-mode' | translate}}</div>
        <mat-radio-group aria-label="Select an option" formControlName="gsm0dhcpSelection">
          <mat-radio-button [value]="true" class="radio-button">DHCP</mat-radio-button>
          <mat-radio-button [value]="false" disabled class="radio-button">Static</mat-radio-button>
        </mat-radio-group>
      </div>
      <div fxLayout="row" fxLayoutGap="10" *ngIf="!this.mobileSettingsForm.controls['gsm0dhcpSelection'].value">
        <div>
          <mat-form-field class="input-field">
            <mat-label>{{'gateway-network-config.ip-address' | translate}}</mat-label>
            <input matInput formControlName="gsm0addressInput">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="input-field">
            <mat-label>{{'gateway-network-config.subnet' | translate}}</mat-label>
            <input matInput formControlName="gsm0subnetInput">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10" *ngIf="this.mobileSettingsForm.controls['gsm0dhcpSelection'].value">
        <div>
          <mat-form-field class="input-field">
            <mat-label>{{'gateway-network-config.ip-address' | translate}}</mat-label>
            <input matInput formControlName="gsm0DhcpAddress">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10">
        <div>
          <mat-form-field class="input-field">
            <mat-label>{{'gateway-network-config.apn' | translate}}</mat-label>
            <input matInput formControlName="gsm0apnInput">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="input-field">
            <mat-label>{{'gateway-network-config.pin' | translate}}</mat-label>
            <input matInput formControlName="gsm0pinInput">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="input-field">
            <mat-label>{{'gateway-network-config.username' | translate}}</mat-label>
            <input matInput formControlName="gsm0usernameInput">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="input-field">
            <mat-label>{{'gateway-network-config.password' | translate}}</mat-label>
            <input matInput formControlName="gsm0passwordInput">
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
  <div class="form-controls" fxLayoutAlign="end center">
    <eon-ui-button text="{{'general.apply' | translate}}" (click)="openConfirmationDialog()"></eon-ui-button>
  </div>
</div>
