import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private _loaderEnabled: boolean;

  constructor() {
  }

  get loaderEnabled() {
    return this._loaderEnabled;
  }

  public showLoader() {
    this._loaderEnabled = true;
  }

  public hideLoader() {
    this._loaderEnabled = false;
  }
}
