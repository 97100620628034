<div class="table-container">
  <table mat-table matSort [dataSource]="datapoints">

    <ng-container matColumnDef="deviceName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'datapoint-table.header.deviceName' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.deviceName}}</td>
    </ng-container>

    <ng-container matColumnDef="integrate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'datapoint-table.header.integrate' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.integrate}}</td>
    </ng-container>

    <ng-container matColumnDef="online">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'gateway-table.header.online-status' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">
        <mat-icon *ngIf="datapoint.icConnected" class="wifi-on">wifi</mat-icon>
        <mat-icon *ngIf="!datapoint.icConnected" class="wifi-off">wifi_off</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="key">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'datapoint-table.header.key' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.key}}</td>
    </ng-container>

    <ng-container matColumnDef="lastValue">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'datapoint-table.header.lastValue' | translate}}</th>
      <td mat-cell
          *matCellDef="let datapoint">{{datapoint.lastValue !== 'null' ? (datapoint.lastValue | number : '1.0-2') : 'n/a'}}</td>
    </ng-container>

    <ng-container matColumnDef="unit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'datapoint-table.header.unit' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.unit}}</td>
    </ng-container>

    <ng-container matColumnDef="scaleFactor">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'datapoint-table.header.scaleFactor' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.scaleFactor}}</td>
    </ng-container>

    <ng-container matColumnDef="pollingInterval">
      <th mat-header-cell *matHeaderCellDef
          mat-sort-header>{{'datapoint-table.header.pollingInterval' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.pollingInterval}}</td>
    </ng-container>

    <ng-container matColumnDef="labels">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'datapoint-table.header.labels' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint" (click)="copyLabelsToClipboard(datapoint.labels)" matTooltip="Click to copy labels to clipboard" matTooltipClass="label-tooltip">
        <eis-gateway-label-chips [labels]="datapoint.labels"></eis-gateway-label-chips>
      </td>
    </ng-container>

    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'datapoint-table.header.address' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.address}}</td>
    </ng-container>

    <ng-container matColumnDef="functionCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'datapoint-table.header.functionCode' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.functionCode}}</td>
    </ng-container>

    <ng-container matColumnDef="registers">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'datapoint-table.header.registers' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.registers}}</td>
    </ng-container>

    <ng-container matColumnDef="format">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'datapoint-table.header.format' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.format}}</td>
    </ng-container>

    <ng-container matColumnDef="byteOrder">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'datapoint-table.header.byteOrder' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.byteOrder}}</td>
    </ng-container>

    <ng-container matColumnDef="lastUpdated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'datapoint-table.header.lastUpdated' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.lastUpdated | date:'dd.MM.yyyy HH:mm'}}</td>
    </ng-container>

    <ng-container matColumnDef="contextActions">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
      <td mat-cell *matCellDef="let datapoint" (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu"
          [matMenuTriggerData]="{datapoint: datapoint}">
        <mat-icon class="clickable">more_vert</mat-icon>
      </td>

    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-datapoint=datapoint>
    <a mat-menu-item target="_blank"href="https://app.iot.eon.com/tenants/{{tenant}}/datapoints/{{datapoint.datapointId}}">
      <mat-icon svgIcon="link-external"></mat-icon>
      {{'datapoint-table.popupmenu.eiot' | translate}}
    </a>

  </ng-template>
</mat-menu>
