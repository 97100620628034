import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'eis-gateway-connection-card',
  templateUrl: './connection-card.component.html',
  styleUrls: ['./connection-card.component.scss']
})
export class ConnectionCardComponent implements OnInit {
  @Input()
  connection: string = "";
  @Input()
  connected: boolean | undefined = false;

  @Output()
  cardClick: EventEmitter<any> = new EventEmitter<any>()

  constructor() { }

  ngOnInit(): void {
  }

  onCardClick() {
    this.cardClick.emit()
  }
}
