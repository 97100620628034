import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  GridxDevice,
  GridxDeviceLogLevels,
  GridxDeviceNetworkAdapters,
  GridxLogLevelDeviceConfig,
  GridxNetworkDeviceConfig,
  GridxNetworkDeviceConfigReset
} from "../domain/gridx-device.interface";
import {GridxOSVersion} from '../domain/gridx-osversion.interface';
import {MBusScanDevicesPayload} from '../domain/mbus-scan-devices-payload';
import {
  MBusConfig,
  MBusConfigPayload,
  MBusDataPoint,
  MbusDataPointScanResults,
  MBusDevice,
  MBusDeviceScan,
  MBusDeviceScanResult
} from '../domain/device.interface';
import {MBusScanDataPointsPayload} from '../domain/mbus-scan-datapoints-payload';
import {triggerLoader} from '../components/decorators/loaderDecorator';
import {checkFullyConnected} from '../components/decorators/checkConnectedDecorator';
import {DeploymentImage} from "../domain/deployment-image.interface";

@Injectable({
  providedIn: 'root'
})
export class GridxDeviceService {

  private readonly SERVICE_URL = `${environment.backendUrl}/gridx`;

  constructor(private http: HttpClient) {
  }

  public addGateway(serial: string): Observable<GridxDevice> {
    return this.http.post<GridxDevice>(this.SERVICE_URL + '/gateway', serial);
  }

  public updateGatewayDeployment(serial: string, isDevUpdate: boolean = false): Observable<GridxDevice> {
    return this.http.post<GridxDevice>(this.SERVICE_URL + '/gateway/'+serial+'/deploy/update', {isDevUpdate});
  }

  public updateGateWayOS(serial: string, version: string) {
    return this.http.post<GridxDevice>(this.SERVICE_URL + '/gateway/'+serial+'/os/update/' + version, {});
  }

  public getDeviceNetworkConfig(serial: string): Observable<GridxDeviceNetworkAdapters> {
    return this.http.get<GridxDeviceNetworkAdapters>(this.SERVICE_URL + '/device/' + serial + '/network');
  }

  public getLogLevelConfig(serial: string): Observable<GridxDeviceLogLevels> {
    return this.http.get<GridxDeviceLogLevels>(this.SERVICE_URL + '/device/' + serial + '/loglevel');
  }

  public postNetworkConfig(output: GridxNetworkDeviceConfig | GridxNetworkDeviceConfigReset, serial: string): Observable<GridxNetworkDeviceConfig> {
    return this.http.post<GridxNetworkDeviceConfig>(this.SERVICE_URL + '/device/' + serial + '/network', output);
  }

  public postMetaDataConfig(output: GridxLogLevelDeviceConfig, serial: string): Observable<GridxLogLevelDeviceConfig> {
    return this.http.post<GridxLogLevelDeviceConfig>(this.SERVICE_URL + '/device/' + serial + '/loglevel', output);
  }

  public getDeviceOS(deviceId: string): Observable<GridxOSVersion> {
    return this.http.get<GridxOSVersion>(this.SERVICE_URL + '/device/' + deviceId + '/os');
  }

  public scanMBusDevices(serial: string, payload: MBusScanDevicesPayload): Observable<boolean> {
    return this.http.post<boolean>(this.SERVICE_URL + "/gateway/" + serial + "/mbus/auto-discovery", payload);
  }

  public stopMBusScan(serial: string, serialPort: string): Observable<boolean> {
    return this.http.delete<boolean>(this.SERVICE_URL + "/gateway/" + serial + "/mbus/auto-discovery?serialPort=" + serialPort);
  }

  public getMBusDevices(serial: string, payload: MBusScanDevicesPayload): Observable<MBusDeviceScanResult> {
    return this.http.post<MBusDeviceScanResult>(this.SERVICE_URL + "/gateway/" + serial + "/port/mbus/devices", payload);
  }

  public getLastMBusDeviceScan(serial: string): Observable<MBusDeviceScan> {
    return this.http.get<MBusDeviceScan>(this.SERVICE_URL + "/gateway/" + serial + "/port/mbus/scan/device/last");
  }

  public scanDataPoints(serial: string, payload: MBusScanDataPointsPayload): Observable<boolean> {
    return this.http.post<boolean>(this.SERVICE_URL + "/gateway/" + serial + "/port/mbus/datapoint-discovery", payload);
  }

  public getMBusDeviceDataPoints(serial: string, secondaryAddress: string): Observable<MBusDataPoint[] | null> {
    return this.http.get<MBusDataPoint[]>(this.SERVICE_URL + "/gateway/" + serial + "/port/mbus/device/" + secondaryAddress + "/datapoints");
  }

  public getMBusDevicesDataPoints(serial: string, secondaryAddresses: string[]): Observable<MBusDataPoint[] | null> {
    const addresses = secondaryAddresses.join(",");
    return this.http.get<MBusDataPoint[]>(this.SERVICE_URL + "/gateway/" + serial + "/port/mbus/device/multiple/datapoints?secondaryAddresses=" + addresses);
  }

  public getMBusConfigDataPoints(serial: string): Observable<MBusDataPoint[] | null> {
    return this.http.get<MBusDataPoint[]>(this.SERVICE_URL + "/gateway/" + serial + "/port/mbus/config/datapoints");
  }

  public saveMBusConfig(serial: string, payload: MBusConfigPayload): Observable<boolean> {
    return this.http.put<boolean>(this.SERVICE_URL + "/gateway/" + serial + "/port/mbus/config", payload);
  }

  @triggerLoader
  @checkFullyConnected
  public pingAddress(serial: string, address: string): Observable<string> {
    return this.http.post<string>(this.SERVICE_URL + "/gateway/" + serial + "/ping?address=" + address, {});
  }

  @triggerLoader
  @checkFullyConnected
  public checkTcpPort(serial: string, address: string, port: string): Observable<string> {
    return this.http.post<string>(this.SERVICE_URL + "/gateway/" + serial + "/telnet?address=" + address + "&port=" + port, {});
  }

  @triggerLoader
  @checkFullyConnected
  public getGatewayLogs(serial: string): Observable<string> {
    return this.http.post<string>(this.SERVICE_URL + "/gateway/" + serial + "/logs", {});
  }

  @triggerLoader
  @checkFullyConnected
  public applyMBusConfig(serial: string, removedDeviceIds: string[], deleteDataPointsInIoT: boolean): Observable<boolean> {
    return this.http.put<boolean>(this.SERVICE_URL + "/gateway/" + serial + "/port/mbus/config/apply/" + deleteDataPointsInIoT, removedDeviceIds);
  }

  public getMBusConfig(serial: string): Observable<MBusConfig[]> {
    return this.http.get<MBusConfig[]>(this.SERVICE_URL + "/gateway/" + serial + "/port/mbus/config");
  }

  @triggerLoader
  @checkFullyConnected
  deleteMBusDevice(gatewaySerial: String, serialPort: string, device: MBusDevice, deleteInEoit: boolean) {
    return this.http.delete<MBusDevice>(this.SERVICE_URL + "/gateway/" + gatewaySerial + "/port/mbus/device/" + device.id + "?deleteInEiot=" + deleteInEoit + "&serialPort="+ serialPort);
  }

  @triggerLoader
  @checkFullyConnected
  public addMBusDevice(gatewaySerial: string, deviceSerialNumber: string, serialPort: string, baudRate: number, dataPoints: MBusDataPoint[]): Observable<MBusDataPoint[]> {
    return this.http.post<MBusDataPoint[]>(this.SERVICE_URL + '/gateway/' + gatewaySerial + '/port/mbus/device/' + deviceSerialNumber + '?serialPort=' + serialPort + '&baudRate=' + baudRate, dataPoints);
  }

  public getMbusDatapoints(gatewaySerial: string, payload: MBusScanDataPointsPayload): Observable<MbusDataPointScanResults> {
    return this.http.post<MbusDataPointScanResults>(this.SERVICE_URL + '/gateway/' + gatewaySerial + "/port/mbus/datapoint-discovery/results", payload);
  }

  public removeLastScanErrors(gatewaySerial: string, serialPort: string) {
    return this.http.patch(this.SERVICE_URL + "/gateway/" + gatewaySerial + "/port/mbus/devices/scan?serialPort=" + serialPort, {});
  }
}
