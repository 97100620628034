import {Injectable} from "@angular/core";
import {AbstractControl, AsyncValidatorFn, ValidationErrors} from "@angular/forms";
import {map, Observable} from "rxjs";
import {GridxDeviceService} from "../../services/gridx-device.service";
import {MBusScanDevicesPayload} from "../../domain/mbus-scan-devices-payload";

@Injectable({ providedIn: 'root' })
export class MbusDeviceNotAlreadyOnboarded {
  static createValidator(serial: string, payload: MBusScanDevicesPayload, gridxService: GridxDeviceService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return gridxService.getMBusDevices(serial, payload).pipe(
        map(result => result.meters.some( meter => meter.id === control.value.toString()) ? { mbusDeviceNotAlreadyInUse: true } : null)
      );
    }
  }
}
