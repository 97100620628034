import {catchError, map, Observable, throwError} from "rxjs";
import {LoaderService} from "../../services/loader.service";

let loaderService: LoaderService

export function setLoaderService(l: LoaderService) {
  loaderService = l
}

export function triggerLoader(target: any, propertyKey: string, descriptor: PropertyDescriptor) {

  let original = descriptor.value
  descriptor.value = function () {

    loaderService.showLoader();

    return original.apply(this, arguments)
      .pipe(
        map((res) => {
          loaderService.hideLoader();
          return res;
        }),
        catchError((err) => {
          loaderService.hideLoader();
          return throwError(err);
        })
      );
  };
  return descriptor;
}



