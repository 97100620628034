import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EonIotService} from "../../../services/eon-iot.service";
import {FormControl, Validators} from "@angular/forms";

export interface DialogData {
  gatewayId: string;
  location: string;
  tenant: string;
}

@Component({
  selector: 'eis-gateway-change-location-dialog',
  templateUrl: './change-location-dialog.component.html',
  styleUrls: ['./change-location-dialog.component.scss']
})
export class ChangeLocationDialogComponent implements OnInit {

  public gatewaySerial: string;
  public location: string;
  public tenant: string;
  public locationInput: FormControl<string | null>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
              public dialogRef: MatDialogRef<ChangeLocationDialogComponent>,
              private eiotService: EonIotService) {
    this.locationInput = new FormControl('', Validators.required);
  }

  ngOnInit(): void {
    this.gatewaySerial = this.data.gatewayId;
    this.location = this.data.location;
    this.tenant = this.data.tenant;
  }

  changeGateway(): void {
    this.eiotService.changeGateway(this.gatewaySerial, this.tenant, this.locationInput.value!).subscribe( data =>
    {
      this.closeDialog(true);
    });
  }

  public closeDialog(result?: boolean) {
    this.dialogRef.close(result);
  }

}
