<div mat-dialog-title class="dialog-title">
  <eon-ui-icon fxLayout fxLayoutAlign="end" [mat-dialog-close]="{status: 'closed'}" class=closeX
               name="close"></eon-ui-icon>
  <div
    class="headline">{{(osVersion ? 'add-version-dialog.button.update' : 'add-version-dialog.button.add') | translate}}</div>
</div>

<div mat-dialog-content fxFlex class="dialog-content">
  <div fxLayout="column" fxLayoutGap="12px" [formGroup]="formGroup">
    <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
      <mat-label>{{'add-version-dialog.label.os-version' | translate}}</mat-label>
      <input [formControl]="version"
             matInput
             class="eon-style-input"
      >
      <mat-error
        *ngIf="version.invalid">{{'add-version-dialog.input.version.error' | translate}}</mat-error>
      <mat-hint align="start">{{'add-version-dialog.input.version.hint' | translate}}</mat-hint>
    </mat-form-field>
  </div>
</div>

<div mat-dialog-actions style="justify-content: space-between;">
  <eon-ui-button scheme="redInverted" icon="close" text="{{'general.cancel' | translate}}"
                 [mat-dialog-close]="{status: 'closed'}"></eon-ui-button>
  <eon-ui-button (click)="update()" icon="{{osVersion ? 'save' : 'add'}}" text="{{(osVersion ? 'add-version-dialog.button.update' : 'add-version-dialog.button.add') | translate}}"></eon-ui-button>
</div>
