import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MBusDataPoint, MBusDevice } from '../../../../domain/device.interface';
import { MatTableDataSource } from '@angular/material/table';
import {GridxDeviceService} from "../../../../services/gridx-device.service";
import {Comport} from "../../../../domain/comport";

@Component({
  selector: 'eis-gateway-mbus-error-device-table',
  templateUrl: './mbus-error-device-table.component.html',
  styleUrls: ['./mbus-error-device-table.component.scss']
})
export class MbusErrorDeviceTableComponent implements OnInit {
  public devices: MatTableDataSource<MBusDevice>;
  public displayedColumns: string[] = ['deviceId', 'error']

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { devices: MBusDevice[], port: Comport, serial: string},
    public dialogRef: MatDialogRef<MbusErrorDeviceTableComponent>,
    public gridxService: GridxDeviceService,
  ) {
    this.devices = new MatTableDataSource<MBusDevice>(data.devices);
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  clearErrors() {
    let port = this.data.port.port
    let serial = this.data.serial
    this.gridxService.removeLastScanErrors(serial, port).subscribe({ next: value =>
        this.dialogRef.close(true)
    })

  }
}
