import {Component, OnInit} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']

})
export class AppComponent implements OnInit {

  static ICON_NAMES = [
    'chevron-right',
    'eon-logo',
    'eis-assets-working-icon',
    'eis-assets-tenant',
    'eis-assets-icn-tenant',
    'eis-assets-icn-modbus',
    'eis-assets-icn-network',
    'eis-assets-icn-port-wtrmrk',
    'logo-red',
    'eon-trash-bin',
    'link-external'
  ]

  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {
    this.registerSvgIcons();
  }

  private registerSvgIcons(): void {
    AppComponent.ICON_NAMES.forEach(iconName => {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/${iconName}.svg`)
      );
    });
  }

  ngOnInit() {
  }

}
