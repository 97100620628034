<div class="table-container">
  <table mat-table [dataSource]="gateways" matSort matSortActive="location" matSortDirection="asc">
    <ng-container matColumnDef="online">
      <th mat-header-cell *matHeaderCellDef
          mat-sort-header="online">{{'gateway-table.header.online-status' | translate}}</th>
      <td mat-cell *matCellDef="let gateway">
        <mat-icon *ngIf="gateway.online" class="wifi-on">wifi</mat-icon>
        <mat-icon *ngIf="!gateway.online" class="wifi-off">wifi_off</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef
          mat-sort-header="location">{{'gateway-table.header.location' | translate}}</th>
      <td mat-cell *matCellDef="let gateway"> {{gateway.location}} </td>
    </ng-container>

    <ng-container matColumnDef="gatewaySerial">
      <th mat-header-cell *matHeaderCellDef
          mat-sort-header="gatewaySerial">{{'gateway-table.header.id' | translate}}</th>
      <td mat-cell *matCellDef="let gateway">
        <a *ngIf="false; else elseLink" target="_blank"
           href="https://app.iot.eon.com/tenants/{{tenant}}/gateways/{{gateway.gatewaySerial}}">{{gateway.gatewaySerial}}
        </a>
        <ng-template #elseLink>{{gateway.gatewaySerial}}</ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="osVersion">
      <th mat-header-cell *matHeaderCellDef
          mat-sort-header="osVersion">{{'gateway-table.header.os-version' | translate}}</th>
      <td mat-cell *matCellDef="let gateway">
        <eis-gateway-gateway-os-version [gateway]="gateway" [latestOsVersion]="imageOsVersions.osVersion"></eis-gateway-gateway-os-version>
      </td>
    </ng-container>

    <ng-container matColumnDef="imageVersion">
      <th mat-header-cell *matHeaderCellDef
          mat-sort-header="imageVersion">{{'gateway-table.header.image-version' | translate}}</th>
      <td mat-cell *matCellDef="let gateway">
        <eis-gateway-gateway-image-version [gateway]="gateway" [latestImageName]="imageOsVersions.imageName" [latestImageDate]="imageOsVersions.imageDate"></eis-gateway-gateway-image-version>
      </td>
    </ng-container>

    <ng-container matColumnDef="lastContact">
      <th mat-header-cell *matHeaderCellDef
          mat-sort-header="lastContact">{{'gateway-table.header.last-contact' | translate}}</th>
      <td mat-cell *matCellDef="let gateway"> {{gateway.lastContact | date:'dd.MM.yyyy HH:mm'}} </td>
    </ng-container>

    <ng-container matColumnDef="lastUpdated">
      <th mat-header-cell *matHeaderCellDef
          mat-sort-header="lastUpdated">{{'gateway-table.header.last-updated' | translate}}</th>
      <td mat-cell *matCellDef="let gateway"> {{gateway.lastUpdated | date:'dd.MM.yyyy HH:mm'}} </td>
    </ng-container>

    <ng-container matColumnDef="detailsAction">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
      <td mat-cell *matCellDef="let gateway" (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu" (menuOpened)="menuOpened(true)" (menuClosed)="menuOpened(false)"
          [matMenuTriggerData]="{gw: gateway}">
        <mat-icon class="clickable">more_vert</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row (click)="routeToDetails(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-gw=gw>
    <a mat-menu-item target="_blank" href="https://app.iot.eon.com/tenants/{{gw.tenant}}/gateways/{{gw.gatewaySerial}}"
       [disabled]="!gw.online">
      <mat-icon svgIcon="link-external"></mat-icon>
      {{'gateway-table.popupmenu.eiot' | translate}}
    </a>

    <a mat-menu-item target="_blank"
       href="https://monitoring.eon.com/de-DE/app/s187_sonic_digital_twin/search?q=search%20source%3D%22http%3Ahttp_sonic_digital_twin_s187_eis_gateway%22%7C%20search%20serialnumber%3D%22{{gw.gatewaySerial}}%22">
      <mat-icon svgIcon="link-external"></mat-icon>
      {{'gateway-table.popupmenu.log' | translate}}
    </a>

    <button mat-menu-item (click)="openChangeDialog(gw.gatewaySerial, gw.tenant, gw.location)">
      <mat-icon>edit</mat-icon>
      {{'gateway-table.popupmenu.edit' | translate}}
    </button>

    <button mat-menu-item (click)="openGatewayConfig(gw.gatewaySerial)">
      <mat-icon>settings</mat-icon>
      {{'gateway-table.popupmenu.config' | translate}}
    </button>

    <button mat-menu-item (click)="openDeletionDialog(gw.gatewaySerial)">
      <mat-icon svgIcon="eon-trash-bin"></mat-icon>
      {{'gateway-table.popupmenu.delete' | translate}}
    </button>

  </ng-template>
</mat-menu>


