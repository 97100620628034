<eis-gateway-app-shell>
  <div class="page-container">
    <div class="top-content-area">
      <eis-gateway-breadcrumb [breadcrumbItems] = breadcrumbData></eis-gateway-breadcrumb>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h1>{{'gateway-overview.top-content.headline' | translate}}</h1>
        <eon-ui-button [text]="'gateway-selection.page.button.add-gateway' | translate" (click)="openAddGatewayDialog()"></eon-ui-button>
      </div>
      <p class="sub-headline">
        {{'gateway-overview.top-content.subheadline' | translate}}&nbsp;&nbsp;
        <mat-form-field class="selection-form" [formGroup]="tenantFormGroup">
          <mat-select formControlName="tenantSelection" [compareWith]="compareFn"  #singleSelect>
            <mat-option>
              <ngx-mat-select-search formControlName="tenantFilter" placeholderLabel="Search" noEntriesFoundLabel="No tenant has been found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let tenant of filteredTenants | async" [value]="tenant">
              {{tenant}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </p>
      <div fxLayout="row" fxLayoutGap="32px" class="status-container-area">
        <div fxLayout="row" class="status-container" fxLayoutAlign="start center" fxLayoutGap="32px">
          <mat-icon svgIcon="eis-assets-working-icon" class="status-icon-1"></mat-icon>
          <div fxLayout="column">
            <p class="status-headline">{{this.totalAmountOfGateways}} Gateways</p>
            <p class="status-subheadline">Total</p>
          </div>
        </div>
        <div fxLayout="row" class="status-container" fxLayoutAlign="start center" fxLayoutGap="32px">
          <mat-icon class="status-icon-2">wifi</mat-icon>
          <div fxLayout="column">
            <p class="status-headline">{{this.amountOnlineGateways}} Gateway(s)</p>
            <p class="status-subheadline">Online</p>
          </div>
        </div>
        <div fxLayout="row" class="status-container" fxLayoutAlign="start center" fxLayoutGap="32px">
          <mat-icon class="status-icon-3">wifi_off</mat-icon>
          <div fxLayout="column">
            <p class="status-headline">{{this.amountOfflineGateways}} Gateways</p>
            <p class="status-subheadline">Offline</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-content-area">
      <div class="main-content-area">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <p>{{totalAmountOfGateways}} Gateways found</p>
          <eon-ui-input class="location-search-input"
                        [formControl]=locationSearch
                        placeholder="{{'general.location-name' | translate}}"
                        type="text"
                        trailing-icon-name="search"
                        trailing-icon="true"
          ></eon-ui-input>
        </div>
        <eis-gateway-gateway-table [tenant]="tenant" [gateways]="this.gateways" [imageOsVersions]="imageOsVersion" (updateDatasource)="refresh()" (menuVisibilityChanged)="menuVisibilityChanged($event)"></eis-gateway-gateway-table>
      </div>
    </div>
  </div>

</eis-gateway-app-shell>

