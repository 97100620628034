<div class="container" fxLayout="column">
  <div class="top" fxFlex>
    <h2>{{headline}}</h2>
    <p>{{subtext}}</p>
  </div>
  <div class="bottom-back" fxFlex="1 1 100%">
    <div class="bottom-content">
      <p class="step">{{stepindicator}}</p>
      <h3 class="stepheadline">{{stepheadline}}</h3>

      <ng-content></ng-content>

    </div>
    <mat-icon svgIcon="eis-assets-working-icon" class="watermark"></mat-icon>

  </div>
</div>

