import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Device, MBusConfig, MBusDevice } from '../../../../domain/device.interface';
import { DeviceService } from '../../../../services/device.service';

@Component({
  selector: 'eis-gateway-mbus-edit-device-labels',
  templateUrl: './mbus-edit-device-labels-dialog.component.html',
  styleUrls: ['./mbus-edit-device-labels-dialog.component.scss']
})
export class MbusEditDeviceLabelsDialogComponent implements OnInit {
  public device: MBusDevice;
  deviceLabels: string = "";
  dataPointLabels: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: {device: MBusDevice, activeSerial: string},
              public dialogRef: MatDialogRef<MbusEditDeviceLabelsDialogComponent>,
              private deviceService: DeviceService) {
    this.device = data.device;
  }

  ngOnInit(): void {
    if(this.data.device.id) {
      this.deviceService.getDeviceByName(this.data.activeSerial, this.data.device.id)
        .subscribe(device => this.deviceLabels = this.deviceLabelsToString(device)
        );
    }
  }

  transferLabels() {
    this.dataPointLabels = this.deviceLabels;
  }

  updateLabels() {
    this.deviceService.updateMbusDeviceLabels(this.data.activeSerial, this.device.id, this.deviceLabels, this.dataPointLabels).subscribe(
      {
        next: (_) => this.dialogRef.close({
          status: "200",
        }),
        error: (err) => {
          this.dialogRef.close({
            status: err.status.toString()
          });
        }
      }
    )
  }

  private deviceLabelsToString(device: Device) {
    if(!device?.labels) {
      return "";
    }

    return JSON.stringify(device.labels)
      .replace(/"/g, "")
      .replace(/",/g, ", ")
      .replace("{", "")
      .replace("}", "");
  }
}
