import { Component, OnInit } from '@angular/core';
import {EonIotService} from "../../services/eon-iot.service";
import {ModbusWritePayload} from "../../domain/modbus-write-payload";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'eis-gateway-gateway-modbus-write',
  templateUrl: './gateway-modbus-write.component.html',
  styleUrls: ['./gateway-modbus-write.component.scss']
})
export class GatewayModbusWriteComponent implements OnInit {

  public serial = "";
  public payload: ModbusWritePayload;

  constructor(private eiotService: EonIotService,
              private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.serial = this.route.snapshot.params['serial'];

  }

  sendModbusWrite() {
    this.payload = {
      tenant: "assethub_dev",
      deviceId: "MB_SIM_RDWR",
      address: 0,
      format: "INT16",
      byteOrder: "ABCD",
      value: 100,
    }

    this.eiotService.writeModbus(this.payload, this.serial).subscribe()
  }

}
