import {Component, OnInit} from '@angular/core';
import {BreadcrumbItem} from "../../components/breadcrumb/breadcrumbItem";
import {MatDialog} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import {DeploymentService} from "../../services/deployment.service";
import {GatewayDeployment} from "../../domain/gateway-deployment.interface";


enum availableViews {
  NETWORK = "network",
  RTU = "rtu",
  LOGLEVEL = "loglevel",
  SOFTWAREUPDATE = "softwareupdate"
}

@Component({
  selector: 'eis-gateway-gateway-configuration',
  templateUrl: './gateway-configuration.component.html',
  styleUrls: ['./gateway-configuration.component.scss']
})
export class GatewayConfigurationComponent implements OnInit {

  public serial = "";

  public activeView = availableViews.NETWORK

  public gatewayDeployment: GatewayDeployment | null;
  public breadcrumbData: BreadcrumbItem[]


  constructor(private dialog: MatDialog,
              private route: ActivatedRoute,
              private deploymentService: DeploymentService,
              private router: Router,) {
  }

  ngOnInit(): void {
    this.serial = this.route.snapshot.params['serial'];
    const section = this.route.snapshot.params['section'];
    this.changeView(section);
    this.deploymentService.getGatewayDeployment(this.serial).subscribe(data => {
      this.gatewayDeployment = data;
      this.breadcrumbData = [
        {name: "Gateway Admin Home", link: ['/tenants']},
        {name: "Gateways", link: ['/gateway-overview', this.gatewayDeployment?.tenant || ""]},
        {name: this.serial, link: ['/gateway', this.serial || ""]}
      ]
    })
  }

  changeView(views: string) {
    switch(views) {
      case 'network':
        this.activeView = availableViews.NETWORK;
        break;
      case 'rtu':
        this.activeView = availableViews.RTU;
        break;
      case 'loglevel':
        this.activeView = availableViews.LOGLEVEL;
        break;
      case 'softwareupdate':
        this.activeView = availableViews.SOFTWAREUPDATE;
        break;
    }
  }

  gotoSection(section: string) {
    this.router.navigate(['gateway/' + this.serial + '/config/' + section]);
    this.changeView(section);
  }

  getActiveViewClass(views: string) {
    return views === this.activeView ? "list-item-active" : "list-item-inactive";
  }
}
