import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GatewayOSVersion } from '../../../../domain/gateway-osversion.interface';
import { OSVersionService } from '../../../../services/os-version.service';

@Component({
  selector: 'eis-gateway-delete-version-dialog',
  templateUrl: './delete-version-dialog.component.html',
  styleUrls: ['./delete-version-dialog.component.scss']
})
export class DeleteVersionDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { osVersion: GatewayOSVersion },
              public dialogRef: MatDialogRef<DeleteVersionDialogComponent>,
              private osVersionService: OSVersionService) { }

  ngOnInit(): void {
  }

  deleteVersion() {
    this.osVersionService.deleteVersion(this.data.osVersion)
      .subscribe({
        next: (_) => this.dialogRef.close({success: true}),
        error: (error) => this.dialogRef.close({success: false, error: error})
      });
  }
}
