import {Injectable} from "@angular/core";
import {AbstractControl, AsyncValidatorFn, ValidationErrors} from "@angular/forms";
import {map, Observable} from "rxjs";
import {EonIotService} from "../../services/eon-iot.service";

@Injectable({ providedIn: 'root' })
export class EiotGatewayNotInUseValidator {
  static createValidator(tenant: string, eiotService: EonIotService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return eiotService.checkGateway(tenant, control.value).pipe(
        map(result => result ? { eiotGatewayNotInUse: true } : null)
      );
    }
  }
}
