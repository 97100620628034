import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MBusConfig } from '../../../../domain/device.interface';

@Component({
  selector: 'eis-gateway-mbus-config-table',
  templateUrl: './mbus-config-table.component.html',
  styleUrls: ['./mbus-config-table.component.scss']
})
export class MbusConfigTableComponent implements OnInit {

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;

  @Input()
  public gatewaySerial: string | undefined;

  @Input()
  public configs: MatTableDataSource<MBusConfig>;

  public displayedColumns: string[] = ['secondaryAddress', 'key', 'serialPort', 'baudrate', 'pollingFrequency']

  constructor() {
  }

  ngOnInit(): void {
  }

  refresh(): void {
    this.table.renderRows();
  }
}
