<div fxLayout="column" fxLayoutGap="10px">
<div *ngIf="needsImageUpdate != undefined">
    <div class="config-container" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="80" fxLayout="row" fxLayoutGap="10px">
          <h3 class="config-container-headline">{{'gateway-software-update-config.headline' | translate}}</h3>
        </div>
      </div>
      <mat-divider class="divider"></mat-divider>
      <div fxLayout="row" class="content-area">
          <div *ngIf="!needsImageUpdate">
            {{'gateway-software-update-config.gateway-upto-date' | translate}}
          </div>
          <div *ngIf="needsImageUpdate">
            <p [innerHTML]="'gateway-software-update-config.gateway-update-required' | translate: {differenceInDays: differenceInDays}"></p>
            <br /><br />
            <eon-ui-button [text]="'gateway-software-update-config.button.update-software' | translate" icon="download" class="bottom-button"
                           (click)="installLatestImage()"></eon-ui-button>
          </div>
      </div>
    </div>
</div>


<div *ngIf="differenceInDaysForOs != undefined">
  <div class="config-container" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="80" fxLayout="row" fxLayoutGap="10px">
        <h3 class="config-container-headline">{{'gateway-os-update-config.headline' | translate}}</h3>
      </div>
    </div>
    <mat-divider class="divider"></mat-divider>
    <div fxLayout="row" class="content-area">
      <div *ngIf="osUpdateTriggered">
        {{'gateway-os-update-config.os-update-triggered' | translate: {latestVersion: imageOsVersion.osVersion} }}
      </div>

      <div *ngIf="!osUpdateTriggered && differenceInDaysForOs == 0">
        {{'gateway-os-update-config.gateway-upto-date' | translate}}
      </div>

      <div *ngIf="!osUpdateTriggered && differenceInDaysForOs > 0">
        <p [innerHTML]="'gateway-os-update-config.os-update-required' | translate: {differenceInDays: differenceInDaysForOs}"></p>
        <br /><br />
        <eon-ui-button [text]="'gateway-software-update-config.button.update-software' | translate" icon="download" class="bottom-button"
                       (click)="updateGatewayOS()"></eon-ui-button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isAdmin">
  <div class="config-container" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="80" fxLayout="row" fxLayoutGap="10px">
        <h3 class="config-container-headline">{{'gateway-software-update-admin-config.headline' | translate}}</h3>
      </div>
    </div>
    <mat-divider class="divider"></mat-divider>
    <div fxLayout="row" class="content-area">
      <eon-ui-button [text]="'gateway-software-update-config.button.update-dev-software' | translate" icon="download" class="bottom-button"
                     (click)="installLatestDevImage()"></eon-ui-button>
    </div>
  </div>
</div>


</div>
