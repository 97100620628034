import { Component, OnDestroy, OnInit } from '@angular/core';
import {AuthService} from "../../services/auth.service";
import { map } from 'rxjs/operators';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'eis-gateway-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  loggedIn: boolean = false;
  isAdmin: boolean = false;

  constructor(public authService: AuthService) {
  }

  ngOnInit(): void {
    this.authService.getConnectionStatus().subscribe( value => {
      this.loggedIn = value.fullyConnected;
    });

    this.authService.getRoles().subscribe(roles => {
      this.isAdmin = roles.indexOf("eisadmin.admin") > -1
    });
  }

  ngOnDestroy(): void {
  }
}
