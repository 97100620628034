<mat-sidenav-container [hasBackdrop]="true">
  <mat-sidenav-content>
    <eis-gateway-mbus-device-table
      [gatewaySerial]="gatewaySerial"
      [tenant]="tenant"
      [location]="location"
      [mBusConfig]="mBusConfig"
      (deviceSelected)="deviceSelected($event)"
      (configUpdated)="changesApplied()">
    </eis-gateway-mbus-device-table>
  </mat-sidenav-content>

  <mat-sidenav #drawer [fixedInViewport]="true" [fixedTopGap]="60" [fixedBottomGap]="0" [disableClose]="true" position="end">
    <eis-gateway-mbus-datapoint-configurator-table
      [gatewaySerial]="gatewaySerial"
      [location]="location"
      [mBusConfig]="mBusConfig"
      [device]="selectedDevice"
      [addedDataPoints]="addedDataPoints"
      [removedDataPoints]="removedDataPoints"
      [port]="selectedPort"
      [drawer]="drawer"
      (dataPointsUpdated)="applyChanges($event)"
    ></eis-gateway-mbus-datapoint-configurator-table>
  </mat-sidenav>

</mat-sidenav-container>
