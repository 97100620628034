<div fxLayout="column" class="footer">
  <div fxFlex=grow fxLayout fxLayoutAlign="space-between center">
    <div class="footer-bold">
      <span>Imprint</span>
      <span class="spacing">Privacy Policy</span>
    </div>
    <div fxLayout fxLayoutAlign="end center">
      <div class="footer-light">© E.ON SE 2024</div>
      <div class="eon-logo">
        <mat-icon svgIcon="eon-logo"></mat-icon>
      </div>
    </div>
  </div>
  <eis-gateway-color-strip></eis-gateway-color-strip>
</div>
