import {Component, Inject, OnInit} from '@angular/core';
import {GridxNetworkDeviceConfigReset} from "../../../../domain/gridx-device.interface";
import {GridxDeviceService} from "../../../../services/gridx-device.service";
import {NotificationService} from "../../../../services/notification.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface DialogData {
  activeSerial: string;
}

@Component({
  selector: 'eis-gateway-reset-ip-configuration-dialog',
  templateUrl: './reset-ip-configuration-dialog.component.html',
  styleUrls: ['./reset-ip-configuration-dialog.component.scss']
})
export class ResetIpConfigurationDialogComponent implements OnInit {

  public outputNetworkConfig: GridxNetworkDeviceConfigReset = {
    spec: {
      networkConfig: {
        eth0: null,
      }
    }
  }
  public serial: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
              public gridxService: GridxDeviceService,
              public notificationService: NotificationService,
              public dialogRef: MatDialogRef<ResetIpConfigurationDialogComponent>,) { }

  ngOnInit(): void {
    this.serial = this.data.activeSerial
  }

  resetIpConfiguration() {
    this.gridxService.postNetworkConfig(this.outputNetworkConfig, this.serial).subscribe({
      next: (_) => {
        this.dialogRef.close({status: "200"});
      },
      error: (err) => this.dialogRef.close({status: err.status})
    });

  }
}
