import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {ConnectionStatus} from "../../domain/login.interface";

@Component({
  selector: 'eis-gateway-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  public auth: string | null = null
  public connectionStatus: ConnectionStatus | null = null

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
    //this.authService.getRoles().subscribe(data => this.auth = data)
    this.authService.getConnectionStatus().subscribe(data => this.connectionStatus = data)
  }

}
