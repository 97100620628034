<div mat-dialog-title class="dialog-title">
  <eon-ui-icon fxLayout fxLayoutAlign="end" [mat-dialog-close]="{status: 'closed'}" class=closeX
               name="close"></eon-ui-icon>
  <div
    class="headline">{{ 'mbus-edit-device-labels-dialog.headline' | translate}}</div>
</div>

<ng-container>
  <mat-dialog-content fxFlex class="dialog-content">
    <div>
      <div fxLayout="column">
        <div fxLayout="column">
          <div fxLayout="row">
            <div fxLayout="column" class="eon-style-form-field">
              <div fxLayout="column" fxLayoutGap="20px">
                <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
                  <mat-label>{{'mbus-device-table.header.serial-number' | translate}}</mat-label>
                  <input name="deviceLabels" [ngModel]="device.id"
                         matInput
                         class="eon-style-input"
                         type="text"
                         disabled
                  >
                </mat-form-field>
              </div>

              <div class="radio-button-headline">
                <div class="tooltip-div">
                  <eon-ui-info-icon>
                    <eon-ui-tooltip slot="tooltip">
                      <eon-ui-text
                        text-style="quote">{{'add-gateway-dialog.labels.tooltip.headline' | translate}}</eon-ui-text>
                      <eon-ui-text
                        text-style="subheadline">{{'add-gateway-dialog.labels.tooltip.subheadline' | translate}}</eon-ui-text>
                      <eon-ui-text text-style="abstract">
                        {{'add-gateway-dialog.labels.tooltip.example' | translate}}
                      </eon-ui-text>
                      <eon-ui-text
                        text-style="subheadline">{{'add-gateway-dialog.labels.tooltip.delete-label' | translate}}</eon-ui-text>
                      <eon-ui-text text-style="abstract">
                        {{'add-gateway-dialog.labels.tooltip.delete-example' | translate}}
                      </eon-ui-text>
                    </eon-ui-tooltip>
                  </eon-ui-info-icon>
                  <span>{{'add-device-dialog.input.labels' | translate}}</span>
                </div>
              </div>
              <div fxLayout="column" fxLayoutGap="20px">
                <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
                  <input name="deviceLabels" [(ngModel)]="deviceLabels"
                         matInput
                         class="eon-style-input"
                         type="text"
                  >
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutAlign="left center">
                <div class="tooltip-div">
                  <eon-ui-info-icon>
                    <eon-ui-tooltip slot="tooltip">
                      <eon-ui-text
                        [innerHTML]="'add-gateway-dialog.data-point-labels.tooltip' | translate"
                        text-style="subheadline"></eon-ui-text>
                    </eon-ui-tooltip>
                  </eon-ui-info-icon>

                  <span class="margin-right-24">{{'add-device-dialog.input.data-point-labels' | translate}}</span>
                  <eon-ui-link (click)="transferLabels()" icon="arrow_down" text="{{'add-device-dialog.input.copy-labels' | translate}}"></eon-ui-link>
                </div>
              </div>

              <div fxLayout="column" fxLayoutGap="20px">
                <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
                  <input name="dataPointLabels" [(ngModel)]="dataPointLabels"
                         matInput
                         class="eon-style-input"
                         type="text"
                  >
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="justify-content: space-between;">
    <eon-ui-button scheme="red" rank="secondary" icon="close" text="{{'general.cancel' | translate}}"
                   [mat-dialog-close]="{status: 'closed'}"></eon-ui-button>

    <eon-ui-button (click)="updateLabels()"
                   text="{{'mbus-edit-device-labels-dialog.actions.update' | translate}}"></eon-ui-button>
  </mat-dialog-actions>

</ng-container>
