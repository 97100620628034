<eis-gateway-app-shell>
  <div class="top-content-area">
    <eis-gateway-breadcrumb [breadcrumbItems] = breadcrumbData></eis-gateway-breadcrumb>
    <div fxLayout="row">
      <h1>{{'gateway-configuration.headline' | translate}}</h1>
    </div>
  </div>
  <div class="bottom-content-area">
    <div class="split-view" fxLayout="row">
      <div class="list-menu" fxLayout="column" fxFlex="27">
        <h2>{{'gateway-configuration.subheadline' | translate}}</h2>
        <div [ngClass]="getActiveViewClass('network')" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" (click)="gotoSection('network')">
          <mat-icon svgIcon="eis-assets-icn-network" fxFlex="20"></mat-icon>
          <p fxFlex="80">{{'gateway-configuration.network-config' | translate}}</p>
        </div>
        <div [ngClass]="getActiveViewClass('rtu')" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" (click)="gotoSection('rtu')">
          <mat-icon svgIcon="eis-assets-icn-modbus" fxFlex="20"></mat-icon>
          <p fxFlex="80">{{'gateway-configuration.port-config' | translate}}</p>
        </div>
        <div [ngClass]="getActiveViewClass('loglevel')" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" (click)="gotoSection('loglevel')">
          <mat-icon fxFlex="20" class="mat-text-icon">settings</mat-icon>
          <p fxFlex="80">{{'gateway-configuration.loglevel-config' | translate}}</p>
        </div>
        <div [ngClass]="getActiveViewClass('softwareupdate')" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" (click)="gotoSection('softwareupdate')">
          <mat-icon fxFlex="20" class="mat-text-icon">system_update</mat-icon>
          <p fxFlex="80">{{'gateway-configuration.software-update-config' | translate}}</p>
        </div>
      </div>
      <div class="config-view" fxLayout="column" fxFlex="73">
        <eis-gateway-gateway-network-config [serial]="this.serial" *ngIf="activeView === 'network'"></eis-gateway-gateway-network-config>
        <eis-gateway-gateway-com-port-config [serial]="this.serial" *ngIf="activeView === 'rtu'"></eis-gateway-gateway-com-port-config>
        <eis-gateway-gateway-log-level-config [serial]="this.serial" *ngIf="activeView === 'loglevel'"></eis-gateway-gateway-log-level-config>
        <eis-gateway-gateway-software-update-config [serial]="this.serial" *ngIf="activeView === 'softwareupdate'"></eis-gateway-gateway-software-update-config>
      </div>
    </div>

  </div>

</eis-gateway-app-shell>
