import {Component, Inject, OnInit} from '@angular/core';
import {DeploymentService} from "../../../services/deployment.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DeviceService} from "../../../services/device.service";

export interface DialogData {
  gatewayId: string;
}

@Component({
  selector: 'eis-gateway-delete-gateway-dialog',
  templateUrl: './delete-gateway-dialog.component.html',
  styleUrls: ['./delete-gateway-dialog.component.scss']
})
export class DeleteGatewayDialogComponent implements OnInit {

  public gatewaySerial: string;

  public deletionAllowed: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
              public dialogRef: MatDialogRef<DeleteGatewayDialogComponent>,
              private deploymentService: DeploymentService,
              private deviceService: DeviceService) { }

  ngOnInit(): void {
    this.gatewaySerial = this.data.gatewayId;
    this.deviceService.getDevices(this.gatewaySerial).subscribe(value =>this.deletionAllowed = value.length == 0)
  }

  deleteGateway(): void {
    this.deploymentService.deleteDeployment(this.gatewaySerial).subscribe(
      data =>this.closeDialog(data)
    , error => this.closeDialog(false));
  }

  public closeDialog(result?: boolean) {
    this.dialogRef.close(result);
  }

}
