import {Component, OnInit} from '@angular/core';
import {EonIotService} from "../../services/eon-iot.service";
import {EiotTenant} from "../../domain/eiot-tenant.interface";
import {Observable, retry, timeout} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {TenantCardComponent} from "../../components/tenant-card/tenant-card.component";

@Component({
  selector: 'eis-gateway-tenants',
  templateUrl: './tenant-selection.component.html',
  styleUrls: ['./tenant-selection.component.scss']
})
export class TenantSelectionComponent implements OnInit {

  public $userTenants!: Observable<EiotTenant[]>;

  cardClicked: boolean = false;
  selectedTenant: string = ""

  constructor(private dialog: MatDialog, private eonIotService: EonIotService) {
  }

  ngOnInit(): void {
    this.$userTenants = this.eonIotService.getTenants().pipe(
      timeout(2000),
      retry(5)
    );
  }


  tenantSelected($event: TenantCardComponent) {
    this.cardClicked = true;
    this.selectedTenant = $event.title
  }
}
