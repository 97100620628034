import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import 'dayjs/locale/de'
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { DeploymentService } from "../../../../services/deployment.service";
import { Router } from "@angular/router";
import { NotificationService } from '../../../../services/notification.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MBusDataPoint } from '../../../../domain/device.interface';

@Component({
  selector: 'eis-gateway-mbus-datapoint-table',
  templateUrl: './mbus-datapoint-table.component.html',
  styleUrls: ['./mbus-datapoint-table.component.scss']
})
export class MbusDatapointTableComponent implements OnInit, AfterViewInit {

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;

  @Input()
  public gatewaySerial: string | undefined;

  @Input()
  public datapoints: MatTableDataSource<MBusDataPoint>;

  @Output()
  public updateDatasource = new EventEmitter<string>();

  public displayedColumns: string[] = ['secondaryAddress', 'key', 'dataType', 'value', 'unit', 'pollingFrequency']

  constructor(private deploymentService: DeploymentService,
              private router: Router,
              private clipboard: Clipboard,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
  }

  refresh(): void {
    this.table.renderRows();
  }

  async copyLabelsToClipboard(labels: string) {
    if(!labels) {
      return;
    }

    this.clipboard.copy(labels);
    await this.notificationService.success("Copied to clipboard");
  }

  ngAfterViewInit(): void {
    this.datapoints.sort = this.sort;
  }
}
