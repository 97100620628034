<eis-gateway-app-shell>
  <div class="top-content-area">
    <div fxLayout="row">
      <h1>{{'admin-page.headline' | translate}}</h1>
    </div>
  </div>
  <div class="bottom-content-area">
    <div class="split-view" fxLayout="row">
      <div class="list-menu" fxLayout="column" fxFlex="27">
        <div [ngClass]="getActiveViewClass('unit-conversions')" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" (click)="gotoSection('unit-conversions')">
          <mat-icon svgIcon="eis-assets-icn-network" fxFlex="20"></mat-icon>
          <p fxFlex="80">{{'admin-page.unit-conversions' | translate}}</p>
        </div>

        <div [ngClass]="getActiveViewClass('os-versions')" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" (click)="gotoSection('os-versions')">
          <eon-ui-icon name="change_contract_02"></eon-ui-icon>
          <p fxFlex="80">{{'admin-page.os-versions' | translate}}</p>
        </div>
      </div>
      <div class="config-view" fxLayout="column" fxFlex="73">
        <eis-gateway-unit-conversion *ngIf="activeView === 'unit-conversions'"></eis-gateway-unit-conversion>
        <eis-gateway-os-version *ngIf="activeView === 'os-versions'"></eis-gateway-os-version>
      </div>
    </div>

  </div>

</eis-gateway-app-shell>
