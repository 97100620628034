<div class="config-container" fxLayout="column" fxLayoutAlign="center end" fxLayoutGap="12px">
  <eon-ui-button icon="add"
                 text="{{'os-version-table.action.add-version' | translate}}"
                 (click)="addOSVersion()"></eon-ui-button>

  <div class="table-container">
    <table mat-table matSort [dataSource]="dataSource">
      <ng-container matColumnDef="latestVersion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'os-version-table.header.version' | translate}}</th>
        <td mat-cell *matCellDef="let version">{{version.latestVersion}}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{'unit-conversion-table.header.actions' | translate}}</th>
        <td mat-cell *matCellDef="let version" fxLayoutGap="24px">
          <eon-ui-button icon="edit" size="small" (click)="editOSVersion(version)"></eon-ui-button>

          <eon-ui-button icon="trash_bin" size="small" (click)="deleteOSVersion(version)"></eon-ui-button>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
