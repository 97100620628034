import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NotificationService} from '../../../../services/notification.service';
import {MBusDataPoint} from '../../../../domain/device.interface';
import {GridxDeviceService} from '../../../../services/gridx-device.service';
import {MBusScanDataPointsPayload} from '../../../../domain/mbus-scan-datapoints-payload';
import {Comport} from '../../../../domain/comport';
import {MatSort} from '@angular/material/sort';
import {interval, Subscription} from "rxjs";
import {MbusDeviceNotAlreadyOnboarded} from "../../../../components/validators/mbus-device-not-already-onboarded";
import {MBusScanDevicesPayload} from "../../../../domain/mbus-scan-devices-payload";

@Component({
  selector: 'eis-gateway-add-mbus-device-dialog',
  templateUrl: './add-mbus-device-dialog.component.html',
  styleUrls: ['./add-mbus-device-dialog.component.scss']
})
export class AddMbusDeviceDialogComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public nameInput: FormControl<string | null>;
  public displayedColumns: string[] = ['key', 'data_type', 'value', 'unit'];
  public scanningDeviceDataPoints = false;
  public scanDataPointError = false;
  public dataPoints: MBusDataPoint[] = [];
  public scanResultSubscription: Subscription | null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {comPort: Comport, activeSerial: string, port: string, portName: string, baudRate: number},
    public dialogRef: MatDialogRef<AddMbusDeviceDialogComponent>,
    private gridxService: GridxDeviceService,
    private notificationService: NotificationService
  ) {

    const scanPayload = {
      port: this.data.port,
      portName: this.data.portName,
      baudrate: this.data.baudRate,
      timeout: 20,
    } as MBusScanDevicesPayload;

    this.nameInput = new FormControl('', [Validators.required], [
      MbusDeviceNotAlreadyOnboarded.createValidator(this.data.activeSerial, scanPayload, this.gridxService)
    ]);
  }

  ngOnInit(): void {
    this.dataPoints = [];
    this.scanningDeviceDataPoints = false;
  }

  validateAndUpdate() {
    this.nameInput.markAsTouched();
    if(this.nameInput.valid) {
      this.gridxService.addMBusDevice(this.data.activeSerial, this.nameInput.value!, this.data.comPort.port, this.data.comPort.baudRate, this.dataPoints).subscribe(
        (device) => {
          this.notificationService.success('add-mbus-device-dialog.snackbar.success');
          this.dialogRef.close();
        },
        (error) => {
          this.notificationService.failure('add-mbus-device-dialog.snackbar.error');
        }
      );
    }
  }

  scanDataPoints() {
    this.nameInput.markAsTouched();
    if(this.nameInput.valid) {
      this.scanningDeviceDataPoints = true;
      this.scanDataPointError = false;
      this.dataPoints = [];

      const scanPayload = {
        port: this.data.comPort.port,
        baudrate: this.data.comPort.baudRate,
        secondary_address: this.nameInput.value!
      } as MBusScanDataPointsPayload;

      this.gridxService.scanDataPoints(this.data.activeSerial, scanPayload).subscribe({next: result => {
        if (result) {
          this.getMbusDatapoints();
          this.scanResultSubscription = interval(10 * 1000).subscribe(() => this.getMbusDatapoints());
        }
      },
        error: (error) => {
        this.notificationService.failure(error.error);
        this.scanDataPointError = true;
        this.scanningDeviceDataPoints = false;
      }});
    }
  }

  getMbusDatapoints() {
    const scanPayload = {
      port: this.data.comPort.port,
      baudrate: this.data.comPort.baudRate,
      secondary_address: this.nameInput.value!,
    } as MBusScanDataPointsPayload;

    this.gridxService.getMbusDatapoints(this.data.activeSerial, scanPayload).subscribe(scanResult => {

      if(scanResult.done && scanResult.error == null) {
        this.loadDatapoints(scanPayload.secondary_address),
        this.scanResultSubscription?.unsubscribe();
      }

      if(scanResult.done && scanResult.error != null) {
        this.notificationService.failure(scanResult.error);
        this.scanDataPointError = true;
        this.scanningDeviceDataPoints = false;
        this.scanResultSubscription?.unsubscribe();
      }
    });
  }
  loadDatapoints(secondaryAddress: string) {
    this.gridxService.getMBusDeviceDataPoints(this.data.activeSerial, secondaryAddress).subscribe(dataPoints => {
      this.dataPoints = dataPoints!!;
      this.scanningDeviceDataPoints = false;
    });
  }

}
