import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TenantSelectionComponent } from "./pages/tenant-selection/tenant-selection.component";
import {WelcomeComponent} from "./pages/welcome/welcome.component";
import {SplashComponent} from "./pages/splash/splash.component";
import {GatewayOverviewComponent} from "./pages/gateway-overview/gateway-overview.component";
import {GatewayDetailsComponent} from "./pages/gateway-details/gateway-details.component";
import {LoggedInGuard} from "./components/guards/logged-in.guard";
import {FullyAuthenticatedGuard} from "./components/guards/fully-authenticated.guard";
import {GatewayConfigurationComponent} from "./pages/gateway-configuration/gateway-configuration.component";
import {GatewayModbusWriteComponent} from "./pages/gateway-modbus-write/gateway-modbus-write.component";
import { AdminGuard } from './components/guards/admin.guard';
import { AdminPageComponent } from './pages/admin/admin-page/admin-page.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: SplashComponent
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'tenants',
    component: TenantSelectionComponent,
    canActivate: [FullyAuthenticatedGuard]
  },
  {
    path: 'gateway-overview/:tenant',
    component: GatewayOverviewComponent,
    canActivate: [FullyAuthenticatedGuard]
  },
  {
    path: 'gateway/:serial',
    component: GatewayDetailsComponent,
    canActivate: [FullyAuthenticatedGuard]
  },
  {
    path: 'gateway/:serial/config/:section',
    component: GatewayConfigurationComponent,
    canActivate: [FullyAuthenticatedGuard]
  },
  {
    path: 'gateway/:serial/modbus-write',
    component: GatewayModbusWriteComponent,
    canActivate: [FullyAuthenticatedGuard]
  },
  {
    path: 'admin',
    component: AdminPageComponent,
    canActivate: [AdminGuard]
  },
  {
    path: 'admin/:section',
    component: AdminPageComponent,
    canActivate: [AdminGuard]
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
