import { Component, Input, OnInit } from '@angular/core';
import { GatewayDeployment } from '../../../domain/gateway-deployment.interface';
import { GridxDeviceService } from '../../../services/gridx-device.service';
import * as dayjs from 'dayjs';
import {Router} from "@angular/router";

@Component({
  selector: 'eis-gateway-gateway-image-version',
  templateUrl: './gateway-image-version.component.html',
  styleUrls: ['./gateway-image-version.component.scss']
})
export class GatewayImageVersionComponent implements OnInit {

  @Input()
  gateway: GatewayDeployment

  @Input()
  latestImageName: string

  @Input()
  latestImageDate: Date

  public currentImageName: string;
  public hasImageUpdate: boolean = false;
  public differenceInDays: number | undefined;
  public loading: boolean = false;

  constructor(private gridxService: GridxDeviceService,
              public router: Router){
  }

  ngOnInit(): void {
    this.currentImageName = this.gateway.imageVersion;
    this.hasImageUpdate = this.hasNewImage();
  }

  private hasNewImage() {
    const currentImageDate = this.getImageDate(this.currentImageName);
    const latestImageDate = dayjs(this.latestImageDate);

    if(currentImageDate != null && latestImageDate != null) {
      this.differenceInDays = latestImageDate?.diff(currentImageDate, 'day');
      return currentImageDate.isBefore(latestImageDate);
    }

    return false;
  }

  private getImageDate(currentImage: string) : dayjs.Dayjs | null {
    const imageParts = currentImage.split("-");
    if(imageParts.length >= 2) {
      const datePart = imageParts[1];
      const dateParts = datePart.split("_");
      if(dateParts.length == 3) {
        return dayjs(datePart.replace(new RegExp("_", 'g'), "-") + 'T00:00:00.000Z');
      }
    }

    return null;
  }

  openGatewayConfig($event: MouseEvent): void {
    $event.stopPropagation();
    this.router.navigate(['gateway/' + this.gateway.gatewaySerial + '/config/softwareupdate'])
  }
}
