import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Device} from "../domain/device.interface";
import {checkFullyConnected} from "../components/decorators/checkConnectedDecorator";
import {Comport} from "../domain/comport";

@Injectable({
  providedIn: 'root'
})
export class ComportService {

  constructor(private http: HttpClient) { }

  public getComportConfigs(serial: string): Observable<Comport[]> {
    return this.http.get<Comport[]>('/api/config/' + serial + '/portconfigs') as Observable<Comport[]>;
  }

  public updateComportConfigs(comportConfigs: Comport[], serial: string): Observable<Comport[]> {
    return this.http.post<Comport[]>('/api/config/' + serial + '/portconfigs', comportConfigs) as Observable<Comport[]>;
  }

}
