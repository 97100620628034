<div class="header-container">
  <mat-toolbar>
    <div class="header-content" fxLayout="row" *ngIf="loggedIn">

        <div class="title" fxLayoutAlign="start center" fxFlex="67" fxLayout="row">
          <mat-icon svgIcon="eis-assets-working-icon" class="header-icon" [routerLink]="['/']"></mat-icon>
          <h1 [routerLink]="['/tenants']">EIS Gateway Administration</h1>
        </div>

      <div *ngIf="isAdmin" class="profile admin" fxFlex="8" fxLayoutAlign="center" [routerLink]="['/admin']">
        <h1>Admin</h1>
        <mat-icon>settings</mat-icon>
      </div>

        <div class="profile" fxFlex="8" fxLayoutAlign="center" (click)="authService.startLogout()">
          <h1>Logout</h1>
          <mat-icon>logout</mat-icon>
        </div>

        <div class="eon-logo" fxFlex="25" fxLayoutAlign="end center">
          <mat-icon svgIcon="logo-red"></mat-icon>
        </div>
    </div>
    <div class="header-content" fxLayout="row" *ngIf="!loggedIn">

      <div class="title" fxLayoutAlign="start center" fxFlex="70" fxLayout="row">
        <mat-icon svgIcon="eis-assets-working-icon" class="header-icon" [routerLink]="['/']"></mat-icon>
        <h1>EIS Gateway Administration</h1>
      </div>

      <div class="eon-logo" fxFlex="30" fxLayoutAlign="end center">
        <mat-icon svgIcon="logo-red"></mat-icon>
      </div>
    </div>
  </mat-toolbar>
</div>
